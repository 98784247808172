export enum DimensionsModal {
  HEADING = 'External dimensions guide',
  MAIN_PARAGRAPH = 'The amount of sunlight, or shade, directly affects comfort and can have a profound effect on how we feel. That’s why we thought it was important to leave it up to you. With a customizable amount of beams, and an easily removable canopy, everything is designed to enhance your experience of your space.',
  SECOND_PARAGRAPH = 'There are two measurements to consider when building your pergola.',
  EXTERNAL_TITLE = 'External dimensions',
  EXTERNAL_CONTENT = 'These refer to the exterior size of your assembled structure.',
  INTERNAL_TITLE = 'Internal dimensions',
  INTERNAL_CONTENT = 'These refer to how much space you have inside of your assembled structure.',
  NOTICE_TITLE = 'Please Notice',
  NOTICE_CONTENT = 'Dimensions you enter are external dimensions as seen in the picture below',
  HEIGHT_TAB_TITLE = 'Height',
  HEIGHT_TAB_CONTENT1 = 'Overall height measurements start at the bottom of the structure and include both posts and beams as illustrated above.',
  HEIGHT_TAB_CONTENT2 = 'The interior dimensions are measured without the beams and posts and therefore are naturally a bit smaller.',
  HEIGHT_TAB_CONTENT3 = 'Pro-tip: When deciding the placement of your pergola, consider your windows! If the pergola will be placed in front of the house, make sure second floor windows remain functional and can still be opened.',
  HEIGHT_PRO_TIP = 'When deciding the placement of your pergola, consider your windows. If the pergola will be placed in front of the house, make sure second floor windows will remain functional and the view won\'t be blocked.',
  LENGTH_TAB_TITLE = 'Length',
  LENGTH_TAB_CONTENT1 = 'The length of a pergola is measured from one end of the furthest beam to the other. This measurement can vary depending on if the pergola is attached or freestanding.',
  LENGTH_TAB_CONTENT2 = 'Pro-tip: Looking to dine alfresco or lounge on your patio set? Consider what furniture you will be placing underneath your pergola when choosing dimensions.',
  LENGTH_PRO_TIP = 'Looking to dine alfresco or lounge on your patio set? Consider what furniture you will be placing underneath your pergola when choosing dimensions.',
  WIDTH_TAB_TITLE = 'Width',
  WIDTH_TAB_CONTENT1 = 'The width of a pergola is measured from one end of the beam to the other. This will be the same whether the pergola is attached or freestanding.'
}


export enum CanopyModal {
  WHAT_IS_TITLE = 'What is canopy?',
  WHAT_IS_CONTENT = 'A canopy is a durable, water-resistant fabric which provides shade to your pergola.',
  WHY_TITLE = 'Why do I need it?',
  WHY_CONTENT = 'Besides being an elegant addition to any pergola, canopies offer protection and coverage from the elements, so that you can enjoy your pergola all year-round.',
  AMOUNT_TITLE = 'Amount of canopy needed:',
  AMOUNT_CONTENT = 'The amount of canopy needed for your structure is determined by the size and design of your structure and cannot be customized.',
  NOTICE_TITLE = 'Please Notice',
  NOTICE_CONTENT = 'You cannot add a canopy to the structure if bought separately',
  HOW_TITLE = 'How is it used?',
  HOW_CONTENT = 'Easy to install and remove, the canopy is completely adaptable to your needs. It easily opens and closes with a gentle pull.',
  NOTICE_TITLE_2 = 'Please Notice',
  NOTICE_CONTENT_2 = 'Beams are permanent and make fixed amount of shade under your pergola. Therefore we recommend combining less beams with a canopy.'
}

export enum BeamsModal {
  WHAT_ARE_TITLE = 'What are beams?',
  WHAT_ARE_CONTENT1 = 'Beams are a fixed part of the overhead structure of your pergola. The amount of beams in each pergola varies according to its size.',
  WHAT_ARE_CONTENT2 = 'Like the canopy, beams contribute both coverage and protection, as well as add style to your structure design.',
  HELP_TITLE = 'Need some help deciding?',
  HELP_CONTENT = 'We recommend spacing beams 1 feet apart and combining with canopy for a balanced amount of sunlight and shade.',
  NOTICE_TITLE = 'Please Note',
  NOTICE_CONTENT = 'Unlike canopies, beams are a permanent fixture of your structure. We recommend combining fewer beams with a canopy.',
}