/**
 * Calculate Beams Size Price
 *
 * @param {number} width The width in inches of the pergola
 * @param {number} beams The number of beams in the pergola
 * @param {number} widthSizePriceBelow120 The pricing configuration number for width below 120 inches
 * @param {number} widthSizePriceBelow240 The pricing configuration number for width below 240 inches
 * @param {number} widthSizePriceAbove240 The pricing configuration number for width above 240 inches
 * @return {number}
 */
export const calculateBeamsSizePrice = (width: number, beams: number, widthSizePriceBelow120: number, widthSizePriceBelow240: number, widthSizePriceAbove240: number): number => {
  // D6 = Width
  // D7 = Beams

  // =(if(D6<=120,D6*1.7,if(D6<=240,120*1.7+(D6-120)*1.4,120*1.7+120*1.4+(D6-240)*1.25)))*D7
  // =(if(D6<=120,D6*1.7,if(D6<=240,120*1.7+(D6-120)*1.5,120*1.7+120*1.5+(D6-240)*1.25)))*D7

  //=(if(D6<=120,D6*1.7,
  if (width <= 120) {
    return (width * widthSizePriceBelow120) * beams;
  }

  // if(D6<=240,120*1.7+(D6-120)*1.4,
  if (width <= 240) {
    return (120 * widthSizePriceBelow120 + (width - 120) * widthSizePriceBelow240) * beams;
  }

  // 120*1.7+120*1.4+(D6-240)*1.25)))*D7
  return (120 * widthSizePriceBelow120 + (120 * widthSizePriceBelow240) + (width - 240) * widthSizePriceAbove240) * beams;
};
