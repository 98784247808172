import React from 'react';
import * as SwitchPrimitive from '@radix-ui/react-switch';

import { usePergola } from '../../data-stores/pergola';

export const CanopySwitch = () => {
  const hasCanopy = usePergola(state => state.canopy.hasCanopy);
  const canopyPrice = usePergola(state => state.canopyPrice);
  const toggleHasCanopy = usePergola(state => state.toggleHasCanopy);

  return (
    <div className="canopy_switch_container">
      <div className="flex flex-col">
        <div className="font-semibold	text-sm text-text">Include 1 canopy</div>
        <div className="font-bold text-sm text-green">+ ${canopyPrice.toFixed(2)}</div>
      </div>

      <SwitchPrimitive.Root
        className="canopy_switch_root"
        id="s1"
        checked={hasCanopy}
        onClick={toggleHasCanopy}
      >
        <SwitchPrimitive.Thumb className="canopy_switch_thumb"/>
      </SwitchPrimitive.Root>
    </div>
  );
};
