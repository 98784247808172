export const beamsMatrix: { [key: string]: number } = {
  '2_2': 0,
  '2_3': 0,
  '2_4': 0,
  '2_5': 0,
  '2_6': 0,
  '2_7': 0,
  '2_8': 0,
  '2_9': 0,
  '2_10': 0,
  '2_11': 0,
  '2_12': 0,
  '2_13': 0,
  '2_14': 0,
  '2_15': 0,
  '2_16': 0,
  '2_17': 0,
  '2_18': 0,
  '2_19': 0,
  '2_20': 0,
  '2_21': 0,
  '2_22': 0,
  '2_23': 0,
  '2_24': 0,
  '2_25': 0,
  '2_26': 0,
  '2_27': 0,
  '2_28': 0,
  '2_29': 0,
  '2_30': 0,
  '2_31': 0,
  '2_32': 0,
  '2_33': 0,
  '2_34': 0,
  '2_35': 0,
  '2_36': 0,
  '2_37': 0,
  '2_38': 0,
  '2_39': 0,
  '2_40': 0,
  '2_41': 0,
  '2_42': 0,
  '2_43': 0,
  '2_44': 0,
  '2_45': 0,
  '2_46': 0,
  '2_47': 0,
  '2_48': 0,
  '2_49': 0,
  '2_50': 0,
  '2_51': 0,
  '2_52': 0,
  '2_53': 0,
  '2_54': 0,
  '2_55': 0,
  '2_56': 0,
  '2_57': 0,
  '2_58': 0,
  '2_59': 0,
  '2_60': 0,
  '3_2': 0,
  '3_3': 0,
  '3_4': 0,
  '3_5': 0,
  '3_6': 0,
  '3_7': 0,
  '3_8': 0,
  '3_9': 0,
  '3_10': 0,
  '3_11': 0,
  '3_12': 0,
  '3_13': 0,
  '3_14': 0,
  '3_15': 0,
  '3_16': 0,
  '3_17': 0,
  '3_18': 0,
  '3_19': 0,
  '3_20': 0,
  '3_21': 0,
  '3_22': 0,
  '3_23': 0,
  '3_24': 0,
  '3_25': 0,
  '3_26': 0,
  '3_27': 0,
  '3_28': 0,
  '3_29': 0,
  '3_30': 0,
  '3_31': 0,
  '3_32': 0,
  '3_33': 0,
  '3_34': 0,
  '3_35': 0,
  '3_36': 0,
  '3_37': 0,
  '3_38': 0,
  '3_39': 0,
  '3_40': 0,
  '3_41': 0,
  '3_42': 0,
  '3_43': 0,
  '3_44': 0,
  '3_45': 0,
  '3_46': 0,
  '3_47': 0,
  '3_48': 0,
  '3_49': 0,
  '3_50': 0,
  '3_51': 0,
  '3_52': 0,
  '3_53': 0,
  '3_54': 0,
  '3_55': 0,
  '3_56': 0,
  '3_57': 0,
  '3_58': 0,
  '3_59': 0,
  '3_60': 0,
  '4_2': 0,
  '4_3': 0,
  '4_4': 0,
  '4_5': 0,
  '4_6': 0,
  '4_7': 0,
  '4_8': 0,
  '4_9': 0,
  '4_10': 0,
  '4_11': 0,
  '4_12': 0,
  '4_13': 0,
  '4_14': 0,
  '4_15': 0,
  '4_16': 0,
  '4_17': 0,
  '4_18': 0,
  '4_19': 0,
  '4_20': 0,
  '4_21': 0,
  '4_22': 0,
  '4_23': 0,
  '4_24': 0,
  '4_25': 0,
  '4_26': 0,
  '4_27': 0,
  '4_28': 0,
  '4_29': 0,
  '4_30': 0,
  '4_31': 0,
  '4_32': 0,
  '4_33': 0,
  '4_34': 0,
  '4_35': 0,
  '4_36': 0,
  '4_37': 0,
  '4_38': 0,
  '4_39': 0,
  '4_40': 0,
  '4_41': 0,
  '4_42': 0,
  '4_43': 0,
  '4_44': 0,
  '4_45': 0,
  '4_46': 0,
  '4_47': 0,
  '4_48': 0,
  '4_49': 0,
  '4_50': 0,
  '4_51': 0,
  '4_52': 0,
  '4_53': 0,
  '4_54': 0,
  '4_55': 0,
  '4_56': 0,
  '4_57': 0,
  '4_58': 0,
  '4_59': 0,
  '4_60': 0,
  '5_2': 0,
  '5_3': 0,
  '5_4': 0,
  '5_5': 7,
  '5_6': 9,
  '5_7': 9,
  '5_8': 11,
  '5_9': 13,
  '5_10': 15,
  '5_11': 15,
  '5_12': 17,
  '5_13': 19,
  '5_14': 21,
  '5_15': 21,
  '5_16': 23,
  '5_17': 25,
  '5_18': 27,
  '5_19': 27,
  '5_20': 29,
  '5_21': 35,
  '5_22': 37,
  '5_23': 37,
  '5_24': 39,
  '5_25': 41,
  '5_26': 41,
  '5_27': 43,
  '5_28': 43,
  '5_29': 45,
  '5_30': 45,
  '5_31': 47,
  '5_32': 47,
  '5_33': 49,
  '5_34': 49,
  '5_35': 51,
  '5_36': 51,
  '5_37': 53,
  '5_38': 53,
  '5_39': 55,
  '5_40': 55,
  '5_41': 57,
  '5_42': 59,
  '5_43': 61,
  '5_44': 63,
  '5_45': 65,
  '5_46': 67,
  '5_47': 69,
  '5_48': 71,
  '5_49': 73,
  '5_50': 75,
  '5_51': 77,
  '5_52': 79,
  '5_53': 81,
  '5_54': 83,
  '5_55': 85,
  '5_56': 86,
  '5_57': 87,
  '5_58': 88,
  '5_59': 89,
  '5_60': 89,
  '6_2': 0,
  '6_3': 0,
  '6_4': 0,
  '6_5': 7,
  '6_6': 9,
  '6_7': 9,
  '6_8': 11,
  '6_9': 13,
  '6_10': 15,
  '6_11': 15,
  '6_12': 17,
  '6_13': 19,
  '6_14': 21,
  '6_15': 21,
  '6_16': 23,
  '6_17': 25,
  '6_18': 27,
  '6_19': 27,
  '6_20': 27,
  '6_21': 33,
  '6_22': 35,
  '6_23': 35,
  '6_24': 37,
  '6_25': 39,
  '6_26': 39,
  '6_27': 41,
  '6_28': 41,
  '6_29': 43,
  '6_30': 43,
  '6_31': 45,
  '6_32': 45,
  '6_33': 47,
  '6_34': 47,
  '6_35': 49,
  '6_36': 49,
  '6_37': 51,
  '6_38': 51,
  '6_39': 53,
  '6_40': 53,
  '6_41': 57,
  '6_42': 59,
  '6_43': 61,
  '6_44': 63,
  '6_45': 65,
  '6_46': 67,
  '6_47': 69,
  '6_48': 71,
  '6_49': 73,
  '6_50': 75,
  '6_51': 77,
  '6_52': 79,
  '6_53': 81,
  '6_54': 83,
  '6_55': 85,
  '6_56': 86,
  '6_57': 87,
  '6_58': 88,
  '6_59': 89,
  '6_60': 89,
  '7_2': 0,
  '7_3': 0,
  '7_4': 0,
  '7_5': 7,
  '7_6': 9,
  '7_7': 9,
  '7_8': 11,
  '7_9': 13,
  '7_10': 15,
  '7_11': 15,
  '7_12': 17,
  '7_13': 19,
  '7_14': 21,
  '7_15': 21,
  '7_16': 23,
  '7_17': 25,
  '7_18': 25,
  '7_19': 27,
  '7_20': 25,
  '7_21': 31,
  '7_22': 33,
  '7_23': 33,
  '7_24': 35,
  '7_25': 37,
  '7_26': 37,
  '7_27': 39,
  '7_28': 39,
  '7_29': 41,
  '7_30': 41,
  '7_31': 43,
  '7_32': 43,
  '7_33': 45,
  '7_34': 45,
  '7_35': 47,
  '7_36': 47,
  '7_37': 49,
  '7_38': 49,
  '7_39': 51,
  '7_40': 51,
  '7_41': 55,
  '7_42': 57,
  '7_43': 59,
  '7_44': 61,
  '7_45': 63,
  '7_46': 65,
  '7_47': 67,
  '7_48': 69,
  '7_49': 71,
  '7_50': 73,
  '7_51': 75,
  '7_52': 77,
  '7_53': 79,
  '7_54': 81,
  '7_55': 83,
  '7_56': 84,
  '7_57': 85,
  '7_58': 86,
  '7_59': 87,
  '7_60': 87,
  '8_2': 0,
  '8_3': 0,
  '8_4': 0,
  '8_5': 7,
  '8_6': 9,
  '8_7': 9,
  '8_8': 11,
  '8_9': 13,
  '8_10': 15,
  '8_11': 15,
  '8_12': 17,
  '8_13': 19,
  '8_14': 21,
  '8_15': 21,
  '8_16': 23,
  '8_17': 25,
  '8_18': 25,
  '8_19': 25,
  '8_20': 23,
  '8_21': 29,
  '8_22': 31,
  '8_23': 31,
  '8_24': 33,
  '8_25': 35,
  '8_26': 35,
  '8_27': 37,
  '8_28': 37,
  '8_29': 39,
  '8_30': 39,
  '8_31': 41,
  '8_32': 41,
  '8_33': 43,
  '8_34': 43,
  '8_35': 45,
  '8_36': 45,
  '8_37': 47,
  '8_38': 47,
  '8_39': 49,
  '8_40': 49,
  '8_41': 55,
  '8_42': 57,
  '8_43': 59,
  '8_44': 61,
  '8_45': 63,
  '8_46': 65,
  '8_47': 67,
  '8_48': 69,
  '8_49': 71,
  '8_50': 73,
  '8_51': 75,
  '8_52': 77,
  '8_53': 79,
  '8_54': 81,
  '8_55': 83,
  '8_56': 84,
  '8_57': 85,
  '8_58': 86,
  '8_59': 87,
  '8_60': 87,
  '9_2': 0,
  '9_3': 0,
  '9_4': 0,
  '9_5': 7,
  '9_6': 9,
  '9_7': 9,
  '9_8': 11,
  '9_9': 13,
  '9_10': 15,
  '9_11': 15,
  '9_12': 17,
  '9_13': 19,
  '9_14': 21,
  '9_15': 21,
  '9_16': 23,
  '9_17': 25,
  '9_18': 23,
  '9_19': 23,
  '9_20': 21,
  '9_21': 25,
  '9_22': 27,
  '9_23': 27,
  '9_24': 29,
  '9_25': 31,
  '9_26': 31,
  '9_27': 33,
  '9_28': 33,
  '9_29': 35,
  '9_30': 35,
  '9_31': 37,
  '9_32': 37,
  '9_33': 39,
  '9_34': 39,
  '9_35': 41,
  '9_36': 41,
  '9_37': 43,
  '9_38': 43,
  '9_39': 45,
  '9_40': 45,
  '9_41': 51,
  '9_42': 53,
  '9_43': 55,
  '9_44': 57,
  '9_45': 59,
  '9_46': 61,
  '9_47': 63,
  '9_48': 65,
  '9_49': 67,
  '9_50': 69,
  '9_51': 71,
  '9_52': 73,
  '9_53': 75,
  '9_54': 77,
  '9_55': 79,
  '9_56': 80,
  '9_57': 81,
  '9_58': 82,
  '9_59': 83,
  '9_60': 83,
  '10_2': 0,
  '10_3': 0,
  '10_4': 0,
  '10_5': 7,
  '10_6': 9,
  '10_7': 9,
  '10_8': 11,
  '10_9': 13,
  '10_10': 15,
  '10_11': 15,
  '10_12': 17,
  '10_13': 19,
  '10_14': 21,
  '10_15': 21,
  '10_16': 23,
  '10_17': 23,
  '10_18': 23,
  '10_19': 21,
  '10_20': 21,
  '10_21': 25,
  '10_22': 27,
  '10_23': 27,
  '10_24': 29,
  '10_25': 31,
  '10_26': 31,
  '10_27': 33,
  '10_28': 33,
  '10_29': 35,
  '10_30': 35,
  '10_31': 37,
  '10_32': 37,
  '10_33': 39,
  '10_34': 39,
  '10_35': 41,
  '10_36': 41,
  '10_37': 43,
  '10_38': 43,
  '10_39': 45,
  '10_40': 45,
  '10_41': 47,
  '10_42': 49,
  '10_43': 51,
  '10_44': 53,
  '10_45': 53,
  '10_46': 55,
  '10_47': 55,
  '10_48': 57,
  '10_49': 57,
  '10_50': 59,
  '10_51': 59,
  '10_52': 61,
  '10_53': 61,
  '10_54': 63,
  '10_55': 63,
  '10_56': 65,
  '10_57': 65,
  '10_58': 67,
  '10_59': 69,
  '10_60': 69,
  '11_2': 0,
  '11_3': 0,
  '11_4': 0,
  '11_5': 7,
  '11_6': 9,
  '11_7': 9,
  '11_8': 11,
  '11_9': 13,
  '11_10': 15,
  '11_11': 15,
  '11_12': 17,
  '11_13': 19,
  '11_14': 21,
  '11_15': 21,
  '11_16': 19,
  '11_17': 21,
  '11_18': 21,
  '11_19': 21,
  '11_20': 19,
  '11_21': 21,
  '11_22': 23,
  '11_23': 23,
  '11_24': 25,
  '11_25': 27,
  '11_26': 27,
  '11_27': 29,
  '11_28': 29,
  '11_29': 31,
  '11_30': 31,
  '11_31': 33,
  '11_32': 33,
  '11_33': 35,
  '11_34': 35,
  '11_35': 37,
  '11_36': 37,
  '11_37': 39,
  '11_38': 39,
  '11_39': 41,
  '11_40': 41,
  '11_41': 43,
  '11_42': 45,
  '11_43': 47,
  '11_44': 49,
  '11_45': 49,
  '11_46': 51,
  '11_47': 51,
  '11_48': 53,
  '11_49': 53,
  '11_50': 55,
  '11_51': 55,
  '11_52': 57,
  '11_53': 57,
  '11_54': 59,
  '11_55': 59,
  '11_56': 61,
  '11_57': 61,
  '11_58': 63,
  '11_59': 65,
  '11_60': 65,
  '12_2': 0,
  '12_3': 0,
  '12_4': 0,
  '12_5': 7,
  '12_6': 9,
  '12_7': 9,
  '12_8': 11,
  '12_9': 13,
  '12_10': 15,
  '12_11': 15,
  '12_12': 17,
  '12_13': 19,
  '12_14': 21,
  '12_15': 21,
  '12_16': 19,
  '12_17': 21,
  '12_18': 21,
  '12_19': 19,
  '12_20': 19,
  '12_21': 21,
  '12_22': 23,
  '12_23': 23,
  '12_24': 25,
  '12_25': 27,
  '12_26': 27,
  '12_27': 29,
  '12_28': 29,
  '12_29': 31,
  '12_30': 31,
  '12_31': 33,
  '12_32': 33,
  '12_33': 35,
  '12_34': 35,
  '12_35': 37,
  '12_36': 37,
  '12_37': 39,
  '12_38': 39,
  '12_39': 41,
  '12_40': 41,
  '12_41': 41,
  '12_42': 43,
  '12_43': 45,
  '12_44': 47,
  '12_45': 47,
  '12_46': 49,
  '12_47': 49,
  '12_48': 51,
  '12_49': 51,
  '12_50': 53,
  '12_51': 53,
  '12_52': 55,
  '12_53': 55,
  '12_54': 57,
  '12_55': 57,
  '12_56': 59,
  '12_57': 59,
  '12_58': 61,
  '12_59': 63,
  '12_60': 63,
  '13_2': 0,
  '13_3': 0,
  '13_4': 0,
  '13_5': 7,
  '13_6': 9,
  '13_7': 9,
  '13_8': 11,
  '13_9': 13,
  '13_10': 15,
  '13_11': 15,
  '13_12': 17,
  '13_13': 19,
  '13_14': 21,
  '13_15': 19,
  '13_16': 17,
  '13_17': 19,
  '13_18': 19,
  '13_19': 19,
  '13_20': 17,
  '13_21': 19,
  '13_22': 21,
  '13_23': 21,
  '13_24': 23,
  '13_25': 25,
  '13_26': 25,
  '13_27': 27,
  '13_28': 27,
  '13_29': 29,
  '13_30': 29,
  '13_31': 31,
  '13_32': 31,
  '13_33': 33,
  '13_34': 33,
  '13_35': 35,
  '13_36': 35,
  '13_37': 37,
  '13_38': 37,
  '13_39': 39,
  '13_40': 39,
  '13_41': 41,
  '13_42': 39,
  '13_43': 41,
  '13_44': 43,
  '13_45': 45,
  '13_46': 45,
  '13_47': 49,
  '13_48': 49,
  '13_49': 51,
  '13_50': 51,
  '13_51': 53,
  '13_52': 53,
  '13_53': 55,
  '13_54': 55,
  '13_55': 57,
  '13_56': 57,
  '13_57': 59,
  '13_58': 59,
  '13_59': 61,
  '13_60': 61,
  '14_2': 0,
  '14_3': 0,
  '14_4': 0,
  '14_5': 7,
  '14_6': 9,
  '14_7': 9,
  '14_8': 11,
  '14_9': 13,
  '14_10': 15,
  '14_11': 15,
  '14_12': 17,
  '14_13': 19,
  '14_14': 21,
  '14_15': 19,
  '14_16': 17,
  '14_17': 19,
  '14_18': 19,
  '14_19': 17,
  '14_20': 17,
  '14_21': 19,
  '14_22': 21,
  '14_23': 21,
  '14_24': 23,
  '14_25': 23,
  '14_26': 25,
  '14_27': 25,
  '14_28': 27,
  '14_29': 27,
  '14_30': 29,
  '14_31': 29,
  '14_32': 31,
  '14_33': 31,
  '14_34': 33,
  '14_35': 33,
  '14_36': 35,
  '14_37': 35,
  '14_38': 37,
  '14_39': 37,
  '14_40': 37,
  '14_41': 37,
  '14_42': 35,
  '14_43': 37,
  '14_44': 39,
  '14_45': 39,
  '14_46': 41,
  '14_47': 41,
  '14_48': 43,
  '14_49': 43,
  '14_50': 45,
  '14_51': 45,
  '14_52': 47,
  '14_53': 47,
  '14_54': 49,
  '14_55': 49,
  '14_56': 51,
  '14_57': 51,
  '14_58': 53,
  '14_59': 55,
  '14_60': 55,
  '15_2': 0,
  '15_3': 0,
  '15_4': 0,
  '15_5': 7,
  '15_6': 9,
  '15_7': 9,
  '15_8': 11,
  '15_9': 13,
  '15_10': 15,
  '15_11': 15,
  '15_12': 17,
  '15_13': 19,
  '15_14': 21,
  '15_15': 19,
  '15_16': 17,
  '15_17': 19,
  '15_18': 17,
  '15_19': 17,
  '15_20': 15,
  '15_21': 17,
  '15_22': 19,
  '15_23': 19,
  '15_24': 21,
  '15_25': 21,
  '15_26': 23,
  '15_27': 23,
  '15_28': 25,
  '15_29': 25,
  '15_30': 25,
  '15_31': 27,
  '15_32': 27,
  '15_33': 27,
  '15_34': 29,
  '15_35': 29,
  '15_36': 29,
  '15_37': 31,
  '15_38': 31,
  '15_39': 31,
  '15_40': 33,
  '15_41': 33,
  '15_42': 31,
  '15_43': 33,
  '15_44': 35,
  '15_45': 35,
  '15_46': 39,
  '15_47': 39,
  '15_48': 41,
  '15_49': 41,
  '15_50': 43,
  '15_51': 43,
  '15_52': 45,
  '15_53': 45,
  '15_54': 47,
  '15_55': 47,
  '15_56': 49,
  '15_57': 49,
  '15_58': 49,
  '15_59': 51,
  '15_60': 51,
  '16_2': 0,
  '16_3': 0,
  '16_4': 0,
  '16_5': 7,
  '16_6': 9,
  '16_7': 9,
  '16_8': 11,
  '16_9': 13,
  '16_10': 15,
  '16_11': 15,
  '16_12': 17,
  '16_13': 19,
  '16_14': 21,
  '16_15': 19,
  '16_16': 17,
  '16_17': 17,
  '16_18': 17,
  '16_19': 15,
  '16_20': 15,
  '16_21': 17,
  '16_22': 19,
  '16_23': 19,
  '16_24': 21,
  '16_25': 21,
  '16_26': 23,
  '16_27': 23,
  '16_28': 25,
  '16_29': 25,
  '16_30': 25,
  '16_31': 27,
  '16_32': 27,
  '16_33': 27,
  '16_34': 29,
  '16_35': 29,
  '16_36': 29,
  '16_37': 31,
  '16_38': 31,
  '16_39': 31,
  '16_40': 31,
  '16_41': 31,
  '16_42': 31,
  '16_43': 33,
  '16_44': 31,
  '16_45': 31,
  '16_46': 35,
  '16_47': 35,
  '16_48': 37,
  '16_49': 37,
  '16_50': 37,
  '16_51': 39,
  '16_52': 39,
  '16_53': 39,
  '16_54': 41,
  '16_55': 41,
  '16_56': 41,
  '16_57': 43,
  '16_58': 43,
  '16_59': 43,
  '16_60': 43,
  '17_2': 0,
  '17_3': 0,
  '17_4': 0,
  '17_5': 7,
  '17_6': 9,
  '17_7': 9,
  '17_8': 11,
  '17_9': 13,
  '17_10': 15,
  '17_11': 15,
  '17_12': 17,
  '17_13': 19,
  '17_14': 21,
  '17_15': 19,
  '17_16': 17,
  '17_17': 17,
  '17_18': 15,
  '17_19': 15,
  '17_20': 13,
  '17_21': 13,
  '17_22': 15,
  '17_23': 15,
  '17_24': 17,
  '17_25': 17,
  '17_26': 19,
  '17_27': 19,
  '17_28': 21,
  '17_29': 21,
  '17_30': 21,
  '17_31': 23,
  '17_32': 23,
  '17_33': 23,
  '17_34': 25,
  '17_35': 25,
  '17_36': 25,
  '17_37': 27,
  '17_38': 27,
  '17_39': 27,
  '17_40': 27,
  '17_41': 27,
  '17_42': 27,
  '17_43': 29,
  '17_44': 27,
  '17_45': 29,
  '17_46': 31,
  '17_47': 31,
  '17_48': 31,
  '17_49': 33,
  '17_50': 33,
  '17_51': 33,
  '17_52': 35,
  '17_53': 35,
  '17_54': 35,
  '17_55': 37,
  '17_56': 37,
  '17_57': 39,
  '17_58': 39,
  '17_59': 39,
  '17_60': 39,
  '18_2': 0,
  '18_3': 0,
  '18_4': 0,
  '18_5': 7,
  '18_6': 9,
  '18_7': 9,
  '18_8': 11,
  '18_9': 13,
  '18_10': 15,
  '18_11': 15,
  '18_12': 17,
  '18_13': 19,
  '18_14': 21,
  '18_15': 19,
  '18_16': 17,
  '18_17': 17,
  '18_18': 15,
  '18_19': 13,
  '18_20': 13,
  '18_21': 13,
  '18_22': 15,
  '18_23': 15,
  '18_24': 17,
  '18_25': 17,
  '18_26': 19,
  '18_27': 19,
  '18_28': 21,
  '18_29': 21,
  '18_30': 21,
  '18_31': 23,
  '18_32': 23,
  '18_33': 23,
  '18_34': 25,
  '18_35': 25,
  '18_36': 25,
  '18_37': 27,
  '18_38': 27,
  '18_39': 27,
  '18_40': 27,
  '18_41': 27,
  '18_42': 27,
  '18_43': 27,
  '18_44': 27,
  '18_45': 29,
  '18_46': 29,
  '18_47': 29,
  '18_48': 31,
  '18_49': 31,
  '18_50': 33,
  '18_51': 33,
  '18_52': 33,
  '18_53': 35,
  '18_54': 35,
  '18_55': 35,
  '18_56': 37,
  '18_57': 37,
  '18_58': 37,
  '18_59': 39,
  '18_60': 39,
  '19_2': 0,
  '19_3': 0,
  '19_4': 0,
  '19_5': 7,
  '19_6': 9,
  '19_7': 9,
  '19_8': 11,
  '19_9': 13,
  '19_10': 15,
  '19_11': 15,
  '19_12': 17,
  '19_13': 19,
  '19_14': 21,
  '19_15': 19,
  '19_16': 17,
  '19_17': 15,
  '19_18': 13,
  '19_19': 13,
  '19_20': 11,
  '19_21': 11,
  '19_22': 13,
  '19_23': 13,
  '19_24': 15,
  '19_25': 15,
  '19_26': 17,
  '19_27': 17,
  '19_28': 19,
  '19_29': 19,
  '19_30': 19,
  '19_31': 19,
  '19_32': 21,
  '19_33': 21,
  '19_34': 21,
  '19_35': 21,
  '19_36': 21,
  '19_37': 21,
  '19_38': 23,
  '19_39': 23,
  '19_40': 23,
  '19_41': 23,
  '19_42': 23,
  '19_43': 23,
  '19_44': 23,
  '19_45': 25,
  '19_46': 25,
  '19_47': 27,
  '19_48': 27,
  '19_49': 29,
  '19_50': 29,
  '19_51': 31,
  '19_52': 31,
  '19_53': 31,
  '19_54': 31,
  '19_55': 33,
  '19_56': 33,
  '19_57': 33,
  '19_58': 35,
  '19_59': 35,
  '19_60': 35,
  '20_2': 0,
  '20_3': 0,
  '20_4': 0,
  '20_5': 7,
  '20_6': 9,
  '20_7': 9,
  '20_8': 11,
  '20_9': 13,
  '20_10': 15,
  '20_11': 15,
  '20_12': 17,
  '20_13': 19,
  '20_14': 21,
  '20_15': 19,
  '20_16': 17,
  '20_17': 15,
  '20_18': 13,
  '20_19': 11,
  '20_20': 11,
  '20_21': 11,
  '20_22': 13,
  '20_23': 13,
  '20_24': 15,
  '20_25': 15,
  '20_26': 17,
  '20_27': 17,
  '20_28': 19,
  '20_29': 19,
  '20_30': 19,
  '20_31': 19,
  '20_32': 21,
  '20_33': 21,
  '20_34': 21,
  '20_35': 21,
  '20_36': 21,
  '20_37': 21,
  '20_38': 23,
  '20_39': 23,
  '20_40': 23,
  '20_41': 23,
  '20_42': 23,
  '20_43': 23,
  '20_44': 23,
  '20_45': 23,
  '20_46': 23,
  '20_47': 25,
  '20_48': 23,
  '20_49': 25,
  '20_50': 25,
  '20_51': 27,
  '20_52': 27,
  '20_53': 27,
  '20_54': 29,
  '20_55': 29,
  '20_56': 29,
  '20_57': 29,
  '20_58': 31,
  '20_59': 31,
  '20_60': 31,
  '21_2': 0,
  '21_3': 0,
  '21_4': 0,
  '21_5': 7,
  '21_6': 9,
  '21_7': 9,
  '21_8': 11,
  '21_9': 13,
  '21_10': 15,
  '21_11': 15,
  '21_12': 17,
  '21_13': 19,
  '21_14': 21,
  '21_15': 19,
  '21_16': 17,
  '21_17': 15,
  '21_18': 13,
  '21_19': 11,
  '21_20': 11,
  '21_21': 11,
  '21_22': 13,
  '21_23': 13,
  '21_24': 15,
  '21_25': 15,
  '21_26': 17,
  '21_27': 17,
  '21_28': 19,
  '21_29': 19,
  '21_30': 19,
  '21_31': 19,
  '21_32': 21,
  '21_33': 21,
  '21_34': 21,
  '21_35': 21,
  '21_36': 21,
  '21_37': 21,
  '21_38': 23,
  '21_39': 23,
  '21_40': 23,
  '21_41': 23,
  '21_42': 23,
  '21_43': 23,
  '21_44': 23,
  '21_45': 23,
  '21_46': 23,
  '21_47': 25,
  '21_48': 23,
  '21_49': 25,
  '21_50': 25,
  '21_51': 27,
  '21_52': 27,
  '21_53': 27,
  '21_54': 29,
  '21_55': 29,
  '21_56': 29,
  '21_57': 29,
  '21_58': 31,
  '21_59': 31,
  '21_60': 31,
  '22_2': 0,
  '22_3': 0,
  '22_4': 0,
  '22_5': 7,
  '22_6': 9,
  '22_7': 9,
  '22_8': 11,
  '22_9': 13,
  '22_10': 15,
  '22_11': 15,
  '22_12': 17,
  '22_13': 19,
  '22_14': 21,
  '22_15': 19,
  '22_16': 17,
  '22_17': 15,
  '22_18': 13,
  '22_19': 11,
  '22_20': 11,
  '22_21': 11,
  '22_22': 13,
  '22_23': 13,
  '22_24': 15,
  '22_25': 15,
  '22_26': 17,
  '22_27': 17,
  '22_28': 19,
  '22_29': 19,
  '22_30': 19,
  '22_31': 19,
  '22_32': 21,
  '22_33': 21,
  '22_34': 21,
  '22_35': 21,
  '22_36': 21,
  '22_37': 21,
  '22_38': 23,
  '22_39': 23,
  '22_40': 23,
  '22_41': 23,
  '22_42': 23,
  '22_43': 23,
  '22_44': 23,
  '22_45': 23,
  '22_46': 23,
  '22_47': 25,
  '22_48': 23,
  '22_49': 25,
  '22_50': 25,
  '22_51': 27,
  '22_52': 27,
  '22_53': 27,
  '22_54': 29,
  '22_55': 29,
  '22_56': 29,
  '22_57': 29,
  '22_58': 31,
  '22_59': 31,
  '22_60': 31,
  '23_2': 0,
  '23_3': 0,
  '23_4': 0,
  '23_5': 7,
  '23_6': 9,
  '23_7': 9,
  '23_8': 11,
  '23_9': 13,
  '23_10': 15,
  '23_11': 15,
  '23_12': 17,
  '23_13': 19,
  '23_14': 21,
  '23_15': 19,
  '23_16': 17,
  '23_17': 15,
  '23_18': 13,
  '23_19': 11,
  '23_20': 11,
  '23_21': 11,
  '23_22': 13,
  '23_23': 13,
  '23_24': 15,
  '23_25': 15,
  '23_26': 17,
  '23_27': 17,
  '23_28': 19,
  '23_29': 19,
  '23_30': 19,
  '23_31': 19,
  '23_32': 21,
  '23_33': 21,
  '23_34': 21,
  '23_35': 21,
  '23_36': 21,
  '23_37': 21,
  '23_38': 23,
  '23_39': 23,
  '23_40': 23,
  '23_41': 23,
  '23_42': 23,
  '23_43': 23,
  '23_44': 23,
  '23_45': 23,
  '23_46': 23,
  '23_47': 25,
  '23_48': 23,
  '23_49': 25,
  '23_50': 25,
  '23_51': 27,
  '23_52': 27,
  '23_53': 27,
  '23_54': 29,
  '23_55': 29,
  '23_56': 29,
  '23_57': 29,
  '23_58': 31,
  '23_59': 31,
  '23_60': 31,
  '24_2': 0,
  '24_3': 0,
  '24_4': 0,
  '24_5': 7,
  '24_6': 9,
  '24_7': 9,
  '24_8': 11,
  '24_9': 13,
  '24_10': 15,
  '24_11': 15,
  '24_12': 17,
  '24_13': 19,
  '24_14': 21,
  '24_15': 19,
  '24_16': 17,
  '24_17': 15,
  '24_18': 13,
  '24_19': 11,
  '24_20': 11,
  '24_21': 11,
  '24_22': 13,
  '24_23': 13,
  '24_24': 15,
  '24_25': 15,
  '24_26': 17,
  '24_27': 17,
  '24_28': 19,
  '24_29': 19,
  '24_30': 19,
  '24_31': 19,
  '24_32': 21,
  '24_33': 21,
  '24_34': 21,
  '24_35': 21,
  '24_36': 21,
  '24_37': 21,
  '24_38': 23,
  '24_39': 23,
  '24_40': 23,
  '24_41': 23,
  '24_42': 23,
  '24_43': 23,
  '24_44': 23,
  '24_45': 23,
  '24_46': 23,
  '24_47': 25,
  '24_48': 23,
  '24_49': 25,
  '24_50': 25,
  '24_51': 27,
  '24_52': 27,
  '24_53': 27,
  '24_54': 29,
  '24_55': 29,
  '24_56': 29,
  '24_57': 29,
  '24_58': 31,
  '24_59': 31,
  '24_60': 31,
  '25_2': 0,
  '25_3': 0,
  '25_4': 0,
  '25_5': 7,
  '25_6': 9,
  '25_7': 9,
  '25_8': 11,
  '25_9': 13,
  '25_10': 15,
  '25_11': 15,
  '25_12': 17,
  '25_13': 19,
  '25_14': 21,
  '25_15': 19,
  '25_16': 17,
  '25_17': 15,
  '25_18': 13,
  '25_19': 11,
  '25_20': 11,
  '25_21': 11,
  '25_22': 13,
  '25_23': 13,
  '25_24': 15,
  '25_25': 15,
  '25_26': 17,
  '25_27': 17,
  '25_28': 19,
  '25_29': 19,
  '25_30': 19,
  '25_31': 19,
  '25_32': 21,
  '25_33': 21,
  '25_34': 21,
  '25_35': 21,
  '25_36': 21,
  '25_37': 21,
  '25_38': 23,
  '25_39': 23,
  '25_40': 23,
  '25_41': 23,
  '25_42': 23,
  '25_43': 23,
  '25_44': 23,
  '25_45': 23,
  '25_46': 23,
  '25_47': 25,
  '25_48': 23,
  '25_49': 25,
  '25_50': 25,
  '25_51': 27,
  '25_52': 27,
  '25_53': 27,
  '25_54': 29,
  '25_55': 29,
  '25_56': 29,
  '25_57': 29,
  '25_58': 31,
  '25_59': 31,
  '25_60': 31,
  '26_2': 0,
  '26_3': 0,
  '26_4': 0,
  '26_5': 7,
  '26_6': 9,
  '26_7': 9,
  '26_8': 11,
  '26_9': 13,
  '26_10': 15,
  '26_11': 15,
  '26_12': 17,
  '26_13': 19,
  '26_14': 21,
  '26_15': 19,
  '26_16': 17,
  '26_17': 15,
  '26_18': 13,
  '26_19': 11,
  '26_20': 11,
  '26_21': 11,
  '26_22': 13,
  '26_23': 13,
  '26_24': 15,
  '26_25': 15,
  '26_26': 17,
  '26_27': 17,
  '26_28': 19,
  '26_29': 19,
  '26_30': 19,
  '26_31': 19,
  '26_32': 21,
  '26_33': 21,
  '26_34': 21,
  '26_35': 21,
  '26_36': 21,
  '26_37': 21,
  '26_38': 23,
  '26_39': 23,
  '26_40': 23,
  '26_41': 23,
  '26_42': 23,
  '26_43': 23,
  '26_44': 23,
  '26_45': 23,
  '26_46': 23,
  '26_47': 25,
  '26_48': 23,
  '26_49': 25,
  '26_50': 25,
  '26_51': 27,
  '26_52': 27,
  '26_53': 27,
  '26_54': 29,
  '26_55': 29,
  '26_56': 29,
  '26_57': 29,
  '26_58': 31,
  '26_59': 31,
  '26_60': 31,
  '27_2': 0,
  '27_3': 0,
  '27_4': 0,
  '27_5': 7,
  '27_6': 9,
  '27_7': 9,
  '27_8': 11,
  '27_9': 13,
  '27_10': 15,
  '27_11': 15,
  '27_12': 17,
  '27_13': 19,
  '27_14': 21,
  '27_15': 19,
  '27_16': 17,
  '27_17': 15,
  '27_18': 13,
  '27_19': 11,
  '27_20': 11,
  '27_21': 11,
  '27_22': 13,
  '27_23': 13,
  '27_24': 15,
  '27_25': 15,
  '27_26': 17,
  '27_27': 17,
  '27_28': 19,
  '27_29': 19,
  '27_30': 19,
  '27_31': 19,
  '27_32': 21,
  '27_33': 21,
  '27_34': 21,
  '27_35': 21,
  '27_36': 21,
  '27_37': 21,
  '27_38': 23,
  '27_39': 23,
  '27_40': 23,
  '27_41': 23,
  '27_42': 23,
  '27_43': 23,
  '27_44': 23,
  '27_45': 23,
  '27_46': 23,
  '27_47': 25,
  '27_48': 23,
  '27_49': 25,
  '27_50': 25,
  '27_51': 27,
  '27_52': 27,
  '27_53': 27,
  '27_54': 29,
  '27_55': 29,
  '27_56': 29,
  '27_57': 29,
  '27_58': 31,
  '27_59': 31,
  '27_60': 31,
  '28_2': 0,
  '28_3': 0,
  '28_4': 0,
  '28_5': 7,
  '28_6': 9,
  '28_7': 9,
  '28_8': 11,
  '28_9': 13,
  '28_10': 15,
  '28_11': 15,
  '28_12': 17,
  '28_13': 19,
  '28_14': 21,
  '28_15': 19,
  '28_16': 17,
  '28_17': 15,
  '28_18': 13,
  '28_19': 11,
  '28_20': 11,
  '28_21': 11,
  '28_22': 13,
  '28_23': 13,
  '28_24': 15,
  '28_25': 15,
  '28_26': 17,
  '28_27': 17,
  '28_28': 19,
  '28_29': 19,
  '28_30': 19,
  '28_31': 19,
  '28_32': 21,
  '28_33': 21,
  '28_34': 21,
  '28_35': 21,
  '28_36': 21,
  '28_37': 21,
  '28_38': 23,
  '28_39': 23,
  '28_40': 23,
  '28_41': 23,
  '28_42': 23,
  '28_43': 23,
  '28_44': 23,
  '28_45': 23,
  '28_46': 23,
  '28_47': 25,
  '28_48': 23,
  '28_49': 25,
  '28_50': 25,
  '28_51': 27,
  '28_52': 27,
  '28_53': 27,
  '28_54': 29,
  '28_55': 29,
  '28_56': 29,
  '28_57': 29,
  '28_58': 31,
  '28_59': 31,
  '28_60': 31,
  '29_2': 0,
  '29_3': 0,
  '29_4': 0,
  '29_5': 7,
  '29_6': 9,
  '29_7': 9,
  '29_8': 11,
  '29_9': 13,
  '29_10': 15,
  '29_11': 15,
  '29_12': 17,
  '29_13': 19,
  '29_14': 21,
  '29_15': 19,
  '29_16': 17,
  '29_17': 15,
  '29_18': 13,
  '29_19': 11,
  '29_20': 11,
  '29_21': 11,
  '29_22': 13,
  '29_23': 13,
  '29_24': 15,
  '29_25': 15,
  '29_26': 17,
  '29_27': 17,
  '29_28': 19,
  '29_29': 19,
  '29_30': 19,
  '29_31': 19,
  '29_32': 21,
  '29_33': 21,
  '29_34': 21,
  '29_35': 21,
  '29_36': 21,
  '29_37': 21,
  '29_38': 23,
  '29_39': 23,
  '29_40': 23,
  '29_41': 23,
  '29_42': 23,
  '29_43': 23,
  '29_44': 23,
  '29_45': 23,
  '29_46': 23,
  '29_47': 25,
  '29_48': 23,
  '29_49': 25,
  '29_50': 25,
  '29_51': 27,
  '29_52': 27,
  '29_53': 27,
  '29_54': 29,
  '29_55': 29,
  '29_56': 29,
  '29_57': 29,
  '29_58': 31,
  '29_59': 31,
  '29_60': 31,
  '30_2': 0,
  '30_3': 0,
  '30_4': 0,
  '30_5': 7,
  '30_6': 9,
  '30_7': 9,
  '30_8': 11,
  '30_9': 13,
  '30_10': 15,
  '30_11': 15,
  '30_12': 17,
  '30_13': 19,
  '30_14': 21,
  '30_15': 19,
  '30_16': 17,
  '30_17': 15,
  '30_18': 13,
  '30_19': 11,
  '30_20': 11,
  '30_21': 11,
  '30_22': 13,
  '30_23': 13,
  '30_24': 15,
  '30_25': 15,
  '30_26': 17,
  '30_27': 17,
  '30_28': 19,
  '30_29': 19,
  '30_30': 19,
  '30_31': 19,
  '30_32': 21,
  '30_33': 21,
  '30_34': 21,
  '30_35': 21,
  '30_36': 21,
  '30_37': 21,
  '30_38': 23,
  '30_39': 23,
  '30_40': 23,
  '30_41': 23,
  '30_42': 23,
  '30_43': 23,
  '30_44': 23,
  '30_45': 23,
  '30_46': 23,
  '30_47': 25,
  '30_48': 23,
  '30_49': 25,
  '30_50': 25,
  '30_51': 27,
  '30_52': 27,
  '30_53': 27,
  '30_54': 29,
  '30_55': 29,
  '30_56': 29,
  '30_57': 29,
  '30_58': 31,
  '30_59': 31,
  '30_60': 31,
  '31_2': 0,
  '31_3': 0,
  '31_4': 0,
  '31_5': 7,
  '31_6': 9,
  '31_7': 9,
  '31_8': 11,
  '31_9': 13,
  '31_10': 15,
  '31_11': 15,
  '31_12': 17,
  '31_13': 19,
  '31_14': 21,
  '31_15': 19,
  '31_16': 17,
  '31_17': 15,
  '31_18': 13,
  '31_19': 11,
  '31_20': 11,
  '31_21': 11,
  '31_22': 13,
  '31_23': 13,
  '31_24': 15,
  '31_25': 15,
  '31_26': 17,
  '31_27': 17,
  '31_28': 19,
  '31_29': 19,
  '31_30': 19,
  '31_31': 19,
  '31_32': 21,
  '31_33': 21,
  '31_34': 21,
  '31_35': 21,
  '31_36': 21,
  '31_37': 21,
  '31_38': 23,
  '31_39': 23,
  '31_40': 23,
  '31_41': 23,
  '31_42': 23,
  '31_43': 23,
  '31_44': 23,
  '31_45': 23,
  '31_46': 23,
  '31_47': 25,
  '31_48': 23,
  '31_49': 25,
  '31_50': 25,
  '31_51': 27,
  '31_52': 27,
  '31_53': 27,
  '31_54': 29,
  '31_55': 29,
  '31_56': 29,
  '31_57': 29,
  '31_58': 31,
  '31_59': 31,
  '31_60': 31,
  '32_2': 0,
  '32_3': 0,
  '32_4': 0,
  '32_5': 7,
  '32_6': 9,
  '32_7': 9,
  '32_8': 11,
  '32_9': 13,
  '32_10': 15,
  '32_11': 15,
  '32_12': 17,
  '32_13': 19,
  '32_14': 21,
  '32_15': 19,
  '32_16': 17,
  '32_17': 15,
  '32_18': 13,
  '32_19': 11,
  '32_20': 11,
  '32_21': 11,
  '32_22': 13,
  '32_23': 13,
  '32_24': 15,
  '32_25': 15,
  '32_26': 17,
  '32_27': 17,
  '32_28': 19,
  '32_29': 19,
  '32_30': 19,
  '32_31': 19,
  '32_32': 21,
  '32_33': 21,
  '32_34': 21,
  '32_35': 21,
  '32_36': 21,
  '32_37': 21,
  '32_38': 23,
  '32_39': 23,
  '32_40': 23,
  '32_41': 23,
  '32_42': 23,
  '32_43': 23,
  '32_44': 23,
  '32_45': 23,
  '32_46': 23,
  '32_47': 25,
  '32_48': 23,
  '32_49': 25,
  '32_50': 25,
  '32_51': 27,
  '32_52': 27,
  '32_53': 27,
  '32_54': 29,
  '32_55': 29,
  '32_56': 29,
  '32_57': 29,
  '32_58': 31,
  '32_59': 31,
  '32_60': 31,
  '33_2': 0,
  '33_3': 0,
  '33_4': 0,
  '33_5': 7,
  '33_6': 9,
  '33_7': 9,
  '33_8': 11,
  '33_9': 13,
  '33_10': 15,
  '33_11': 15,
  '33_12': 17,
  '33_13': 19,
  '33_14': 21,
  '33_15': 19,
  '33_16': 17,
  '33_17': 15,
  '33_18': 13,
  '33_19': 11,
  '33_20': 11,
  '33_21': 11,
  '33_22': 13,
  '33_23': 13,
  '33_24': 15,
  '33_25': 15,
  '33_26': 17,
  '33_27': 17,
  '33_28': 19,
  '33_29': 19,
  '33_30': 19,
  '33_31': 19,
  '33_32': 21,
  '33_33': 21,
  '33_34': 21,
  '33_35': 21,
  '33_36': 21,
  '33_37': 21,
  '33_38': 23,
  '33_39': 23,
  '33_40': 23,
  '33_41': 23,
  '33_42': 23,
  '33_43': 23,
  '33_44': 23,
  '33_45': 23,
  '33_46': 23,
  '33_47': 25,
  '33_48': 23,
  '33_49': 25,
  '33_50': 25,
  '33_51': 27,
  '33_52': 27,
  '33_53': 27,
  '33_54': 29,
  '33_55': 29,
  '33_56': 29,
  '33_57': 29,
  '33_58': 31,
  '33_59': 31,
  '33_60': 31,
  '34_2': 0,
  '34_3': 0,
  '34_4': 0,
  '34_5': 7,
  '34_6': 9,
  '34_7': 9,
  '34_8': 11,
  '34_9': 13,
  '34_10': 15,
  '34_11': 15,
  '34_12': 17,
  '34_13': 19,
  '34_14': 21,
  '34_15': 19,
  '34_16': 17,
  '34_17': 15,
  '34_18': 13,
  '34_19': 11,
  '34_20': 11,
  '34_21': 11,
  '34_22': 13,
  '34_23': 13,
  '34_24': 15,
  '34_25': 15,
  '34_26': 17,
  '34_27': 17,
  '34_28': 19,
  '34_29': 19,
  '34_30': 19,
  '34_31': 19,
  '34_32': 21,
  '34_33': 21,
  '34_34': 21,
  '34_35': 21,
  '34_36': 21,
  '34_37': 21,
  '34_38': 23,
  '34_39': 23,
  '34_40': 23,
  '34_41': 23,
  '34_42': 23,
  '34_43': 23,
  '34_44': 23,
  '34_45': 23,
  '34_46': 23,
  '34_47': 25,
  '34_48': 23,
  '34_49': 25,
  '34_50': 25,
  '34_51': 27,
  '34_52': 27,
  '34_53': 27,
  '34_54': 29,
  '34_55': 29,
  '34_56': 29,
  '34_57': 29,
  '34_58': 31,
  '34_59': 31,
  '34_60': 31,
  '35_2': 0,
  '35_3': 0,
  '35_4': 0,
  '35_5': 7,
  '35_6': 9,
  '35_7': 9,
  '35_8': 11,
  '35_9': 13,
  '35_10': 15,
  '35_11': 15,
  '35_12': 17,
  '35_13': 19,
  '35_14': 21,
  '35_15': 19,
  '35_16': 17,
  '35_17': 15,
  '35_18': 13,
  '35_19': 11,
  '35_20': 11,
  '35_21': 11,
  '35_22': 13,
  '35_23': 13,
  '35_24': 15,
  '35_25': 15,
  '35_26': 17,
  '35_27': 17,
  '35_28': 19,
  '35_29': 19,
  '35_30': 19,
  '35_31': 19,
  '35_32': 21,
  '35_33': 21,
  '35_34': 21,
  '35_35': 21,
  '35_36': 21,
  '35_37': 21,
  '35_38': 23,
  '35_39': 23,
  '35_40': 23,
  '35_41': 23,
  '35_42': 23,
  '35_43': 23,
  '35_44': 23,
  '35_45': 23,
  '35_46': 23,
  '35_47': 25,
  '35_48': 23,
  '35_49': 25,
  '35_50': 25,
  '35_51': 27,
  '35_52': 27,
  '35_53': 27,
  '35_54': 29,
  '35_55': 29,
  '35_56': 29,
  '35_57': 29,
  '35_58': 31,
  '35_59': 31,
  '35_60': 31,
  '36_2': 0,
  '36_3': 0,
  '36_4': 0,
  '36_5': 7,
  '36_6': 9,
  '36_7': 9,
  '36_8': 11,
  '36_9': 13,
  '36_10': 15,
  '36_11': 15,
  '36_12': 17,
  '36_13': 19,
  '36_14': 21,
  '36_15': 19,
  '36_16': 17,
  '36_17': 15,
  '36_18': 13,
  '36_19': 11,
  '36_20': 11,
  '36_21': 11,
  '36_22': 13,
  '36_23': 13,
  '36_24': 15,
  '36_25': 15,
  '36_26': 17,
  '36_27': 17,
  '36_28': 19,
  '36_29': 19,
  '36_30': 19,
  '36_31': 19,
  '36_32': 21,
  '36_33': 21,
  '36_34': 21,
  '36_35': 21,
  '36_36': 21,
  '36_37': 21,
  '36_38': 23,
  '36_39': 23,
  '36_40': 23,
  '36_41': 23,
  '36_42': 23,
  '36_43': 23,
  '36_44': 23,
  '36_45': 23,
  '36_46': 23,
  '36_47': 25,
  '36_48': 23,
  '36_49': 25,
  '36_50': 25,
  '36_51': 27,
  '36_52': 27,
  '36_53': 27,
  '36_54': 29,
  '36_55': 29,
  '36_56': 29,
  '36_57': 29,
  '36_58': 31,
  '36_59': 31,
  '36_60': 31,
  '37_2': 0,
  '37_3': 0,
  '37_4': 0,
  '37_5': 7,
  '37_6': 9,
  '37_7': 9,
  '37_8': 11,
  '37_9': 13,
  '37_10': 15,
  '37_11': 15,
  '37_12': 17,
  '37_13': 19,
  '37_14': 21,
  '37_15': 19,
  '37_16': 17,
  '37_17': 15,
  '37_18': 13,
  '37_19': 11,
  '37_20': 11,
  '37_21': 11,
  '37_22': 13,
  '37_23': 13,
  '37_24': 15,
  '37_25': 15,
  '37_26': 17,
  '37_27': 17,
  '37_28': 19,
  '37_29': 19,
  '37_30': 19,
  '37_31': 19,
  '37_32': 21,
  '37_33': 21,
  '37_34': 21,
  '37_35': 21,
  '37_36': 21,
  '37_37': 21,
  '37_38': 23,
  '37_39': 23,
  '37_40': 23,
  '37_41': 23,
  '37_42': 23,
  '37_43': 23,
  '37_44': 23,
  '37_45': 23,
  '37_46': 23,
  '37_47': 25,
  '37_48': 23,
  '37_49': 25,
  '37_50': 25,
  '37_51': 27,
  '37_52': 27,
  '37_53': 27,
  '37_54': 29,
  '37_55': 29,
  '37_56': 29,
  '37_57': 29,
  '37_58': 31,
  '37_59': 31,
  '37_60': 31,
  '38_2': 0,
  '38_3': 0,
  '38_4': 0,
  '38_5': 7,
  '38_6': 9,
  '38_7': 9,
  '38_8': 11,
  '38_9': 13,
  '38_10': 15,
  '38_11': 15,
  '38_12': 17,
  '38_13': 19,
  '38_14': 21,
  '38_15': 19,
  '38_16': 17,
  '38_17': 15,
  '38_18': 13,
  '38_19': 11,
  '38_20': 11,
  '38_21': 11,
  '38_22': 13,
  '38_23': 13,
  '38_24': 15,
  '38_25': 15,
  '38_26': 17,
  '38_27': 17,
  '38_28': 19,
  '38_29': 19,
  '38_30': 19,
  '38_31': 19,
  '38_32': 21,
  '38_33': 21,
  '38_34': 21,
  '38_35': 21,
  '38_36': 21,
  '38_37': 21,
  '38_38': 23,
  '38_39': 23,
  '38_40': 23,
  '38_41': 23,
  '38_42': 23,
  '38_43': 23,
  '38_44': 23,
  '38_45': 23,
  '38_46': 23,
  '38_47': 25,
  '38_48': 23,
  '38_49': 25,
  '38_50': 25,
  '38_51': 27,
  '38_52': 27,
  '38_53': 27,
  '38_54': 29,
  '38_55': 29,
  '38_56': 29,
  '38_57': 29,
  '38_58': 31,
  '38_59': 31,
  '38_60': 31,
  '39_2': 0,
  '39_3': 0,
  '39_4': 0,
  '39_5': 7,
  '39_6': 9,
  '39_7': 9,
  '39_8': 11,
  '39_9': 13,
  '39_10': 15,
  '39_11': 15,
  '39_12': 17,
  '39_13': 19,
  '39_14': 21,
  '39_15': 19,
  '39_16': 17,
  '39_17': 15,
  '39_18': 13,
  '39_19': 11,
  '39_20': 11,
  '39_21': 11,
  '39_22': 13,
  '39_23': 13,
  '39_24': 15,
  '39_25': 15,
  '39_26': 17,
  '39_27': 17,
  '39_28': 19,
  '39_29': 19,
  '39_30': 19,
  '39_31': 19,
  '39_32': 21,
  '39_33': 21,
  '39_34': 21,
  '39_35': 21,
  '39_36': 21,
  '39_37': 21,
  '39_38': 23,
  '39_39': 23,
  '39_40': 23,
  '39_41': 23,
  '39_42': 23,
  '39_43': 23,
  '39_44': 23,
  '39_45': 23,
  '39_46': 23,
  '39_47': 25,
  '39_48': 23,
  '39_49': 25,
  '39_50': 25,
  '39_51': 27,
  '39_52': 27,
  '39_53': 27,
  '39_54': 29,
  '39_55': 29,
  '39_56': 29,
  '39_57': 29,
  '39_58': 31,
  '39_59': 31,
  '39_60': 31,
  '40_2': 0,
  '40_3': 0,
  '40_4': 0,
  '40_5': 7,
  '40_6': 9,
  '40_7': 9,
  '40_8': 11,
  '40_9': 13,
  '40_10': 15,
  '40_11': 15,
  '40_12': 17,
  '40_13': 19,
  '40_14': 21,
  '40_15': 19,
  '40_16': 17,
  '40_17': 15,
  '40_18': 13,
  '40_19': 11,
  '40_20': 11,
  '40_21': 11,
  '40_22': 13,
  '40_23': 13,
  '40_24': 15,
  '40_25': 15,
  '40_26': 17,
  '40_27': 17,
  '40_28': 19,
  '40_29': 19,
  '40_30': 19,
  '40_31': 19,
  '40_32': 21,
  '40_33': 21,
  '40_34': 21,
  '40_35': 21,
  '40_36': 21,
  '40_37': 21,
  '40_38': 23,
  '40_39': 23,
  '40_40': 23,
  '40_41': 23,
  '40_42': 23,
  '40_43': 23,
  '40_44': 23,
  '40_45': 23,
  '40_46': 23,
  '40_47': 25,
  '40_48': 23,
  '40_49': 25,
  '40_50': 25,
  '40_51': 27,
  '40_52': 27,
  '40_53': 27,
  '40_54': 29,
  '40_55': 29,
  '40_56': 29,
  '40_57': 29,
  '40_58': 31,
  '40_59': 31,
  '40_60': 31
};

// export const beamsMatrix: { [key: string]: number; }[] = [
//   {
//     '1': 2,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 0,
//     '6': 0,
//     '7': 0,
//     '8': 0,
//     '9': 0,
//     '10': 0,
//     '11': 0,
//     '12': 0,
//     '13': 0,
//     '14': 0,
//     '15': 0,
//     '16': 0,
//     '17': 0,
//     '18': 0,
//     '19': 0,
//     '20': 0,
//     '21': 0,
//     '22': 0,
//     '23': 0,
//     '24': 0,
//     '25': 0,
//     '26': 0,
//     '27': 0,
//     '28': 0,
//     '29': 0,
//     '30': 0,
//     '31': 0,
//     '32': 0,
//     '33': 0,
//     '34': 0,
//     '35': 0,
//     '36': 0,
//     '37': 0,
//     '38': 0,
//     '39': 0,
//     '40': 0,
//     '41': 0,
//     '42': 0,
//     '43': 0,
//     '44': 0,
//     '45': 0,
//     '46': 0,
//     '47': 0,
//     '48': 0,
//     '49': 0,
//     '50': 0,
//     '51': 0,
//     '52': 0,
//     '53': 0,
//     '54': 0,
//     '55': 0,
//     '56': 0,
//     '57': 0,
//     '58': 0,
//     '59': 0,
//     '60': 0
//   },
//   {
//     '1': 3,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 0,
//     '6': 0,
//     '7': 0,
//     '8': 0,
//     '9': 0,
//     '10': 0,
//     '11': 0,
//     '12': 0,
//     '13': 0,
//     '14': 0,
//     '15': 0,
//     '16': 0,
//     '17': 0,
//     '18': 0,
//     '19': 0,
//     '20': 0,
//     '21': 0,
//     '22': 0,
//     '23': 0,
//     '24': 0,
//     '25': 0,
//     '26': 0,
//     '27': 0,
//     '28': 0,
//     '29': 0,
//     '30': 0,
//     '31': 0,
//     '32': 0,
//     '33': 0,
//     '34': 0,
//     '35': 0,
//     '36': 0,
//     '37': 0,
//     '38': 0,
//     '39': 0,
//     '40': 0,
//     '41': 0,
//     '42': 0,
//     '43': 0,
//     '44': 0,
//     '45': 0,
//     '46': 0,
//     '47': 0,
//     '48': 0,
//     '49': 0,
//     '50': 0,
//     '51': 0,
//     '52': 0,
//     '53': 0,
//     '54': 0,
//     '55': 0,
//     '56': 0,
//     '57': 0,
//     '58': 0,
//     '59': 0,
//     '60': 0
//   },
//   {
//     '1': 4,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 0,
//     '6': 0,
//     '7': 0,
//     '8': 0,
//     '9': 0,
//     '10': 0,
//     '11': 0,
//     '12': 0,
//     '13': 0,
//     '14': 0,
//     '15': 0,
//     '16': 0,
//     '17': 0,
//     '18': 0,
//     '19': 0,
//     '20': 0,
//     '21': 0,
//     '22': 0,
//     '23': 0,
//     '24': 0,
//     '25': 0,
//     '26': 0,
//     '27': 0,
//     '28': 0,
//     '29': 0,
//     '30': 0,
//     '31': 0,
//     '32': 0,
//     '33': 0,
//     '34': 0,
//     '35': 0,
//     '36': 0,
//     '37': 0,
//     '38': 0,
//     '39': 0,
//     '40': 0,
//     '41': 0,
//     '42': 0,
//     '43': 0,
//     '44': 0,
//     '45': 0,
//     '46': 0,
//     '47': 0,
//     '48': 0,
//     '49': 0,
//     '50': 0,
//     '51': 0,
//     '52': 0,
//     '53': 0,
//     '54': 0,
//     '55': 0,
//     '56': 0,
//     '57': 0,
//     '58': 0,
//     '59': 0,
//     '60': 0
//   },
//   {
//     '1': 5,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 21,
//     '16': 23,
//     '17': 25,
//     '18': 27,
//     '19': 27,
//     '20': 29,
//     '21': 35,
//     '22': 37,
//     '23': 37,
//     '24': 39,
//     '25': 41,
//     '26': 41,
//     '27': 43,
//     '28': 43,
//     '29': 45,
//     '30': 45,
//     '31': 47,
//     '32': 47,
//     '33': 49,
//     '34': 49,
//     '35': 51,
//     '36': 51,
//     '37': 53,
//     '38': 53,
//     '39': 55,
//     '40': 55,
//     '41': 57,
//     '42': 59,
//     '43': 61,
//     '44': 63,
//     '45': 65,
//     '46': 67,
//     '47': 69,
//     '48': 71,
//     '49': 73,
//     '50': 75,
//     '51': 77,
//     '52': 79,
//     '53': 81,
//     '54': 83,
//     '55': 85,
//     '56': 86,
//     '57': 87,
//     '58': 88,
//     '59': 89,
//     '60': 89
//   },
//   {
//     '1': 6,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 21,
//     '16': 23,
//     '17': 25,
//     '18': 27,
//     '19': 27,
//     '20': 27,
//     '21': 33,
//     '22': 35,
//     '23': 35,
//     '24': 37,
//     '25': 39,
//     '26': 39,
//     '27': 41,
//     '28': 41,
//     '29': 43,
//     '30': 43,
//     '31': 45,
//     '32': 45,
//     '33': 47,
//     '34': 47,
//     '35': 49,
//     '36': 49,
//     '37': 51,
//     '38': 51,
//     '39': 53,
//     '40': 53,
//     '41': 57,
//     '42': 59,
//     '43': 61,
//     '44': 63,
//     '45': 65,
//     '46': 67,
//     '47': 69,
//     '48': 71,
//     '49': 73,
//     '50': 75,
//     '51': 77,
//     '52': 79,
//     '53': 81,
//     '54': 83,
//     '55': 85,
//     '56': 86,
//     '57': 87,
//     '58': 88,
//     '59': 89,
//     '60': 89
//   },
//   {
//     '1': 7,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 21,
//     '16': 23,
//     '17': 25,
//     '18': 25,
//     '19': 27,
//     '20': 25,
//     '21': 31,
//     '22': 33,
//     '23': 33,
//     '24': 35,
//     '25': 37,
//     '26': 37,
//     '27': 39,
//     '28': 39,
//     '29': 41,
//     '30': 41,
//     '31': 43,
//     '32': 43,
//     '33': 45,
//     '34': 45,
//     '35': 47,
//     '36': 47,
//     '37': 49,
//     '38': 49,
//     '39': 51,
//     '40': 51,
//     '41': 55,
//     '42': 57,
//     '43': 59,
//     '44': 61,
//     '45': 63,
//     '46': 65,
//     '47': 67,
//     '48': 69,
//     '49': 71,
//     '50': 73,
//     '51': 75,
//     '52': 77,
//     '53': 79,
//     '54': 81,
//     '55': 83,
//     '56': 84,
//     '57': 85,
//     '58': 86,
//     '59': 87,
//     '60': 87
//   },
//   {
//     '1': 8,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 21,
//     '16': 23,
//     '17': 25,
//     '18': 25,
//     '19': 25,
//     '20': 23,
//     '21': 29,
//     '22': 31,
//     '23': 31,
//     '24': 33,
//     '25': 35,
//     '26': 35,
//     '27': 37,
//     '28': 37,
//     '29': 39,
//     '30': 39,
//     '31': 41,
//     '32': 41,
//     '33': 43,
//     '34': 43,
//     '35': 45,
//     '36': 45,
//     '37': 47,
//     '38': 47,
//     '39': 49,
//     '40': 49,
//     '41': 55,
//     '42': 57,
//     '43': 59,
//     '44': 61,
//     '45': 63,
//     '46': 65,
//     '47': 67,
//     '48': 69,
//     '49': 71,
//     '50': 73,
//     '51': 75,
//     '52': 77,
//     '53': 79,
//     '54': 81,
//     '55': 83,
//     '56': 84,
//     '57': 85,
//     '58': 86,
//     '59': 87,
//     '60': 87
//   },
//   {
//     '1': 9,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 21,
//     '16': 23,
//     '17': 25,
//     '18': 23,
//     '19': 23,
//     '20': 21,
//     '21': 25,
//     '22': 27,
//     '23': 27,
//     '24': 29,
//     '25': 31,
//     '26': 31,
//     '27': 33,
//     '28': 33,
//     '29': 35,
//     '30': 35,
//     '31': 37,
//     '32': 37,
//     '33': 39,
//     '34': 39,
//     '35': 41,
//     '36': 41,
//     '37': 43,
//     '38': 43,
//     '39': 45,
//     '40': 45,
//     '41': 51,
//     '42': 53,
//     '43': 55,
//     '44': 57,
//     '45': 59,
//     '46': 61,
//     '47': 63,
//     '48': 65,
//     '49': 67,
//     '50': 69,
//     '51': 71,
//     '52': 73,
//     '53': 75,
//     '54': 77,
//     '55': 79,
//     '56': 80,
//     '57': 81,
//     '58': 82,
//     '59': 83,
//     '60': 83
//   },
//   {
//     '1': 10,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 21,
//     '16': 23,
//     '17': 23,
//     '18': 23,
//     '19': 21,
//     '20': 21,
//     '21': 25,
//     '22': 27,
//     '23': 27,
//     '24': 29,
//     '25': 31,
//     '26': 31,
//     '27': 33,
//     '28': 33,
//     '29': 35,
//     '30': 35,
//     '31': 37,
//     '32': 37,
//     '33': 39,
//     '34': 39,
//     '35': 41,
//     '36': 41,
//     '37': 43,
//     '38': 43,
//     '39': 45,
//     '40': 45,
//     '41': 47,
//     '42': 49,
//     '43': 51,
//     '44': 53,
//     '45': 53,
//     '46': 55,
//     '47': 55,
//     '48': 57,
//     '49': 57,
//     '50': 59,
//     '51': 59,
//     '52': 61,
//     '53': 61,
//     '54': 63,
//     '55': 63,
//     '56': 65,
//     '57': 65,
//     '58': 67,
//     '59': 69,
//     '60': 69
//   },
//   {
//     '1': 11,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 21,
//     '16': 19,
//     '17': 21,
//     '18': 21,
//     '19': 21,
//     '20': 19,
//     '21': 21,
//     '22': 23,
//     '23': 23,
//     '24': 25,
//     '25': 27,
//     '26': 27,
//     '27': 29,
//     '28': 29,
//     '29': 31,
//     '30': 31,
//     '31': 33,
//     '32': 33,
//     '33': 35,
//     '34': 35,
//     '35': 37,
//     '36': 37,
//     '37': 39,
//     '38': 39,
//     '39': 41,
//     '40': 41,
//     '41': 43,
//     '42': 45,
//     '43': 47,
//     '44': 49,
//     '45': 49,
//     '46': 51,
//     '47': 51,
//     '48': 53,
//     '49': 53,
//     '50': 55,
//     '51': 55,
//     '52': 57,
//     '53': 57,
//     '54': 59,
//     '55': 59,
//     '56': 61,
//     '57': 61,
//     '58': 63,
//     '59': 65,
//     '60': 65
//   },
//   {
//     '1': 12,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 21,
//     '16': 19,
//     '17': 21,
//     '18': 21,
//     '19': 19,
//     '20': 19,
//     '21': 21,
//     '22': 23,
//     '23': 23,
//     '24': 25,
//     '25': 27,
//     '26': 27,
//     '27': 29,
//     '28': 29,
//     '29': 31,
//     '30': 31,
//     '31': 33,
//     '32': 33,
//     '33': 35,
//     '34': 35,
//     '35': 37,
//     '36': 37,
//     '37': 39,
//     '38': 39,
//     '39': 41,
//     '40': 41,
//     '41': 41,
//     '42': 43,
//     '43': 45,
//     '44': 47,
//     '45': 47,
//     '46': 49,
//     '47': 49,
//     '48': 51,
//     '49': 51,
//     '50': 53,
//     '51': 53,
//     '52': 55,
//     '53': 55,
//     '54': 57,
//     '55': 57,
//     '56': 59,
//     '57': 59,
//     '58': 61,
//     '59': 63,
//     '60': 63
//   },
//   {
//     '1': 13,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 19,
//     '18': 19,
//     '19': 19,
//     '20': 17,
//     '21': 19,
//     '22': 21,
//     '23': 21,
//     '24': 23,
//     '25': 25,
//     '26': 25,
//     '27': 27,
//     '28': 27,
//     '29': 29,
//     '30': 29,
//     '31': 31,
//     '32': 31,
//     '33': 33,
//     '34': 33,
//     '35': 35,
//     '36': 35,
//     '37': 37,
//     '38': 37,
//     '39': 39,
//     '40': 39,
//     '41': 41,
//     '42': 39,
//     '43': 41,
//     '44': 43,
//     '45': 45,
//     '46': 45,
//     '47': 49,
//     '48': 49,
//     '49': 51,
//     '50': 51,
//     '51': 53,
//     '52': 53,
//     '53': 55,
//     '54': 55,
//     '55': 57,
//     '56': 57,
//     '57': 59,
//     '58': 59,
//     '59': 61,
//     '60': 61
//   },
//   {
//     '1': 14,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 19,
//     '18': 19,
//     '19': 17,
//     '20': 17,
//     '21': 19,
//     '22': 21,
//     '23': 21,
//     '24': 23,
//     '25': 23,
//     '26': 25,
//     '27': 25,
//     '28': 27,
//     '29': 27,
//     '30': 29,
//     '31': 29,
//     '32': 31,
//     '33': 31,
//     '34': 33,
//     '35': 33,
//     '36': 35,
//     '37': 35,
//     '38': 37,
//     '39': 37,
//     '40': 37,
//     '41': 37,
//     '42': 35,
//     '43': 37,
//     '44': 39,
//     '45': 39,
//     '46': 41,
//     '47': 41,
//     '48': 43,
//     '49': 43,
//     '50': 45,
//     '51': 45,
//     '52': 47,
//     '53': 47,
//     '54': 49,
//     '55': 49,
//     '56': 51,
//     '57': 51,
//     '58': 53,
//     '59': 55,
//     '60': 55
//   },
//   {
//     '1': 15,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 19,
//     '18': 17,
//     '19': 17,
//     '20': 15,
//     '21': 17,
//     '22': 19,
//     '23': 19,
//     '24': 21,
//     '25': 21,
//     '26': 23,
//     '27': 23,
//     '28': 25,
//     '29': 25,
//     '30': 25,
//     '31': 27,
//     '32': 27,
//     '33': 27,
//     '34': 29,
//     '35': 29,
//     '36': 29,
//     '37': 31,
//     '38': 31,
//     '39': 31,
//     '40': 33,
//     '41': 33,
//     '42': 31,
//     '43': 33,
//     '44': 35,
//     '45': 35,
//     '46': 39,
//     '47': 39,
//     '48': 41,
//     '49': 41,
//     '50': 43,
//     '51': 43,
//     '52': 45,
//     '53': 45,
//     '54': 47,
//     '55': 47,
//     '56': 49,
//     '57': 49,
//     '58': 49,
//     '59': 51,
//     '60': 51
//   },
//   {
//     '1': 16,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 17,
//     '18': 17,
//     '19': 15,
//     '20': 15,
//     '21': 17,
//     '22': 19,
//     '23': 19,
//     '24': 21,
//     '25': 21,
//     '26': 23,
//     '27': 23,
//     '28': 25,
//     '29': 25,
//     '30': 25,
//     '31': 27,
//     '32': 27,
//     '33': 27,
//     '34': 29,
//     '35': 29,
//     '36': 29,
//     '37': 31,
//     '38': 31,
//     '39': 31,
//     '40': 31,
//     '41': 31,
//     '42': 31,
//     '43': 33,
//     '44': 31,
//     '45': 31,
//     '46': 35,
//     '47': 35,
//     '48': 37,
//     '49': 37,
//     '50': 37,
//     '51': 39,
//     '52': 39,
//     '53': 39,
//     '54': 41,
//     '55': 41,
//     '56': 41,
//     '57': 43,
//     '58': 43,
//     '59': 43,
//     '60': 43
//   },
//   {
//     '1': 17,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 17,
//     '18': 15,
//     '19': 15,
//     '20': 13,
//     '21': 13,
//     '22': 15,
//     '23': 15,
//     '24': 17,
//     '25': 17,
//     '26': 19,
//     '27': 19,
//     '28': 21,
//     '29': 21,
//     '30': 21,
//     '31': 23,
//     '32': 23,
//     '33': 23,
//     '34': 25,
//     '35': 25,
//     '36': 25,
//     '37': 27,
//     '38': 27,
//     '39': 27,
//     '40': 27,
//     '41': 27,
//     '42': 27,
//     '43': 29,
//     '44': 27,
//     '45': 29,
//     '46': 31,
//     '47': 31,
//     '48': 31,
//     '49': 33,
//     '50': 33,
//     '51': 33,
//     '52': 35,
//     '53': 35,
//     '54': 35,
//     '55': 37,
//     '56': 37,
//     '57': 39,
//     '58': 39,
//     '59': 39,
//     '60': 39
//   },
//   {
//     '1': 18,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 17,
//     '18': 15,
//     '19': 13,
//     '20': 13,
//     '21': 13,
//     '22': 15,
//     '23': 15,
//     '24': 17,
//     '25': 17,
//     '26': 19,
//     '27': 19,
//     '28': 21,
//     '29': 21,
//     '30': 21,
//     '31': 23,
//     '32': 23,
//     '33': 23,
//     '34': 25,
//     '35': 25,
//     '36': 25,
//     '37': 27,
//     '38': 27,
//     '39': 27,
//     '40': 27,
//     '41': 27,
//     '42': 27,
//     '43': 27,
//     '44': 27,
//     '45': 29,
//     '46': 29,
//     '47': 29,
//     '48': 31,
//     '49': 31,
//     '50': 33,
//     '51': 33,
//     '52': 33,
//     '53': 35,
//     '54': 35,
//     '55': 35,
//     '56': 37,
//     '57': 37,
//     '58': 37,
//     '59': 39,
//     '60': 39
//   },
//   {
//     '1': 19,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 13,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 25,
//     '46': 25,
//     '47': 27,
//     '48': 27,
//     '49': 29,
//     '50': 29,
//     '51': 31,
//     '52': 31,
//     '53': 31,
//     '54': 31,
//     '55': 33,
//     '56': 33,
//     '57': 33,
//     '58': 35,
//     '59': 35,
//     '60': 35
//   },
//   {
//     '1': 20,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 21,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 22,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 23,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 24,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 25,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 26,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 27,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 28,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 29,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 30,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 31,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 32,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 33,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 34,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 35,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 36,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 37,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 38,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 39,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   },
//   {
//     '1': 40,
//     '2': 0,
//     '3': 0,
//     '4': 0,
//     '5': 7,
//     '6': 9,
//     '7': 9,
//     '8': 11,
//     '9': 13,
//     '10': 15,
//     '11': 15,
//     '12': 17,
//     '13': 19,
//     '14': 21,
//     '15': 19,
//     '16': 17,
//     '17': 15,
//     '18': 13,
//     '19': 11,
//     '20': 11,
//     '21': 11,
//     '22': 13,
//     '23': 13,
//     '24': 15,
//     '25': 15,
//     '26': 17,
//     '27': 17,
//     '28': 19,
//     '29': 19,
//     '30': 19,
//     '31': 19,
//     '32': 21,
//     '33': 21,
//     '34': 21,
//     '35': 21,
//     '36': 21,
//     '37': 21,
//     '38': 23,
//     '39': 23,
//     '40': 23,
//     '41': 23,
//     '42': 23,
//     '43': 23,
//     '44': 23,
//     '45': 23,
//     '46': 23,
//     '47': 25,
//     '48': 23,
//     '49': 25,
//     '50': 25,
//     '51': 27,
//     '52': 27,
//     '53': 27,
//     '54': 29,
//     '55': 29,
//     '56': 29,
//     '57': 29,
//     '58': 31,
//     '59': 31,
//     '60': 31
//   }
// ];
//
// export const generateMatrix = () => {
//   return beamsMatrix.reduce((all, object) => {
//       const width: number = object['1'];
//       const objectKeys: string[] = Object.keys(object);
//
//       const widthLengthKeys = objectKeys.reduce((all, key) => {
//           if (key === '1') {
//             return all;
//           }
//
//           all = {
//             ...all,
//             [`${width}_${key}`]: object[key]
//           };
//
//           return { ...all };
//         }, {} as {
//           [key: string]: number;
//         }[]
//       );
//
//       all = {
//         ...all,
//         ...widthLengthKeys
//       };
//
//       return all;
//     }, {} as {
//       [key: string]: number;
//     }[]
//   );
// };
