import React, { FC } from 'react';

import { convertInchesToCentimeters } from '../../utilities/converting-units';
import { Canopy } from './Canopy';
import { defaultLegWidth } from '../../utilities/constants';

interface CanopiesProps {
  hPositionM: (x: number) => number;
  totalWidthInches: number;
  totalLengthInches: number;
  nodes: any;
}

export const Canopies: FC<CanopiesProps> = ({
  hPositionM,
  totalWidthInches,
  totalLengthInches,
  nodes
}) => {
  if (totalLengthInches <= 240 && totalWidthInches <= 240) {
    return (
      <Canopy
        hPositionM={hPositionM}
        totalWidthInches={totalWidthInches}
        totalLengthInches={totalLengthInches}
        widthPositionOffset={0}
        lengthPositionOffset={0}
        nodes={nodes}
      />
    );
  }

  return (
    <>
      {totalLengthInches > 240 && totalLengthInches <= 480 && totalWidthInches <= 240 && (
        <>
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches}
            totalLengthInches={totalLengthInches / 2 + 2}
            widthPositionOffset={0}
            lengthPositionOffset={-convertInchesToCentimeters(totalLengthInches) / 4 / 100 + 0.01 * 2.54}
            nodes={nodes}
          />
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches}
            totalLengthInches={totalLengthInches / 2 + 2}
            widthPositionOffset={0}
            lengthPositionOffset={convertInchesToCentimeters(totalLengthInches) / 4 / 100 - 0.01 * 2.54}
            nodes={nodes}
          />
        </>
      )}

      {totalWidthInches > 240 && totalLengthInches <= 240 && (
        <>
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches / 2 + defaultLegWidth}
            totalLengthInches={totalLengthInches}
            widthPositionOffset={-convertInchesToCentimeters(totalWidthInches) / 4 / 100 + convertInchesToCentimeters(defaultLegWidth / 2) / 100}
            lengthPositionOffset={0}
            nodes={nodes}
          />
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches / 2 + defaultLegWidth}
            totalLengthInches={totalLengthInches}
            widthPositionOffset={convertInchesToCentimeters(totalWidthInches) / 4 / 100 - convertInchesToCentimeters(defaultLegWidth / 2) / 100}
            lengthPositionOffset={0}
            nodes={nodes}
          />
        </>
      )}

      {totalLengthInches > 480 && totalWidthInches <= 240 && (
        <>
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches}
            totalLengthInches={totalLengthInches / 3 + 2.5}
            widthPositionOffset={0}
            lengthPositionOffset={0}
            nodes={nodes}
          />
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches}
            totalLengthInches={totalLengthInches / 3 + 3.5}
            widthPositionOffset={0}
            lengthPositionOffset={convertInchesToCentimeters(totalLengthInches) / 3 / 100 - 0.015 * 2.64}
            middleHookOffset={0}
            nodes={nodes}
          />
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches}
            totalLengthInches={totalLengthInches / 3 + 3.5}
            widthPositionOffset={0}
            lengthPositionOffset={-convertInchesToCentimeters(totalLengthInches) / 3 / 100 + 0.015 * 2.64}
            middleHookOffset={0}
            nodes={nodes}
          />
        </>
      )}

      {totalLengthInches > 240 && totalLengthInches <= 480 && totalWidthInches > 240 && (
        <>
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches / 2 + defaultLegWidth}
            totalLengthInches={totalLengthInches / 2 + 2}
            widthPositionOffset={-convertInchesToCentimeters(totalWidthInches) / 4 / 100 + convertInchesToCentimeters(defaultLegWidth / 2) / 100}
            lengthPositionOffset={-convertInchesToCentimeters(totalLengthInches) / 4 / 100 + 0.01 * 2.54}
            nodes={nodes}
          />
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches / 2 + defaultLegWidth}
            totalLengthInches={totalLengthInches / 2 + 2}
            widthPositionOffset={-convertInchesToCentimeters(totalWidthInches) / 4 / 100 + convertInchesToCentimeters(defaultLegWidth / 2) / 100}
            lengthPositionOffset={convertInchesToCentimeters(totalLengthInches) / 4 / 100 - 0.01 * 2.54}
            nodes={nodes}
          />
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches / 2 + defaultLegWidth}
            totalLengthInches={totalLengthInches / 2 + 2}
            widthPositionOffset={convertInchesToCentimeters(totalWidthInches) / 4 / 100 - convertInchesToCentimeters(defaultLegWidth / 2) / 100}
            lengthPositionOffset={-convertInchesToCentimeters(totalLengthInches) / 4 / 100 + 0.01 * 2.54}
            nodes={nodes}
          />
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches / 2 + defaultLegWidth}
            totalLengthInches={totalLengthInches / 2 + 2}
            widthPositionOffset={convertInchesToCentimeters(totalWidthInches) / 4 / 100 - convertInchesToCentimeters(defaultLegWidth / 2) / 100}
            lengthPositionOffset={convertInchesToCentimeters(totalLengthInches) / 4 / 100 - 0.01 * 2.54}
            nodes={nodes}
          />
        </>
      )}


      {totalLengthInches > 480 && totalWidthInches > 240 && (
        <>
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches / 2 + defaultLegWidth}
            totalLengthInches={totalLengthInches / 3 + 3.5}
            widthPositionOffset={-convertInchesToCentimeters(totalWidthInches) / 4 / 100 + convertInchesToCentimeters(defaultLegWidth / 2) / 100}
            lengthPositionOffset={-convertInchesToCentimeters(totalLengthInches) / 3 / 100 + 0.015 * 2.64}
            middleHookOffset={0}
            nodes={nodes}
          />
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches / 2 + defaultLegWidth}
            totalLengthInches={totalLengthInches / 3 + 2.5}
            widthPositionOffset={-convertInchesToCentimeters(totalWidthInches) / 4 / 100 + convertInchesToCentimeters(defaultLegWidth / 2) / 100}
            lengthPositionOffset={0}
            nodes={nodes}
          />
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches / 2 + defaultLegWidth}
            totalLengthInches={totalLengthInches / 3 + 3.5}
            widthPositionOffset={-convertInchesToCentimeters(totalWidthInches) / 4 / 100 + convertInchesToCentimeters(defaultLegWidth / 2) / 100}
            lengthPositionOffset={convertInchesToCentimeters(totalLengthInches) / 3 / 100 - 0.015 * 2.64}
            middleHookOffset={0}
            nodes={nodes}
          />

          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches / 2 + defaultLegWidth}
            totalLengthInches={totalLengthInches / 3 + 3.5}
            widthPositionOffset={convertInchesToCentimeters(totalWidthInches) / 4 / 100 - convertInchesToCentimeters(defaultLegWidth / 2) / 100}
            lengthPositionOffset={-convertInchesToCentimeters(totalLengthInches) / 3 / 100 + 0.015 * 2.64}
            middleHookOffset={0}
            nodes={nodes}
          />
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches / 2 + defaultLegWidth}
            totalLengthInches={totalLengthInches / 3 + 2.5}
            widthPositionOffset={convertInchesToCentimeters(totalWidthInches) / 4 / 100 - convertInchesToCentimeters(defaultLegWidth / 2) / 100}
            lengthPositionOffset={0}
            nodes={nodes}
          />
          <Canopy
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches / 2 + defaultLegWidth}
            totalLengthInches={totalLengthInches / 3 + 3.5}
            widthPositionOffset={convertInchesToCentimeters(totalWidthInches) / 4 / 100 - convertInchesToCentimeters(defaultLegWidth / 2) / 100}
            lengthPositionOffset={convertInchesToCentimeters(totalLengthInches) / 3 / 100 - 0.015 * 2.64}
            middleHookOffset={0}
            nodes={nodes}
          />
        </>
      )}
    </>
  );
};
