import React, { FC } from 'react';

import StructureColorRadio from '../radio/StructureColorRadio';
import PergolaRadioButtons from '../radio/PergolaRadioButtons';

export const StyleTabContent: FC = (): JSX.Element => (
  <>
    <div>
      <div className="lg:hidden font-light text-sm text-center mb-4">Choose your
        <span className="font-semibold text-text"> Pergola design</span>
      </div>

      <div className="hidden lg:block text-center text-text text-sm mt-12 mb-8">Choose your
        <span className="font-semibold text-text"> pergola design:</span>
      </div>

      <PergolaRadioButtons/>
    </div>

    <div>
      <div className="lg:hidden font-light text-sm text-center my-4">Choose your
        <span className="font-semibold text-text"> Structure color</span>
      </div>

      <div className="hidden lg:block text-center text-text text-sm mt-12 mb-8">Choose your
        <span className="font-semibold text-text"> structure color:</span>
      </div>

      <StructureColorRadio/>
    </div>
  </>
);
