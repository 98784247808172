import React, { FC } from 'react';

import paragonb from '../../assets/paragonb.svg';
import loader from '../../assets/loader.gif';
import { useMediaQuery } from '../../contexts/MediaQueryContext';
import { usePergola } from '../../data-stores/pergola';
import { StructureColor } from '../../configurations/colors';

export interface LoaderProps {
  inCanvas?: boolean;
}

export const Loader: FC<LoaderProps> = ({ inCanvas }): JSX.Element => {
  const mediaQueryContext = useMediaQuery();
  let height = mediaQueryContext.onMobile ? '80vh' : '100vh';
  const structureColor = usePergola(state => state.design.color);

  if (inCanvas) {
    height = mediaQueryContext.onMobile ? '30%' : '100%';
  }

  return (
    <div
      className="w-full bg-background"
      style={{ height }}
    >
      {!inCanvas &&
      mediaQueryContext.onMobile
        ? (
          <div className="fixed hidden bg-white flex justify-center items-center mobile_top_bar">
            <img
              src={paragonb}
              style={{
                width: '91.85px',
                height: '49.81px'
              }}
              alt="paragon logo"
            />
          </div>
        )
        : (
          <div className="fixed hidden pl-8 pt-10">
            <img
              src={paragonb}
              alt="paragon logo"
            />
          </div>
        )
      }

      <div
        className={`flex flex-col justify-center items-center transition-colors duration-1000 ${structureColor === StructureColor.HAMPTON_WHITE ? 'bg-gray' : ''}`}
        style={{
          height,
          paddingTop: inCanvas ? mediaQueryContext.onMobile ? '110px' : 0 : mediaQueryContext.onMobile ? '50px' : 0
        }}
      >
        <img
          style={{
            height: inCanvas && mediaQueryContext.onMobile ? '70px' : '200px',
            width: inCanvas && mediaQueryContext.onMobile ? '70px' : '200px'
          }}
          src={loader}
          alt="paragon loader"
        />
        <div
          className={`flex flex-col justify-center items-center transition-colors duration-1000 ${structureColor === StructureColor.HAMPTON_WHITE ? 'bg-gray' : ''}`}>
          <div className="text-green text-sm font-semibold">One moment please.</div>
        </div>
      </div>
    </div>
  );
};
