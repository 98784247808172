import create from 'zustand';

import { DimensionsDefaultValues } from '../configurations/dimensions';
import { CanopyColor, StructureColor } from '../configurations/colors';
import { PergolaConfigurationUpdateData } from '../firebase/firestore/types/configuration';

export enum PergolaStoreKeys {
  DESIGN = 'design',
  DIMENSIONS = 'dimensions',
  CANOPY = 'canopy',
  BEAMS = 'beams'
}

export enum PergolaDesign {
  FREE_STANDING = 'free_standing',
  ATTACHED = 'attached'
}

export enum DimensionsOperations {
  MINUS = 'minus',
  PLUS = 'plus',
  VALUE = 'value'
}

export interface PergolaStore {
  design: {
    pergola: PergolaDesign;
    color: StructureColor;
  };
  dimensions: {
    height: {
      feet: number;
      inches: number;
    };
    length: {
      feet: number;
      inches: number;
    };
    width: {
      feet: number;
      inches: number;
    };
  };
  canopy: {
    hasCanopy: boolean;
    color: CanopyColor | '';
  };
  beams: number;
  collaborative: boolean;
  canopyPrice: number;
  price: number;
  discount: number;
  weight: number;
  configurationID: string;
  readyToUpdate: boolean;
  buildNumber: string;
  showDimensionsLabels: boolean;
  changePergolaDesign: (value: PergolaDesign) => void;
  changePergolaColor: (value: StructureColor) => void;
  changeHeightFeet: (operation: DimensionsOperations, value?: number) => void;
  changeHeightInches: (operation: DimensionsOperations, value?: number) => void;
  changeLengthFeet: (operation: DimensionsOperations, value?: number) => void;
  changeLengthInches: (operation: DimensionsOperations, value?: number) => void;
  changeWidthFeet: (operation: DimensionsOperations, value?: number) => void;
  changeWidthInches: (operation: DimensionsOperations, value?: number) => void;
  toggleHasCanopy: () => void;
  changeCanopyColor: (value: CanopyColor) => void;
  changeBeamsCount: (operation: DimensionsOperations, beamStep: number, value?: number) => void;
  updatePergolaPrice: (price: number) => void;
  updateDiscountPrice: (price: number) => void;
  updatePergolaFromFirebase: (data: Omit<PergolaConfigurationUpdateData, 'collaborative'>) => void;
  updatePergolaBuildNumber: (buildNumber: string) => void;
  updateCanopyPrice: (price: number) => void;
  updatePergolaWeight: (weight: number) => void;
  updateConfigurationID: (id: string) => void;
  setReadyToUpdate: (value: boolean) => void;
  toggleCollaborative: () => void;
  toggleDimensionsLabels: () => void;
}

export const usePergola = create<PergolaStore>(set => ({
  design: {
    pergola: PergolaDesign.FREE_STANDING,
    color: StructureColor.TUSCAN_CEDAR
  },
  dimensions: {
    height: {
      feet: DimensionsDefaultValues.HEIGHT_FEET,
      inches: DimensionsDefaultValues.HEIGHT_INCHES
    },
    length: {
      feet: DimensionsDefaultValues.LENGTH_FEET,
      inches: DimensionsDefaultValues.LENGTH_INCHES
    },
    width: {
      feet: DimensionsDefaultValues.WIDTH_FEET,
      inches: DimensionsDefaultValues.WIDTH_INCHES
    }
  },
  canopy: {
    hasCanopy: false,
    color: ''
  },
  buildNumber: '',
  beams: 11,
  collaborative: true,
  canopyPrice: 0,
  price: 0,
  discount: 0,
  weight: 0,
  configurationID: '',
  readyToUpdate: true,
  showDimensionsLabels: true,
  changePergolaDesign: (value: PergolaDesign) => set((state: PergolaStore) => ({
    ...state,
    design: {
      ...state.design,
      pergola: value
    }
  })),
  changePergolaColor: (value: StructureColor) => set((state: PergolaStore) => ({
    ...state,
    design: {
      ...state.design,
      color: value
    }
  })),
  changeHeightFeet: (operation: DimensionsOperations, value) => set((state: PergolaStore) => {
    if (!value && operation !== DimensionsOperations.VALUE) {
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          height: {
            ...state.dimensions.height,
            feet: operation === DimensionsOperations.MINUS
              ? state.dimensions.height.feet - 1
              : state.dimensions.height.feet + 1
          }
        }
      };
    }

    return {
      ...state,
      dimensions: {
        ...state.dimensions,
        height: {
          ...state.dimensions.height,
          feet: value as number
        }
      }
    };
  }),
  changeHeightInches: (operation: DimensionsOperations, value) => set((state: PergolaStore) => {
    if (!value && operation !== DimensionsOperations.VALUE) {
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          height: {
            ...state.dimensions.height,
            inches: operation === DimensionsOperations.MINUS
              ? state.dimensions.height.inches - 1
              : state.dimensions.height.inches + 1
          }
        }
      };
    }

    return {
      ...state,
      dimensions: {
        ...state.dimensions,
        height: {
          ...state.dimensions.height,
          inches: value as number
        }
      }
    };
  }),
  changeLengthFeet: (operation: DimensionsOperations, value) => set((state: PergolaStore) => {
    if (!value && operation !== DimensionsOperations.VALUE) {
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          length: {
            ...state.dimensions.length,
            feet: operation === DimensionsOperations.MINUS
              ? state.dimensions.length.feet - 1
              : state.dimensions.length.feet + 1
          }
        }
      };
    }

    return {
      ...state,
      dimensions: {
        ...state.dimensions,
        length: {
          ...state.dimensions.length,
          feet: value as number
        }
      }
    };
  }),
  changeLengthInches: (operation: DimensionsOperations, value) => set((state: PergolaStore) => {
    if (!value && operation !== DimensionsOperations.VALUE) {
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          length: {
            ...state.dimensions.length,
            inches: operation === DimensionsOperations.MINUS
              ? state.dimensions.length.inches - 1
              : state.dimensions.length.inches + 1
          }
        }
      };
    }

    return {
      ...state,
      dimensions: {
        ...state.dimensions,
        length: {
          ...state.dimensions.length,
          inches: value as number
        }
      }
    };
  }),
  changeWidthFeet: (operation: DimensionsOperations, value) => set((state: PergolaStore) => {
    if (!value && operation !== DimensionsOperations.VALUE) {
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          width: {
            ...state.dimensions.width,
            feet: operation === DimensionsOperations.MINUS
              ? state.dimensions.width.feet - 1
              : state.dimensions.width.feet + 1
          }
        }
      };
    }

    return {
      ...state,
      dimensions: {
        ...state.dimensions,
        width: {
          ...state.dimensions.width,
          feet: value as number
        }
      }
    };
  }),
  changeWidthInches: (operation: DimensionsOperations, value) => set((state: PergolaStore) => {
    if (!value && operation !== DimensionsOperations.VALUE) {
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          width: {
            ...state.dimensions.width,
            inches: operation === DimensionsOperations.MINUS
              ? state.dimensions.width.inches - 1
              : state.dimensions.width.inches + 1
          }
        }
      };
    }

    return {
      ...state,
      dimensions: {
        ...state.dimensions,
        width: {
          ...state.dimensions.width,
          inches: value as number
        }
      }
    };
  }),
  toggleHasCanopy: () => set((state: PergolaStore) => {
    if (state.canopy.hasCanopy === undefined) {
      return {
        ...state,
        canopy: {
          hasCanopy: true,
          color: CanopyColor.CAPPUCCINO
        }
      };
    }

    if (state.canopy.hasCanopy) {
      return {
        ...state,
        canopy: {
          color: '',
          hasCanopy: !state.canopy.hasCanopy
        }
      };
    } else {
      return {
        ...state,
        canopy: {
          color: CanopyColor.CAPPUCCINO,
          hasCanopy: !state.canopy.hasCanopy
        }
      };
    }
  }),
  changeCanopyColor: (value: CanopyColor) => set((state: PergolaStore) => ({
    ...state,
    canopy: {
      ...state.canopy,
      color: value
    }
  })),
  changeBeamsCount: (operation: DimensionsOperations, beamStep, value) => set((state: PergolaStore) => {
    if (!value && operation !== DimensionsOperations.VALUE) {
      return {
        ...state,
        beams: operation === DimensionsOperations.MINUS
          ? state.beams - beamStep
          : state.beams + beamStep
      };
    }

    return {
      ...state,
      beams: value as number
    };
  }),
  updatePergolaPrice: (price: number) => set((state: PergolaStore) => ({
    ...state,
    price
  })),
  updateDiscountPrice: (discount: number) => set((state: PergolaStore) => ({
    ...state,
    discount
  })),
  updatePergolaFromFirebase: (data: Omit<PergolaConfigurationUpdateData, 'collaborative'>) => set((state: PergolaStore) => ({
    ...state,
    ...data
  })),
  updateCanopyPrice: (canopyPrice: number) => set((state: PergolaStore) => ({
    ...state,
    canopyPrice
  })),
  updatePergolaBuildNumber: (buildNumber: string) => set((state: PergolaStore) => ({
    ...state,
    buildNumber
  })),
  updatePergolaWeight: (weight: number) => set((state: PergolaStore) => ({
    ...state,
    weight
  })),
  updateConfigurationID: (configurationID: string) => set((state: PergolaStore) => ({
    ...state,
    configurationID
  })),
  setReadyToUpdate: (readyToUpdate: boolean) => set((state: PergolaStore) => ({
    ...state,
    readyToUpdate
  })),
  toggleCollaborative: () => set((state: PergolaStore) => ({
    ...state,
    collaborative: !state.collaborative
  })),
  toggleDimensionsLabels: () => set((state: PergolaStore) => ({
    ...state,
    showDimensionsLabels: !state.showDimensionsLabels
  }))
}));
