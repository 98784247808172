import React, { FC } from 'react';
import { CanopyMaterial } from '../../materials/CanopyMaterial';
import { HardwareMaterial } from '../../materials/HardwareMaterial';
import { lengthScaleCanopy } from '../../utilities/scaling';
import { lengthPositionCentimeters, widthPositionMeters } from '../../utilities/positioning-new';

interface CanopyProps {
  hPositionM: (x: number) => number;
  totalWidthInches: number;
  totalLengthInches: number;
  widthPositionOffset: number;
  lengthPositionOffset: number;
  elementWidth: number;
  isLastElement: boolean;
  middleHookOffset?: number;
  nodes: any; // TODO add type
}

const INITIAL_CANOPY_OFFSET = 0.3556;

export const CanopyElement: FC<CanopyProps> = ({
  hPositionM,
  totalWidthInches,
  totalLengthInches,
  widthPositionOffset,
  lengthPositionOffset,
  elementWidth,
  isLastElement,
  middleHookOffset,
  nodes
}): JSX.Element => {
  const wPositionM = widthPositionMeters(totalWidthInches);
  const lPositionCM = lengthPositionCentimeters(totalLengthInches);
  const lScaleC = lengthScaleCanopy(totalLengthInches);

  const hookOffset = middleHookOffset ? middleHookOffset * 100 : 0;

  return (
    <>
      <group name="canopy-element" position={[lengthPositionOffset, hPositionM(2.18), wPositionM - INITIAL_CANOPY_OFFSET - widthPositionOffset]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        {isLastElement && (
          <group position={[-0.0, -(elementWidth * 100 / 2) + 1.9, -3.84]} rotation={[0, 0, Math.PI / 2]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Canopy_left_pipe_2.geometry}
              material={nodes.Canopy_left_pipe_2.material}
              scale={[1, lScaleC(1), 1]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              name={'clpeb'}
              castShadow
              receiveShadow
              geometry={nodes.Canopy_left_pipe_element_back.geometry}
              material={nodes.Canopy_left_pipe_element_back.material}
              position={[0, -lPositionCM, -4.04]}
              rotation={[Math.PI, 0, 0]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Canopy_left_pipe_element_front.geometry}
              material={nodes.Canopy_left_pipe_element_front.material}
              position={[0, lPositionCM, -4.04]}
              rotation={[Math.PI, 0, 0]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Canopy_left_pipe_element_middle_right.geometry}
              material={nodes.Canopy_left_pipe_element_middle_right.material}
              position={[0, hookOffset, -4.04]}
              rotation={[Math.PI, 0, 0]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Canopy_left_round.geometry}
              position={[0, -0.04, 0]}
              rotation={[0, 0, Math.PI]}
              scale={[1, lScaleC(1), 1]}
            >
              <CanopyMaterial/>
            </mesh>
          </group>
        )}

        <group position={[-0.0, 23.45, -3.84]} rotation={[0, 0, Math.PI / 2]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Canopy_left_pipe_element_middle_left.geometry}
            material={nodes.Canopy_left_pipe_element_middle_left.material}
            position={[0, hookOffset, -4.04]}
            rotation={[Math.PI, 0, 0]}
          >
            <HardwareMaterial/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Canopy_right_pipe_2.geometry}
            material={nodes.Canopy_right_pipe_2.material}
            scale={[1, lScaleC(1), 1]}
          >
            <HardwareMaterial/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Canopy_right_pipe_element_back.geometry}
            material={nodes.Canopy_right_pipe_element_back.material}
            position={[0, lPositionCM , -4.04]}
            rotation={[Math.PI, 0, 0]}
          >
            <HardwareMaterial/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Canopy_right_pipe_element_front.geometry}
            position={[0, -lPositionCM, -4.04]}
            rotation={[Math.PI, 0, 0]}
          >
            <HardwareMaterial/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Canopy_right_round.geometry}
            position={[0, -0.04, 0]}
            rotation={[0, 0, Math.PI]}
            scale={[1, lScaleC(1), 1]}
          >
            <CanopyMaterial/>
          </mesh>
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.CANOPY.geometry}
          position={[0, 22.35, -3.89]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[elementWidth / 0.48, lScaleC(1), 1]}
        >
          <CanopyMaterial/>
        </mesh>
      </group>
    </>
  );
};
