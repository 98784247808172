export enum StructureColor {
  CHARCOAL_GRAY = 'Charcoal Gray',
  HAMPTON_WHITE = 'White Snow',
  BRAZILIAN_IPE = 'Brazilian Ipe',
  TUSCAN_CEDAR = 'Tuscan Cedar'
}

export enum StructureColorHexNumbers {
  CHARCOAL_GRAY = '#343635',
  HAMPTON_WHITE = '#F2F2F2',
  BRAZILIAN_IPE = '#311006',
  TUSCAN_CEDAR = '#D7A25F'
}

export enum CanopyColor {
  CAPPUCCINO = 'Cappuccino',
  DOVE_WHITE = 'Dove White',
  STERLING_SILVER = 'Sterling Silver',
  DESERT_SAND = 'Desert Sand'
}

export enum CanopyColorHexNumbers {
  CAPPUCCINO = '#9A8B79',
  DOVE_WHITE = '#DEDFDF',
  STERLING_SILVER = '#6D6D64',
  DESERT_SAND = '#988266'
}
