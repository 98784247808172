import React, { FC, useCallback, useRef, useState } from 'react';
import { styled, keyframes } from '@stitches/react';
import { blackA } from '@radix-ui/colors';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import { usePergola } from '../../data-stores/pergola';
import { useMediaQuery } from '../../contexts/MediaQueryContext';

import { CollaborativeShareSwitch } from '../switches/CollaborativeShareSwitch';

import close from '../../assets/close.svg';
import copy from '../../assets/copy-clipboard.svg';
import check from '../../assets/checkmark.svg';
import linkChain from '../../assets/link-chain.svg';
import failedIcon from '../../assets/failed.svg';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' }
});

const StyledOverlay = styled(AlertDialogPrimitive.Overlay, {
  backgroundColor: blackA.blackA9,
  position: 'fixed',
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  }
});

const StyledContent = styled(AlertDialogPrimitive.Content, {
  backgroundColor: 'white',
  borderRadius: '10px',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  maxWidth: '769px',
  maxHeight: '85vh',
  padding: '1rem 0',
  overflow: 'auto',
  zIndex: 9999,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  },
  '&:focus': { outline: 'none' }
});

const AlertDialog = AlertDialogPrimitive.Root;
const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
const AlertDialogContent = StyledContent;
const AlertDialogCancel = AlertDialogPrimitive.Cancel;

export const ShareModal: FC = (): JSX.Element => {
  const mediaQueryContext = useMediaQuery();

  const url = window.location.href;

  const closeShareRef = useRef<any>(null);

  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const [copyFailed, setCopyFailed] = useState<boolean>(false);

  const closeSharePopup = () => {
    if (closeShareRef.current) {
      closeShareRef.current.click();
    }
  };

  const copyToClipBoard = useCallback(async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
    } catch (err) {
      setCopyFailed(true);
    }
  }, []);

  const clearCopy = () => {
    setCopyFailed(false);
    setCopySuccess(false);
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        {
          mediaQueryContext.onMobile
            ? (
              <div
                className="absolute"
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%'
                }}
              />
            )
            : (
              <div className="floating_button_container">
                <img
                  className="floating_button_image"
                  src={linkChain}
                  alt="share link"
                />
                <div className="floating_button_text text-text text-xs font-normal">Get sharable link</div>
              </div>
            )
        }
      </AlertDialogTrigger>
      <AlertDialogPrimitive.Portal>
        <StyledOverlay onClick={closeSharePopup}/>
        <AlertDialogContent>
          <div
            className="sticky top-0 bg-white items-center grid grid-cols-home-modal-header pb-3 lg:py-4 lg:px-4 custom_modal_title_border">
            <div/>

            <div className="justify-self-center font-bold lg:font-semibold text-sm lg:text-base text-text">
              Share your design
            </div>

            <AlertDialogCancel
              asChild
              onClick={clearCopy}
              ref={closeShareRef}
            >
              <img
                className="cursor-pointer mr-4 justify-self-end"
                src={close}
                alt="close canopy modal"
              />
            </AlertDialogCancel>
          </div>

          <div className="px-8 lg:px-16 pt-4 lg:pt-10 lg:pt-2 home_modal_container">
            <div className="mb-8">
              <div className="text-text text-sm font-medium mb-1 text-center">We got you covered!</div>
              <div className="text-extralight text-sm font-light text-center">
                Save your draft by copying to clipboard and pick it up from where you left
              </div>

              <div className="lg:grid grid-cols-clipboard-copy mt-6 lg:mt-0 mx-4 lg:mx-0">
                <div/>
                <div
                  className="clipboard_copy_container py-2 px-4 mt-4"
                  onClick={async () => {
                    await copyToClipBoard(url);
                  }}
                >
                  <img
                    src={copySuccess ? check : copyFailed ? failedIcon : copy}
                    alt="copy to clipboard icon"
                  />
                  <div
                    className={`text-base font-medium underline text-center ${copyFailed ? 'text-red' : 'text-green'}`}>
                    {
                      copySuccess
                        ? 'Copied to clipboard!'
                        : copyFailed
                          ? 'Copy to clipboard failed!'
                          : 'Copy draft to clipboard'
                    }
                  </div>
                </div>
              </div>
            </div>

            <CollaborativeShareSwitch/>
          </div>
        </AlertDialogContent>
      </AlertDialogPrimitive.Portal>
    </AlertDialog>
  );
};
