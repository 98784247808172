/**
 * Calculate Height Size Price
 *
 * @param {number} height The height in inches of the pergola
 * @param {number} posts The number of posts of the pergola
 * @param {number} heightSizePriceBelow94 The pricing configuration number for height below 94 inches
 * @param {number} heightSizePriceAbove94 The pricing configuration number for height above 94 inches
 * @return {number}
 */
export const calculateHeightSizePrice = (height: number, posts: number, heightSizePriceBelow94: number, heightSizePriceAbove94: number): number => {
  // D3 = Height
  // D9 = Posts

  // =(if(D3<=94,D3*3.95,D3*4.6))*D9
  if (height <= 94) {
    return (height * heightSizePriceBelow94) * posts;
  }

  // =(if(D3<=94,D3*3.95,D3*4.6))*D9
  return (height * heightSizePriceAbove94) * posts;
};
