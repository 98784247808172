export enum DimensionsMinMaxValues {
  HEIGHT_MIN = 94,
  HEIGHT_MAX = 118,
  LENGTH_MIN = 60,
  LENGTH_MAX = 720,
  WIDTH_MIN = 60,
  WIDTH_MAX = 480
}

export enum DimensionsDefaultValues {
  HEIGHT_FEET = 7,
  HEIGHT_INCHES = 8,
  LENGTH_FEET = 11,
  LENGTH_INCHES = 8,
  WIDTH_FEET = 11,
  WIDTH_INCHES = 8
}

const calculateMinOrMaxFeet = (number: DimensionsMinMaxValues): number =>
  Math.floor(number / 12);

const calculateMinOrMaxInches = (number: DimensionsMinMaxValues): number =>
  Math.round(number / 12 % 1 * 10);

export const dimensionsMinMaxValues = {
  height: {
    min: {
      feet: calculateMinOrMaxFeet(DimensionsMinMaxValues.HEIGHT_MIN),
      inches: calculateMinOrMaxInches(DimensionsMinMaxValues.HEIGHT_MIN)
    },
    max: {
      feet: calculateMinOrMaxFeet(DimensionsMinMaxValues.HEIGHT_MAX),
      inches: calculateMinOrMaxInches(DimensionsMinMaxValues.HEIGHT_MAX)
    }
  },
  length: {
    min: {
      feet: calculateMinOrMaxFeet(DimensionsMinMaxValues.LENGTH_MIN),
      inches: calculateMinOrMaxInches(DimensionsMinMaxValues.LENGTH_MIN)
    },
    max: {
      feet: calculateMinOrMaxFeet(DimensionsMinMaxValues.LENGTH_MAX),
      inches: calculateMinOrMaxInches(DimensionsMinMaxValues.LENGTH_MAX)
    }
  },
  width: {
    min: {
      feet: calculateMinOrMaxFeet(DimensionsMinMaxValues.WIDTH_MIN),
      inches: calculateMinOrMaxInches(DimensionsMinMaxValues.WIDTH_MIN)
    },
    max: {
      feet: calculateMinOrMaxFeet(DimensionsMinMaxValues.WIDTH_MAX),
      inches: calculateMinOrMaxInches(DimensionsMinMaxValues.WIDTH_MAX)
    }
  }
};
