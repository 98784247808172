/**
 * Calculate Length Size Price
 *
 * @param {number} length The length in inches of the pergola
 * @param {number} railings The number of railings of the pergola
 * @param {number} lengthSizePriceBelow120 The pricing configuration number for length below 120 inches
 * @param {number} lengthSizePriceBelow240 The pricing configuration number for length below 240 inches
 * @param {number} lengthSizePriceAbove240 The pricing configuration number for length above 240 inches
 * @return {number}
 */
export const calculateLengthSizePrice = (length: number, railings: number, lengthSizePriceBelow120: number, lengthSizePriceBelow240: number, lengthSizePriceAbove240: number): number => {
  // D4 = Length
  // D5 = Railings

  // =(if(D4<=120,D4*1.7,if(D4<=240,120*1.7+(D4-120)*1.5,120*1.7+120*1.5+(D4-240)*1.25)))*D5

  // (if(D4<=120,D4*1.7,
  if (length <= 120) {
    return (length * lengthSizePriceBelow120) * railings;
  }

  // if(D4<=240,120*1.7+(D4-120)*1.4,
  if (length <= 240) {
    return (120 * lengthSizePriceBelow120 + (length - 120) * lengthSizePriceBelow240) * railings;
  }

  // 120*1.7+120*1.4+(D4-240)*1.25)))*D5
  return (120 * lengthSizePriceBelow120 + (120 * lengthSizePriceBelow240) + (length - 240) * lengthSizePriceAbove240) * railings;
};
