import { feetToInchesRatio } from './constants';

/**
 * Calculate total inches given feet and inches
 *
 * @param {number} feet
 * @param {number} inches
 * @return {number}
 */
export const calculateTotalInches = (feet: number, inches: number): number =>
  feet * feetToInchesRatio + inches;

/**
 * Convert inches to centimeters
 *
 * @param {number} inches
 * @return {number}
 */
export const convertInchesToCentimeters = (inches: number): number =>
  inches * 2.54;
