// Weight in LB
export const partsWeight = {
  posts: {
    weightPerInch: 0.2759, // Weight by inch -> calculate whole weight / #1
    postCap: 0.264, // Weight by piece / #14
    base: 3.938, // Weight by piece / #15
    baseCap: 0.858 // Weight by piece / #17
  },
  beams: {
    centerAndEdges: {
      railProfile: 0.1019, // Weight by inch -> calculate whole weight / #3
      railProfileCap: 0.264, // Weight by piece / #12
      railProfileConnector: 1.914  // Weight by piece / #9
    },
    normal: {
      railProfile: 0.0879, // Weight by inch -> calculate whole weight / #5
      railProfileCap: 0.308, // Weight by piece / #13
      railProfileConnector: 2.794 // Weight by piece / #10
    },
    lower: {
      railProfile: 0.0994, // Weight by inch -> calculate whole weight / #7 -> missing -> 21 assumed
      railProfileConnector: 2.794 // Weight by piece / #10
    },
    wall: 0.0676 // Weight by inch / #51
  },
  uConnector: 0.418, // Weight by piece / #11
  tubes: {
    a: 0.0092,
    b: 0.0089
  },
  canopy: 0.00125, // Weight by squire inch -> calculate whole weight // 12 inches
  wheels: {
    normal: 0.0374, // 26
    a: 0.0462, // 26A
    b: 0.0352 // 26B or 16
  }
};