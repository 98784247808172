/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { Ref, useEffect, useMemo, useRef } from 'react';
import { Group, Mesh } from 'three';
import { GLTF } from 'three-stdlib';
import { useStore } from '@react-three/fiber';

import { usePergola } from '../data-stores/pergola';
import { useAr } from '../data-stores/ar';
import { heightScale, lengthScale, widthScale } from './utilities/scaling';
import { calculateTotalInches } from './utilities/converting-units';
import {
  heightInsideGroupPositionCentimeters,
  heightPositionMeters,
  lengthPositionMetersNegative,
  lengthPositionMetersPositive,
  widthInsideGroupPositionCentimetersNegative,
  widthInsideGroupPositionCentimetersPositive,
  widthPositionMetersNegative,
  widthPositionMetersPositive
} from './utilities/positioning';
import { Legs } from './elements/legs/Legs';
import { TopBeams } from './elements/beams/TopBeams';
import { EdgeBeams } from './elements/beams/EdgeBeams';
import { Canopies } from './elements/canopy/Canopies';
import { useGLTF } from '@react-three/drei';
import { TopBeamsAdditional } from './elements/beams/TopBeamsAdditional';
import { CanopyCover } from './elements/canopy/CanopyCover';
import { exportARUrl } from './exporters';
import { MeasurementsGroup } from './elements/measurements/MeasurementsGroup';

type GLTFResult = GLTF & {
  nodes: {
    Canopy_left_pipe_2: Mesh
    Canopy_left_pipe_element_back: Mesh
    Canopy_left_pipe_element_front: Mesh
    Canopy_left_pipe_element_middle_right: Mesh
    Canopy_left_round: Mesh
    Canopy_left_pipe_element_middle_left: Mesh
    Canopy_right_pipe_2: Mesh
    Canopy_right_pipe_element_back: Mesh
    Canopy_right_pipe_element_front: Mesh
    Canopy_right_round: Mesh
    CANOPY: Mesh
    Beam_body_3: Mesh
    beam_element_left_3: Mesh
    Beam_element_right_3: Mesh
    Beam_end_screws_left_3: Mesh
    Beam_end_screws_right_3: Mesh
    Beam_left_segment_3: Mesh
    Beam_right_segment_3: Mesh
    Beam_screws_middle_3: Mesh
    Beam_body_2: Mesh
    beam_element_left_2: Mesh
    Beam_element_right_2: Mesh
    Beam_end_screws_left_2: Mesh
    Beam_end_screws_right_2: Mesh
    Beam_left_segment_2: Mesh
    Beam_right_segment_2: Mesh
    Beam_screws_middle_2: Mesh
    Beam_body: Mesh
    beam_element_left: Mesh
    Beam_element_right: Mesh
    Beam_end_screws_left: Mesh
    Beam_end_screws_right: Mesh
    Beam_left_segment: Mesh
    Beam_right_segment: Mesh
    Beam_screws_middle: Mesh
    Lenght_beam_left_2: Mesh
    Lenght_beam_left_screws_middle: Mesh
    Lenght_beam_right_2: Mesh
    lenght_beam_right_screws_middle: Mesh
    Width_beam_back_2: Mesh
    Width_beam_back_screws_middle: Mesh
    Width_beam_front_2: Mesh
    Width_beam_front_screws_middle: Mesh
    Leg_back_right_2: Mesh
    Leg_back_right_lower_element: Mesh
    Leg_back_right_screws_bottom: Mesh
    Leg_back_right_screws_top: Mesh
    Leg_back_right_upper_element: Mesh
    Leg_back_left_2: Mesh
    Leg_back_left_lower_element: Mesh
    Leg_back_left_screws_bottom: Mesh
    Leg_back_left_screws_top: Mesh
    Leg_back_left_upper_element: Mesh
    Leg_front_left_2: Mesh
    Leg_front_left_lower_element: Mesh
    Leg_front_left_screws_bottom: Mesh
    Leg_front_left_screws_top: Mesh
    Leg_front_left_upper_element: Mesh
    Leg_front_right_2: Mesh
    Leg_front_right_lower_element: Mesh
    Leg_front_right_screws_bottom: Mesh
    Leg_front_right_screws_top: Mesh
    Leg_front_right_upper_element: Mesh
  }
  materials: {}
}

export default function Model({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef() as Ref<Group> | undefined;
  const { nodes } = useGLTF('/3d-models/pergola.glb') as GLTFResult;

  const store = useStore();
  const isAr = useAr(state => state.isAr);
  const setIsAr = useAr(state => state.setIsAr);
  const urlSearchParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const arURLParam = urlSearchParams.get('ar');

  useEffect(() => {
    if (!isAr && !arURLParam) {
      return;
    }

    (async () => {
      const state = store.getState();
      const url = await exportARUrl(state.scene);

      debugger;
      if (!url) {
        return;
      }

      const link = document.getElementById('ar-link');

      if (link) {
        link.setAttribute('href', url);
        link.click();
      }

      if (arURLParam) {
        urlSearchParams.delete('ar');
      }

      if (isAr) {
        setIsAr(false);
      }
    })();
  }, [arURLParam, isAr, setIsAr, store, urlSearchParams]);

  // Height
  const height = usePergola(s => s.dimensions.height);
  const totalHeightInches = calculateTotalInches(height.feet, height.inches);
  const hScale = heightScale(totalHeightInches);
  const hPositionM = heightPositionMeters(totalHeightInches);
  const hPositionCm = heightInsideGroupPositionCentimeters(totalHeightInches);

  // Width
  const width = usePergola(s => s.dimensions.width);
  const totalWidthInches = calculateTotalInches(width.feet, width.inches);
  const wScale = widthScale(totalWidthInches);
  const wPositionMP = widthPositionMetersPositive(totalWidthInches);
  const wPositionMN = widthPositionMetersNegative(totalWidthInches);
  const wPositionCMP = widthInsideGroupPositionCentimetersPositive(totalWidthInches);
  const wPositionCMN = widthInsideGroupPositionCentimetersNegative(totalWidthInches);

  const length = usePergola(s => s.dimensions.length);
  const totalLengthInches = calculateTotalInches(length.feet, length.inches);
  const lScale = lengthScale(totalLengthInches);
  const lPositionMP = lengthPositionMetersPositive(totalLengthInches);
  const lPositionMN = lengthPositionMetersNegative(totalLengthInches);

  const hasCanopy = usePergola(s => s.canopy.hasCanopy);
  const { showDimensionsLabels } = usePergola(s => s);


  return (
    <group name="pergola" ref={group} {...props} dispose={null} rotation={[0, Math.PI, 0]}>

      {showDimensionsLabels && (
        <MeasurementsGroup
          wPositionMP={wPositionMP}
          lPositionMP={lPositionMP}
          hPositionM={hPositionM}
        />)}

      <Legs
        lPositionMP={lPositionMP}
        wPositionMP={wPositionMP}
        hScale={hScale}
        wPositionMN={wPositionMN}
        hPositionCm={hPositionCm}
        lPositionMN={lPositionMN}
        nodes={nodes}
        totalWidthInches={totalWidthInches}
        totalLengthInches={totalLengthInches}
      />

      {/* Canopy */}
      {hasCanopy && (
        <>
          <Canopies
            hPositionM={hPositionM}
            totalWidthInches={totalWidthInches}
            totalLengthInches={totalLengthInches}
            nodes={nodes}
          />
          <CanopyCover
            hPositionM={hPositionM}
            wPositionMP={wPositionMP}
            totalWidthInches={totalWidthInches}
            totalLengthInches={totalLengthInches}
          />
        </>
      )}

      {/* Top Beams */}
      <TopBeams
        hPositionM={hPositionM}
        wScale={wScale}
        wPositionCMN={wPositionCMN}
        wPositionCMP={wPositionCMP}
        totalWidthInches={totalWidthInches}
        totalLengthInches={totalLengthInches}
        nodes={nodes}
      />

      <TopBeamsAdditional
        wScale={wScale}
        hPositionM={hPositionM}
        wPositionCMN={wPositionCMN}
        wPositionCMP={wPositionCMP}
        totalWidthInches={totalWidthInches}
        totalLengthInches={totalLengthInches}
        nodes={nodes}
      />

      {/* Edge Beams */}
      <EdgeBeams
        hPositionM={hPositionM}
        wPositionMP={wPositionMP}
        lPositionMP={lPositionMP}
        lPositionMN={lPositionMN}
        wScale={wScale}
        lScale={lScale}
        wPositionCMN={wPositionCMN}
        wPositionMN={wPositionMN}
        wPositionCMP={wPositionCMP}
        totalWidthInches={totalWidthInches}
        totalLengthInches={totalLengthInches}
        nodes={nodes}
      />

    </group>
  );
}

useGLTF.preload('/3d-models/pergola.glb');
