import create from 'zustand';

export interface ErrorsStore {
  dimensionsError: boolean;
  setDimensionsError: (value: boolean) => void;
  beamsError: boolean;
  setBeamsError: (value: boolean) => void;
}

export const useErrors = create<ErrorsStore>(set => ({
  dimensionsError: false,
  setDimensionsError: (value: boolean) => set((state: ErrorsStore) => ({
    ...state,
    dimensionsError: value
  })),
  beamsError: false,
  setBeamsError: (value: boolean) => set((state: ErrorsStore) => ({
    ...state,
    beamsError: value
  }))
}));
