import React from 'react';
import * as SwitchPrimitive from '@radix-ui/react-switch';

import { usePergola } from '../../data-stores/pergola';

export const CollaborativeShareSwitch = () => {
  const collaborative = usePergola(state => state.collaborative);
  const toggleCollaborative = usePergola(state => state.toggleCollaborative);

  return (
    <div className="flex justify-center items-center">

      <div className="flex items-center">
        <div className="text-extralight text-sm font-light mr-4">Viewers can edit:</div>

        <SwitchPrimitive.Root
          className="canopy_switch_root"
          id="s1"
          checked={collaborative}
          onClick={toggleCollaborative}
        >
          <SwitchPrimitive.Thumb className="canopy_switch_thumb"/>
        </SwitchPrimitive.Root>
      </div>
    </div>
  );
};
