import { gql } from '@apollo/client';
import client from '../apollo-client';

const query = gql`
    query getProductByHandle($handle: String!){
        product(handle: $handle) {
            id
            title
            handle
            variants(first: 1) {
                edges {
                    node {
                        id
                    }
                }
            }
        }
    }
`;

export const getProductByHandle = async (handle: string) => {
  const { data } = await client.query({
    query,
    variables: {
      handle
    }
  });

  // const products = data.products;

  return {
    data
  };
};

export const createCheckout = async (variantId: string) => {
  console.log('variantId: ', variantId);

  const { data, errors } = await client.mutate({
    mutation: gql`
        mutation CreateCheckout($input: CheckoutCreateInput!){
            checkoutCreate(input: $input) {
                checkout {
                    id
                    webUrl
                    lineItems(first: 5) {
                        edges {
                            node {
                                title
                                quantity
                            }
                        }
                    }
                }
                checkoutUserErrors {
                    code
                    field
                    message
                }
            }
        }
    `, variables: {
      input: {
        lineItems: [{ variantId: variantId, quantity: 1 }],
        buyerIdentity: {
          countryCode: 'US'
        }
      }
    }
  });

  // const products = data.products;
  console.error('Error: ', errors);

  return {
    data
  };
};
