export const createConfigurationWithBuildID = async (ownerId: string) => {
  const res = await fetch('https://create-configuration-svzpqwmgna-uc.a.run.app', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ownerID: ownerId
    })
  });

  return res.json();
};
