import React, { FC, useMemo } from 'react';

import { usePergola } from '../../data-stores/pergola';

import { getInternalHeight, getInternalLength, getInternalWidth } from '../../internal-dimensions-calculations';

import {
  CanopyColor,
  StructureColor
} from '../../configurations/colors';

import { ShippingBox } from '../icons/ShippingBox';
import hamptonWhite from '../../assets/hampton-white.png';
import charcoalGray from '../../assets/charcoal-gray.png';
import brazilianIpe from '../../assets/brazilian-ipe.png';
import tuscanCedar from '../../assets/tuscan-cedar.png';
import doveWhite from '../../assets/dove-white.png';
import cappuccino from '../../assets/cappuccino.png';
import sterlingSilver from '../../assets/sterling-silver.png';
import desertSand from '../../assets/desert-sand.png';

const Summary: FC = (): JSX.Element => {
  const state = usePergola(state => state);
  const structureColor = usePergola(state => state.design.color);
  const canopyColor = usePergola(state => state.canopy.color);
  const buildNumber = usePergola(state => state.buildNumber);

  const internalDimensions = useMemo(() => {
    return {
      height: {
        ...getInternalHeight(state.dimensions.height.feet, state.dimensions.height.inches)
      },
      length: {
        ...getInternalLength(state.dimensions.length.feet, state.dimensions.length.inches)
      },
      width: {
        ...getInternalWidth(state.dimensions.width.feet, state.dimensions.width.inches)
      }
    };
  }, [state.dimensions.height.feet, state.dimensions.height.inches, state.dimensions.length.feet, state.dimensions.length.inches, state.dimensions.width.feet, state.dimensions.width.inches]);

  return (
    <div className="cart_modal_summary px-8">
      <div className="flex justify-between items-center summary_border py-3">
        <div className="text-text text-xs font-light">Build Number:</div>
        <div>
          {buildNumber}
        </div>
      </div>

      <div className="flex justify-between items-center summary_border py-3">
        <div className="text-text text-xs font-light">Structure Color:</div>
        <div
          style={{
            width: 27,
            height: 27,
            borderRadius: '50%',
            marginLeft: '1rem'
          }}
        >
          {structureColor === StructureColor.HAMPTON_WHITE && (
            <img className="rounded-full" src={hamptonWhite} alt="hampton white color"/>
          )}
          {structureColor === StructureColor.CHARCOAL_GRAY && (
            <img className="rounded-full" src={charcoalGray} alt="charcoal gray color"/>
          )}
          {structureColor === StructureColor.BRAZILIAN_IPE && (
            <img className="rounded-full" src={brazilianIpe} alt="brazilian ipe color"/>
          )}
          {structureColor === StructureColor.TUSCAN_CEDAR && (
            <img className="rounded-full" src={tuscanCedar} alt="tuscan cedar color"/>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center summary_border py-3">
        <div className="text-text text-xs font-light">Canopy Color:</div>
        {
          !state.canopy.hasCanopy
            ? (
              <div className="text-text text-sm font-medium">-</div>
            )
            : (
              <div
                style={{
                  width: 27,
                  height: 27,
                  borderRadius: '50%',
                  marginLeft: '1rem'
                }}
              >
                {canopyColor === CanopyColor.DOVE_WHITE && (
                  <img className="rounded-full" src={doveWhite} alt="dove white color"/>
                )}
                {canopyColor === CanopyColor.CAPPUCCINO && (
                  <img className="rounded-full" src={cappuccino} alt="cappuccino color"/>
                )}
                {canopyColor === CanopyColor.STERLING_SILVER && (
                  <img className="rounded-full" src={sterlingSilver} alt="sterling silver color"/>
                )}
                {canopyColor === CanopyColor.DESERT_SAND && (
                  <img className="rounded-full" src={desertSand} alt="desert sand color"/>
                )}
              </div>
            )
        }
      </div>

      <div className="flex justify-between items-center summary_border py-3">
        <div className="text-text text-xs font-light">External Dimensions:</div>
        <div className="text-text text-xs font-medium">
          {state.dimensions.height.feet}′{state.dimensions.height.inches !== 0 && `${state.dimensions.height.inches}′′`}{' '}H{' '}
          <span>x</span> {state.dimensions.length.feet}′{state.dimensions.length.inches !== 0 && `${state.dimensions.length.inches}′′`}{' '}D{' '}
          <span>x</span> {state.dimensions.width.feet}′{state.dimensions.width.inches !== 0 && `${state.dimensions.width.inches}′′`}{' '}W{' '}
        </div>
      </div>

      <div className="flex justify-between items-center summary_border py-3">
        <div className="text-text text-xs font-light">Internal Dimensions:</div>
        <div className="text-text text-xs font-medium">
          {internalDimensions.height.feet}′{internalDimensions.height.inches !== 0 && `${internalDimensions.height.inches}′′`}{' '}H{' '}
          <span>x</span> {internalDimensions.length.feet}′{internalDimensions.length.inches !== 0 && `${internalDimensions.length.inches}′′`}{' '}L{' '}
          <span>x</span> {internalDimensions.width.feet}′{internalDimensions.width.inches !== 0 && `${internalDimensions.width.inches}′′`}{' '}W{' '}
        </div>
      </div>

      <div className="flex justify-between items-center summary_border py-3">
        <div className="text-text text-xs font-light">Beam Quantity:</div>
        <div className="text-text text-sm font-medium">
          {state.beams}
        </div>
      </div>

      <div className="flex justify-between items-center summary_border py-3">
        <div className="text-text text-xs font-light">Approx. Net Weight:</div>
        <div className="text-text text-sm font-medium">
          {state.weight} lb
        </div>
      </div>

      <div className="flex items-center pt-2 pb-3">
        <ShippingBox/>
        <div className="text-text text-xs font-light text-green ml-2">Expected Shipping:</div>
        <div className="text-text text-xs font-medium text-green ml-2">
          {
            structureColor === StructureColor.HAMPTON_WHITE
              ? '90 Days'
              : '5 Weeks'
          }
        </div>
      </div>
    </div>
  );
};

export default Summary;
