/**
 * Calculate Canopy Price
 *
 * @param {boolean} hasCanopy Does the pergola has a canopy attached
 * @param {number} length The length in inches of the pergola
 * @param {number} width The width in inches of the pergola
 * @param {number} canopyMultiplier The canopy multiplier number
 * @return {number}
 */
export const calculateCanopyPrice = (hasCanopy: boolean, length: number, width: number, canopyMultiplier: number): number => {
  // D4 = Length
  // D6 = Width
  // D10 = hasCanopy

  // =if(D10=true,4.1*((D4/12)*(D6/12)),0)
  if (!hasCanopy) {
    return 0;
  }

  return Number((canopyMultiplier * (length / 12) * (width / 12)).toFixed(3));
};
