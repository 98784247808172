import { PergolaDesign } from '../data-stores/pergola';

import { partsWeight } from './config';

/**
 * Calculate posts weight
 *
 * @param {number} heightFeet The Height feet of the pergola
 * @param {number} heightInches The Height inches of the pergola
 * @param {number} lengthFeet The Length inches of the pergola
 * @param {number} lengthInches The Length inches of the pergola
 * @param {number} widthFeet The Width inches of the pergola
 * @param {number} widthInches The Width inches of the pergola
 * @param {PergolaDesign} structureType The Pergola structure type
 *
 * @return {number} The total weight of all the posts of the Pergola
 */

export const getPostsWeight = (
  heightFeet: number,
  heightInches: number,
  lengthFeet: number,
  lengthInches: number,
  widthFeet: number,
  widthInches: number,
  structureType: PergolaDesign
): number => {
  // Convert post height to inches
  const postInches: number = (heightFeet * 12) + heightInches;
  const pergolaLength: number = (lengthFeet * 12) + lengthInches;
  const pergolaWidth: number = (widthFeet * 12) + widthInches;

  // The weight of a single post
  const postWeight: number = (postInches * partsWeight.posts.weightPerInch) + partsWeight.posts.postCap + partsWeight.posts.base + partsWeight.posts.baseCap;

  // Initial number of posts / legs of the pergola
  let numberOfPosts: number = structureType === PergolaDesign.FREE_STANDING ? 4 : 2;

  // Calculate additional posts
  if (pergolaLength > 240 && pergolaLength <= 480) {
    numberOfPosts += structureType === PergolaDesign.FREE_STANDING ? 2 : 1;
  }

  if (pergolaLength > 480) {
    numberOfPosts += structureType === PergolaDesign.FREE_STANDING ? 4 : 2;
  }

  if (pergolaWidth > 240 && pergolaLength <= 240) {
    numberOfPosts += 2;
  }

  if (pergolaWidth > 240 && pergolaLength > 240 && pergolaLength <= 480) {
    numberOfPosts += 3;
  }

  if (pergolaWidth > 240 && pergolaLength > 480) {
    numberOfPosts += 4;
  }

  // Return total weight of posts
  return postWeight * numberOfPosts;
};