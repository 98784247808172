import React, { FC, useEffect, useMemo, useState } from 'react';

import { CanvasContainer } from '../3d/CanvasContainer';
import { Loader } from '../shared/Loader';
import ar from '../../assets/ar.svg';

export const ARLayout: FC = (): JSX.Element => {
  const [buttonAnimation, setButtonAnimation] = useState<boolean>(false);

  const renderCanvas = useMemo(() => <CanvasContainer/>, []);

  useEffect(() => {
    if (buttonAnimation) {
      setTimeout(() => {
        setButtonAnimation(false)
      }, 2000)
    }
  } , [buttonAnimation])

  return (
    <div className="mobile_layout_container w-full grid lg:hidden">
      <div className='h-screen'>
        <Loader/>
      </div>

      <div className="invisible">
        <a id="ar-link" rel="ar" href="#" download="pergola.usdz">
          <img
            className="floating_button_image"
            src={ar}
            width={19}
            height={19}
            alt="view in ar"
          />
        </a>

        {renderCanvas}
      </div>
    </div>
  );
};
