/**
 * Calculate Railings
 *
 * @param {number} posts The number of posts for the pergola
 * @return {number}
 */
export const calculateRailings = (posts: number): number => {
  if (posts === 4) return 2;
  // if(D9=6,4,
  if (posts === 6) return 4;
  // if(D9=8,6,
  if (posts === 8) return 6;
  // if(D9=9,6,
  if (posts === 9) return 6;
  // if(D9=12,9,"ERROR"))))))
  return 9;
};