import { convertInchesToCentimeters } from './converting-units';

/**
 * Calculate position
 *
 * @param {number} totalInches
 * @param {number} offsetInches
 * @return {number}
 */
const calculatePositionInCentimeters = (totalInches: number, offsetInches: number = 0): number => {
  const inchPosition = totalInches - offsetInches;
  return convertInchesToCentimeters(inchPosition);
}

/**
 * Calculate half position
 *
 * @param {number} totalInches
 * @param {number} offsetInches
 * @return {number}
 */
const calculateHalfPositionInCentimeters = (totalInches: number, offsetInches: number): number => {
  const halfInches = totalInches / 2;
  const inchPosition = halfInches - offsetInches;
  return convertInchesToCentimeters(inchPosition);
}

/**
 * Calculate height position in centimeters
 *
 * @param {number} totalHeightInches
 * @return {number}
 */
export const heightPositionCentimeters = (totalHeightInches: number): number => {
  return calculatePositionInCentimeters(totalHeightInches, 0);
}

/**
 * Calculate height position in meters
 *
 * @param {number} totalHeightInches
 * @return {number}
 */
export const heightPositionMeters = (totalHeightInches: number): number => {
  return heightPositionCentimeters(totalHeightInches) / 100;
}

/**
 * Calculate width positions in centimeters
 *
 * @param {number} totalWidthInches
 * @return {number}
 */
export const widthPositionCentimeters = (totalWidthInches: number): number => {
  return calculateHalfPositionInCentimeters(totalWidthInches, 11);
}

/**
 * Calculate width positions in meters
 *
 * @param {number} totalWidthInches
 * @return {number}
 */
export const widthPositionMeters = (totalWidthInches: number): number => {
  return widthPositionCentimeters(totalWidthInches) / 100;
}

/**
 * Calculate length position in centimeters
 *
 * @param {number} totalLengthInches
 * @return {number}
 */
export const lengthPositionCentimeters = (totalLengthInches: number): number => {
  return calculateHalfPositionInCentimeters(totalLengthInches, 2.6);
}

/**
 * Calculate length position in meters
 *
 * @param {number} totalLengthInches
 * @return {number}
 */
export const lengthPositionMeters = (totalLengthInches: number): number => {
  return lengthPositionCentimeters(totalLengthInches) / 100;
}
