import { DocumentSnapshot } from 'firebase/firestore';

import { PricingConfiguration } from '../types/pricing-configuration';

export const transformPricingConfiguration = (document: DocumentSnapshot): PricingConfiguration | null => {
  if (!document.exists) {
    return null;
  }

  const data = document.data({ serverTimestamps: 'estimate' });

  if (!data) {
    return null;
  }

  return {
    ...data as PricingConfiguration,
    updatedAt: data.updatedAt.toDate()
  };
};