/**
 * Calculate U Connectors
 *
 * @param {number} width The width in inches of the pergola
 * @param {number} beams The number of beams attached to the pergola
 * @param {number} uConnectorsMultiplier The U Connectors multiplier number
 * @return {number}
 */
export const calculateUConnectorsPrice = (width: number, beams: number, uConnectorsMultiplier: number): number => {
  // D6 = Width
  // D7 = Beams
  // D8 = U Connectors Count

  // if(D6<=240,(D7-2)*2,(D7-2)*3)
  const connectorsCount = width <= 240 ? (beams - 2) * 2 : (beams - 2) * 3;

  // =D8*4.75*2
  return connectorsCount * uConnectorsMultiplier * 2;
};