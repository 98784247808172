// Measurement units conversion ratios
export const inchesToCentimetersRatio = 2.54;
export const feetToInchesRatio = 12;

export const defaultLengthInches = 141.8;
export const defaultWidthInches = 142;
export const defaultHeightInches = 94.2;

// Default size of beams
export const defaultWidthBeamInches = 142;
export const defaultBeamBracketWidth = 6.731;

// Default size of legs
export const defaultLegHeight = 94.125;
export const defaultLegWidth = 10.085;

// Default size of canopy
export const defaultLengthCanopyInches = 136;

// Minimum distance between beams
export const minDistanceBetweenBeamsInches = 6.06299 + 2;
export const maxDistanceBetweenBeamsInches = 48;
