import React, { FC } from 'react';
import { CanopyElement } from './CanopyElement';
import { convertInchesToCentimeters } from '../../utilities/converting-units';

interface CanopyProps {
  hPositionM: (x: number) => number;
  totalWidthInches: number;
  totalLengthInches: number;
  widthPositionOffset: number;
  lengthPositionOffset: number;
  middleHookOffset?: number;
  nodes: any; // TODO add type
}

export const Canopy: FC<CanopyProps> = ({
  hPositionM,
  totalWidthInches,
  totalLengthInches,
  widthPositionOffset,
  lengthPositionOffset,
  middleHookOffset,
  nodes
}) => {
  const availableWidth = convertInchesToCentimeters(totalWidthInches - 15.3053 * 2) / 100;
  const canopyElements = Math.floor(availableWidth / 0.48) + 1;
  const canopyElementWidth = availableWidth / canopyElements;
  const canopyOffsets = Array(canopyElements)
    .fill(canopyElementWidth)
    .map((value, index) => value * index);

  return (
    <>
      {canopyOffsets.map((offset, index) => (
        <CanopyElement
          key={`canopy-element-offset-${offset}`}
          hPositionM={hPositionM}
          totalWidthInches={totalWidthInches}
          totalLengthInches={totalLengthInches}
          widthPositionOffset={offset + widthPositionOffset}
          lengthPositionOffset={lengthPositionOffset}
          elementWidth={canopyElementWidth}
          isLastElement={canopyOffsets.length - 1 === index}
          middleHookOffset={middleHookOffset}
          nodes={nodes}
        />
      ))}
  </>);
};
