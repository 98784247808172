import React, { FC, useMemo } from 'react';

import { usePergola } from '../../data-stores/pergola';
import { useErrors } from '../../data-stores/errors';

import { getInternalHeight, getInternalLength, getInternalWidth } from '../../internal-dimensions-calculations';

import { CanopyColor, StructureColor } from '../../configurations/colors';

import cappuccino from '../../assets/cappuccino.png';
import desertSand from '../../assets/desert-sand.png';
import doveWhite from '../../assets/dove-white.png';
import sterlingSilver from '../../assets/sterling-silver.png';
import tuscanCedar from '../../assets/tuscan-cedar.png';
import brazilianIpe from '../../assets/brazilian-ipe.png';
import charcoalGray from '../../assets/charcoal-gray.png';
import hamptonWhite from '../../assets/hampton-white.png';

export const PergolaSummary: FC = (): JSX.Element => {
  const state = usePergola(state => state);
  const structureColor = usePergola(state => state.design.color);
  const heightFeet = usePergola(state => state.dimensions.height.feet);
  const heightInches = usePergola(state => state.dimensions.height.inches);
  const lengthFeet = usePergola(state => state.dimensions.length.feet);
  const lengthInches = usePergola(state => state.dimensions.length.inches);
  const widthFeet = usePergola(state => state.dimensions.width.feet);
  const widthInches = usePergola(state => state.dimensions.width.inches);
  const hasCanopy = usePergola(state => state.canopy.hasCanopy);
  const canopyColor = usePergola(state => state.canopy.color);
  const beams = usePergola(state => state.beams);
  const errors = useErrors(state => state);

  const internalDimensions = useMemo(() => {
    return {
      height: {
        ...getInternalHeight(state.dimensions.height.feet, state.dimensions.height.inches)
      },
      length: {
        ...getInternalLength(state.dimensions.length.feet, state.dimensions.length.inches)
      },
      width: {
        ...getInternalWidth(state.dimensions.width.feet, state.dimensions.width.inches)
      }
    };
  }, [state.dimensions.height.feet, state.dimensions.height.inches, state.dimensions.length.feet, state.dimensions.length.inches, state.dimensions.width.feet, state.dimensions.width.inches]);

  return (
    <div className="pergola_summary_grid h-full items-center bg-white">
      <div className="pergola_summary_structure">
        <div className="flex items-center">
          <div className="pergola_summary_text ">
            Structure Color:
          </div>

          <div
            style={{
              width: 27,
              height: 27,
              borderRadius: '50%',
              marginLeft: '1rem'
            }}
          >
            {structureColor === StructureColor.HAMPTON_WHITE && (
              <img className="rounded-full swatchBorder" src={hamptonWhite} alt="hampton white color"/>
            )}
            {structureColor === StructureColor.CHARCOAL_GRAY && (
              <img className="rounded-full" src={charcoalGray} alt="charcoal gray color"/>
            )}
            {structureColor === StructureColor.BRAZILIAN_IPE && (
              <img className="rounded-full" src={brazilianIpe} alt="brazilian ipe color"/>
            )}
            {structureColor === StructureColor.TUSCAN_CEDAR && (
              <img className="rounded-full" src={tuscanCedar} alt="tuscan cedar color"/>
            )}
          </div>
        </div>

        <div className="flex items-center mt-2">
          <div className="pergola_summary_text ">
            Canopy Color:
          </div>

          {
            !hasCanopy
              ? (
                <div className="text-text text-xs font-medium ml-8">-</div>
              )
              : (
                <div
                  style={{
                    width: 27,
                    height: 27,
                    borderRadius: '50%',
                    marginLeft: '1.5rem'
                  }}
                >
                  {canopyColor === CanopyColor.DOVE_WHITE && (
                    <img className="rounded-full" src={doveWhite} alt="dove white color"/>
                  )}
                  {canopyColor === CanopyColor.CAPPUCCINO && (
                    <img className="rounded-full" src={cappuccino} alt="cappuccino color"/>
                  )}
                  {canopyColor === CanopyColor.STERLING_SILVER && (
                    <img className="rounded-full" src={sterlingSilver} alt="sterling silver color"/>
                  )}
                  {canopyColor === CanopyColor.DESERT_SAND && (
                    <img className="rounded-full" src={desertSand} alt="desert sand color"/>
                  )}
                </div>
              )
          }
        </div>
      </div>

      <div className="pergola_summary_dimensions justify-self-center"
      >
        <div>
          <div className="flex items-center custom_dimensions_flex">
            <div className="pergola_summary_text">
              External Dimensions:
            </div>

            <div className={`pergola_summary_dimensions_text ${errors.dimensionsError && 'hidden'}`}>
              {heightFeet}′{heightInches !== 0 && ` ${heightInches}′′`}{' '}H{' '}
              <span>x</span> {lengthFeet}′{lengthInches !== 0 && ` ${lengthInches}′′`}{' '}L{' '}
              <span>x</span> {widthFeet}′{widthInches !== 0 && ` ${widthInches}′′`}{' '}W{' '}
            </div>
          </div>

          <div className="flex items-center custom_dimensions_flex mt-1">
            <div className="pergola_summary_text">
              Internal Dimensions:
            </div>
            <div className={`pergola_summary_dimensions_text ${errors.dimensionsError && 'hidden'}`}>
              {internalDimensions.height.feet}′{internalDimensions.height.inches !== 0 && `${internalDimensions.height.inches}′′`}{' '}H{' '}
              <span>x</span> {internalDimensions.length.feet}′{internalDimensions.length.inches !== 0 && `${internalDimensions.length.inches}′′`}{' '}L{' '}
              <span>x</span> {internalDimensions.width.feet}′{internalDimensions.width.inches !== 0 && `${internalDimensions.width.inches}′′`}{' '}W{' '}
            </div>
          </div>
        </div>
      </div>

      <div
        className="pergola_summary_beams justify-self-end">
        <div>
          <div className="beams_and_weight_custom_flex">
            <div className="pergola_summary_text">
              Beam Quantity:
            </div>

            <div className={`beams_summary_text text-text font-bold text-base flex ${errors.beamsError && 'hidden'}`}>
              {beams}
            </div>
          </div>

          <div className="beams_and_weight_custom_flex mt-2">
            <div className="pergola_summary_text">
              Approx. Net Weight:
            </div>
            <div className="beams_summary_text text-text font-bold text-base flex">
              {state.weight} lb
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
