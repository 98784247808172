import { PergolaDesign } from '../data-stores/pergola';

/**
 * Calculate Pergola Structure price
 *
 * @param {StructureColor} structureType The structure type of the pergola
 * @param {number} heightPrice The height total price
 * @param {number} attachedHeightPriceMultiplier The attached height price multiplier
 * @param {number} attachedMultiplier The attached multiplier
 * @return {number}
 */

export const calculateStructureTypePrice = (
  structureType: PergolaDesign,
  heightPrice: number,
  attachedHeightPriceMultiplier: number,
  attachedMultiplier: number
): number => {
  // D2 = Free standing true / false
  // G3 = Height Total Price

  //=if(D2=true,0,(G3*0.33)*(-1))
  if (structureType === PergolaDesign.FREE_STANDING) {
    return 0;
  }

  return (heightPrice * attachedHeightPriceMultiplier) * attachedMultiplier;
};