import React, { FC } from 'react';
import { usePergola } from '../../data-stores/pergola';
import { useLoader } from '@react-three/fiber';
import { RepeatWrapping, Texture, TextureLoader } from 'three';
import { StructureColor, StructureColorHexNumbers } from '../../configurations/colors';

interface StructureMaterialProps {
  vertical?: boolean
}

export const StructureMaterial: FC<StructureMaterialProps> = ({ vertical }) => {
  const structureColor = usePergola(s => s.design.color);

  const texturesBI = !!vertical
    ? [
      `/3d-models/textures/wood/brazilian-ipe-legs/WOOD_light_diffuse.jpg`,
      `/3d-models/textures/wood/brazilian-ipe-legs/WOOD_light_normal.jpg`,
      `/3d-models/textures/wood/brazilian-ipe-legs/WOOD_light_roughness.jpg`,
    ]
    : [

      `/3d-models/textures/wood/brazilian-ipe-beams/WOOD_light_diffuse.jpg`,
      `/3d-models/textures/wood/brazilian-ipe-beams/WOOD_light_normal.jpg`,
      `/3d-models/textures/wood/brazilian-ipe-beams/WOOD_light_roughness.jpg`,
    ]

  const brazilianIpeTexture = useLoader(TextureLoader, texturesBI);

  const texturesBCC = !!vertical
    ? [
      `/3d-models/textures/wood/cedar-legs/wood-cedar-darker.jpg`,
      `/3d-models/textures/wood/cedar-legs/wood_2_normal.jpg`,
      `/3d-models/textures/wood/cedar-legs/wood_2_roughness.jpg`,
    ]
    : [
      `/3d-models/textures/wood/cedar-beams/wood-cedar-darker.jpg`,
      `/3d-models/textures/wood/cedar-beams/wood_2_normal.jpg`,
      `/3d-models/textures/wood/cedar-beams/wood_2_roughness.jpg`,
    ]

  const tuscanCedarTexture = useLoader(TextureLoader, texturesBCC);

  let textures: Texture[] = [];
  let color: StructureColorHexNumbers = StructureColorHexNumbers.HAMPTON_WHITE;

  switch (structureColor) {
    case StructureColor.HAMPTON_WHITE:
      color = StructureColorHexNumbers.HAMPTON_WHITE;
      break;
    case StructureColor.CHARCOAL_GRAY:
      color = StructureColorHexNumbers.CHARCOAL_GRAY;
      break;
    case StructureColor.BRAZILIAN_IPE:
      textures = brazilianIpeTexture;
      break;
    case StructureColor.TUSCAN_CEDAR:
      textures = tuscanCedarTexture;
      break;
  }

  if (!textures.length) {
    return (
      <meshStandardMaterial
        key={`color-${structureColor}`}
        roughness={0.8}
        metalness={0}
        color={color}
      />
    );
  }

  for (const texture of textures) {
    if (texture) {
      texture.wrapS = RepeatWrapping;
      texture.wrapT = RepeatWrapping;
      texture.repeat.set(1, 10);

      if (vertical) {
        texture.repeat.set(10, 1);
      }
    }
  }

  const [colorMap, normalMap, roughnessMap] = textures;

  return (
    <meshStandardMaterial
      key={`texture-${structureColor}-${!!vertical}`}
      map={colorMap}
      normalMap={normalMap}
      roughnessMap={roughnessMap}
    />
  );
}
