import React, { FC, useCallback, useEffect, useMemo } from 'react';

import { usePergola } from '../../../data-stores/pergola';

import { useBeamsConfiguration } from '../../../data-stores/beams-configuration';

import { convertInchesToCentimeters } from '../../utilities/converting-units';

import { Beam } from './Beam';

import {
  defaultLegWidth,
  maxDistanceBetweenBeamsInches,
  minDistanceBetweenBeamsInches
} from '../../utilities/constants';
import { beamsMatrix } from '../../../configurations/beams-matrix';

interface TopBeamsAdditionalProps {
  hPositionM: (x: number) => number;
  wScale: (x: number) => number;
  wPositionCMN: (x: number) => number;
  wPositionCMP: (x: number) => number;
  totalWidthInches: number;
  totalLengthInches: number;
  nodes: any; // TODO add type
}

export const TopBeamsAdditional: FC<TopBeamsAdditionalProps> = ({
  hPositionM,
  wScale,
  wPositionCMN,
  wPositionCMP,
  totalWidthInches,
  totalLengthInches,
  nodes
}): JSX.Element => {
  const lengthFeet = usePergola(state => state.dimensions.length.feet);
  const widthFeet = usePergola(state => state.dimensions.width.feet);
  const beams = usePergola(state => state.beams);
  const setBeamsConfiguration = useBeamsConfiguration(state => state.setBeamsConfig);

  const parts = useMemo(() => {
    if (totalLengthInches <= 240) {
      return 2;
    }

    if (totalLengthInches > 240 && totalLengthInches <= 480) {
      return 4;
    }

    return 6;
  }, [totalLengthInches]);

  const partWidth = useMemo(() =>
    (totalLengthInches - defaultLegWidth / 2) / parts, [totalLengthInches, parts]);

  const minimumBeamsInPart = useMemo(() =>
    Math.ceil(partWidth / maxDistanceBetweenBeamsInches), [partWidth]);

  const maximumBeamsInPart = useMemo(() =>
    Math.ceil(partWidth / minDistanceBetweenBeamsInches), [partWidth]);

  const beamsInPart = useMemo(() => {
    const beamsInPart = Math.floor(beams / parts);

    if (beamsInPart < minimumBeamsInPart) {
      return minimumBeamsInPart;
    }

    if (beamsInPart > maximumBeamsInPart) {
      return maximumBeamsInPart;
    }

    return beamsInPart;
  }, [beams, maximumBeamsInPart, minimumBeamsInPart, parts]);

  useEffect(() => {
    const matrixKey = `${widthFeet}_${lengthFeet}`;
    let minBeams = minimumBeamsInPart * parts + 1;
    let maxBeams = beamsMatrix[matrixKey];
    const calculatedMaxBeams = Math.floor((maximumBeamsInPart * parts - 1) / parts) * parts + 1;

    maxBeams -= maxBeams % parts;
    maxBeams++;

    if (maxBeams > calculatedMaxBeams) {
      maxBeams = calculatedMaxBeams;
    }

    if (minBeams > maxBeams) {
      minBeams = maxBeams;
    }

    setBeamsConfiguration({
      minBeams,
      maxBeams,
      beamsStep: parts,
      distanceBetweenBeams: Math.round(partWidth / beamsInPart)
    });
  }, [beamsInPart, lengthFeet, maximumBeamsInPart, minimumBeamsInPart, partWidth, parts, setBeamsConfiguration, widthFeet]);

  const getPositionsForPart: (part: number) => number[] = useCallback((part = 0) => {
    const beamStep = partWidth / beamsInPart;
    const beamStepInM = convertInchesToCentimeters(beamStep) / 100;
    const offset = part * convertInchesToCentimeters(partWidth) / 100;

    return Array(beamsInPart)
      .fill(beamStepInM)
      .map((beamStep, i) => {
        if (i === 0) {
          return beamStep + offset;
        }

        return beamStep * (i + 1) + offset;
      });
  }, [beamsInPart, partWidth]);

  const positions = useMemo(() => {
    let positions: number[] = [];

    for (let i = 0; i < parts / 2; i++) {
      positions = [
        ...positions,
        ...getPositionsForPart(i)
      ];
    }

    return positions;
  }, [getPositionsForPart, parts]);


  const positiveBeams = useMemo(() =>
    positions.map((position, index) =>
      (<Beam
        key={`key-${position}`}
        wScale={wScale}
        hPositionM={hPositionM}
        wPositionCMN={wPositionCMN}
        wPositionCMP={wPositionCMP}
        nodes={nodes}
        totalWidthInches={totalWidthInches}
        position={position}
        isLast={index === positions.length - 1}
      />)
    ), [hPositionM, nodes, positions, totalWidthInches, wPositionCMN, wPositionCMP, wScale]);

  const negativeBeams = useMemo(() =>
    positions.map((position, index) =>
      (<Beam
        key={`key-negative-${position}`}
        wScale={wScale}
        hPositionM={hPositionM}
        wPositionCMN={wPositionCMN}
        wPositionCMP={wPositionCMP}
        nodes={nodes}
        totalWidthInches={totalWidthInches}
        position={-position}
        isLast={index === positions.length - 1}
      />)
    ), [hPositionM, nodes, positions, totalWidthInches, wPositionCMN, wPositionCMP, wScale]);

  return (
    <>
      {positiveBeams}

      {totalLengthInches > 480 && (
        <Beam
          key={`middle-beam`}
          wScale={wScale}
          hPositionM={hPositionM}
          wPositionCMN={wPositionCMN}
          wPositionCMP={wPositionCMP}
          nodes={nodes}
          totalWidthInches={totalWidthInches}
          position={0}
        />
      )}

      {negativeBeams}
    </>
  );
};
