import { OrbitControls, Stage } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React, { FC, Suspense, useRef } from 'react';

import Pergola from '../../3d-models/Pergola';
import { Loader } from '../shared/Loader';
import { useErrors } from '../../data-stores/errors';

interface CanvasContainerProps {
}

export const CanvasContainer: FC<CanvasContainerProps> = () => {
  const ref = useRef<any>();
  const beamsError = useErrors(s => s.beamsError);
  const dimensionsError = useErrors(s => s.dimensionsError);

  if (beamsError || dimensionsError) {
    return (<></>);
  }

  return (
    <Suspense fallback={<Loader inCanvas={true}/>}>
      <Canvas
        shadows={true}
        dpr={[1, 2]}
        camera={{ fov: 50 }}
        frameloop="demand"
      >
        <Stage
          controls={ref}
          preset="portrait"
          intensity={1}
          ambience={1}
          environment={null}
          castShadow={true}
        >
          <Pergola/>
        </Stage>
        <OrbitControls
          ref={ref}
          enablePan={true}
          autoRotate={false}
          minPolarAngle={0.0}
          maxPolarAngle={2}
          minDistance={0.5}
          maxDistance={20}
        />
      </Canvas>
    </Suspense>
  );
};
