import React, { FC, useRef } from 'react';
import QRCode from 'react-qr-code';
import { styled, keyframes } from '@stitches/react';
import { blackA } from '@radix-ui/colors';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import close from '../../assets/close.svg';
import ar from '../../assets/ar.svg';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' }
});

const StyledOverlay = styled(AlertDialogPrimitive.Overlay, {
  backgroundColor: blackA.blackA9,
  position: 'fixed',
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  }
});

const StyledContent = styled(AlertDialogPrimitive.Content, {
  backgroundColor: 'white',
  borderRadius: '10px',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  maxWidth: '847px',
  maxHeight: '85vh',
  padding: '1rem 0',
  overflow: 'auto',
  zIndex: 9999,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  },
  '&:focus': { outline: 'none' }
});

const AlertDialog = AlertDialogPrimitive.Root;
const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
const AlertDialogContent = StyledContent;
const AlertDialogCancel = AlertDialogPrimitive.Cancel;

export const ArModal: FC = (): JSX.Element => {
  const closeArRef = useRef<any>(null);

  const closeArPopup = () => {
    if (closeArRef.current) {
      closeArRef.current.click();
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <div id="ar-modal-trigger" className="floating_button_container ar">
          <img
            className="floating_button_image"
            src={ar}
            alt="view in ar"
          />
          <div className="hidden lg:block floating_button_text ar text-text text-xs font-normal">View in AR</div>
        </div>
      </AlertDialogTrigger>
      <AlertDialogPrimitive.Portal>
        <StyledOverlay onClick={closeArPopup}/>
        <AlertDialogContent>
          <div
            className="grid grid-cols-home-modal-header pt-2 pb-4 lg:px-4">
            <div/>

            <div className="text-text text-xl font-semibold justify-self-center mt-4">
              Scan QR code to view in your <span className="text-green font-extrabold">Outdoor</span>
            </div>

            <AlertDialogCancel
              asChild
              ref={closeArRef}
            >
              <img
                className="cursor-pointer mr-4 mt-2 justify-self-end"
                src={close}
                alt="close canopy modal"
              />
            </AlertDialogCancel>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 justify-center pl-8 pt-8 pb-3">
            <ol className="list-decimal mt-8 pl-10 pr-4">
              <li className="text-text font-normal text-lg mb-4">Open camera app on your phone</li>
              <li className="text-text font-normal text-lg">Point your phone to this screen to capture the code</li>
            </ol>

            <div className="justify-self-center">
              <QRCode value={`${window.location.href}&ar=true`}/>
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialogPrimitive.Portal>
    </AlertDialog>
  );
};
