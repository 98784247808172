import React, { FC, useMemo } from 'react';
import { HardwareMaterial } from '../../materials/HardwareMaterial';
import { StructureMaterial } from '../../materials/StructureMaterial';
import { widthBeamScale } from '../../utilities/scaling';
import { PergolaDesign, usePergola } from '../../../data-stores/pergola';

interface TopBeamsProps {
  hPositionM: (x: number) => number;
  wScale: (x: number) => number;
  wPositionCMN: (x: number) => number;
  wPositionCMP: (x: number) => number;
  totalWidthInches: number;
  totalLengthInches: number;
  nodes: any; // TODO add type
}

export const TopBeams: FC<TopBeamsProps> = ({
  hPositionM,
  wPositionCMN,
  wPositionCMP,
  totalWidthInches,
  totalLengthInches,
  nodes
}): JSX.Element => {
  const pergolaDesign = usePergola(s => s.design.pergola);
  const isAttached = pergolaDesign === PergolaDesign.ATTACHED;

  const wScale = useMemo(() => {
    if (isAttached) {
      return widthBeamScale(totalWidthInches - 10);
    }

    return widthBeamScale(totalWidthInches);
  }, [isAttached, totalWidthInches]);

  return (
    <>
      {/* Middle */}
      <group position={[0, hPositionM(2.317), 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Beam_body.geometry}
          material={nodes.Beam_body.material}
          rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
          scale={[wScale(100), 100, 100]}
          position={[0, isAttached ? -13 : 0, 0]}
        >

          <StructureMaterial/>

        </mesh>
        {totalLengthInches <= 240 && (
          <>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.beam_element_left.geometry}
              material={nodes.beam_element_left.material}
              position={[-8.55, wPositionCMN(152.4), 7.5]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Beam_element_right.geometry}
              material={nodes.Beam_element_right.material}
              position={[0, wPositionCMP(-152.4), -0.17]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Beam_end_screws_left.geometry}
              material={nodes.Beam_end_screws_left.material}
              position={[5.55, wPositionCMN(152.4), 8.4]}
              rotation={[0, 1.57, 0]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Beam_end_screws_right.geometry}
              material={nodes.Beam_end_screws_right.material}
              position={[-0.03, wPositionCMP(-152.46), -0.35]}
              rotation={[0, 1.57, 0]}
            >
              <HardwareMaterial/>
            </mesh>
          </>
        )}
        {totalLengthInches <= 240 && totalWidthInches > 240 && (
          <>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Beam_element_right.geometry}
              material={nodes.Beam_element_right.material}
              position={[0, 0, -0.17]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Beam_end_screws_left.geometry}
              material={nodes.Beam_end_screws_left.material}
              position={[5.55, 0, 8.4]}
              rotation={[0, 1.57, 0]}
            >
              <HardwareMaterial/>
            </mesh>
          </>
        )}

        {isAttached && (
          <>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Beam_element_right.geometry}
              material={nodes.Beam_element_right.material}
              position={[0, wPositionCMP(-152.4), -0.17]}
            >
              <HardwareMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Beam_end_screws_right.geometry}
              material={nodes.Beam_end_screws_right.material}
              position={[-0.03, wPositionCMP(-152.46), -0.35]}
              rotation={[0, 1.57, 0]}
            >
              <HardwareMaterial/>
            </mesh>
          </>
        )}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Beam_screws_middle.geometry}
          material={nodes.Beam_screws_middle.material}
          position={[0, -26.88, 0]}
        >
          <HardwareMaterial/>
        </mesh>
      </group>
    </>
  );
};
