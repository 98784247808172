import React, { FC, useMemo } from 'react';
import { HardwareMaterial } from '../../materials/HardwareMaterial';
import { StructureMaterial } from '../../materials/StructureMaterial';
import { PergolaDesign, usePergola } from '../../../data-stores/pergola';

interface EdgeBeamsProps {
  hPositionM: (x: number) => number;
  wPositionMP: (x: number) => number;
  lPositionMP: (x: number) => number;
  lPositionMN: (x: number) => number;
  wScale: (x: number) => number;
  lScale: (x: number) => number;
  wPositionCMN: (x: number) => number;
  wPositionMN: (x: number) => number;
  wPositionCMP: (x: number) => number;
  totalWidthInches: number;
  totalLengthInches: number;
  nodes: any; // TODO add type
}

export const EdgeBeams: FC<EdgeBeamsProps> = ({
  hPositionM,
  wPositionMP,
  lScale,
  wPositionMN,
  totalWidthInches,
  totalLengthInches,
  nodes
}): JSX.Element => {
  const pergolaDesign = usePergola(s => s.design.pergola);
  const isAttached = pergolaDesign === PergolaDesign.ATTACHED;
  const additionalLength = useMemo(() => {
    if (!isAttached) {
      return 0;
    }

    if (totalLengthInches > 600) {
      return 4.5;
    }

    if (totalLengthInches > 480) {
      return 4;
    }

    if (totalLengthInches > 240) {
      return 3.5;
    }

    return 2.5;
  }, [isAttached, totalLengthInches]);

  return (
    <>
      <group position={[0.2, hPositionM(2.18), wPositionMP(-1.54) - 0.0125]} rotation={[Math.PI / 2, 0, 0]}
             scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Lenght_beam_left_2.geometry}
          material={nodes.Lenght_beam_left_2.material}
          position={[isAttached ? -20.5 : -21, 1.25, 1.25]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[lScale(100) + additionalLength, 100, 100]}
        >
          <StructureMaterial/>
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Lenght_beam_left_screws_middle.geometry}
          material={nodes.Lenght_beam_left_screws_middle.material}
          position={[9, 1.25, 1.25]}
          rotation={[-Math.PI, -0.39, -Math.PI / 2]}
        >
          <HardwareMaterial/>
        </mesh>
      </group>
      <group position={[-0.1, hPositionM(2.18), wPositionMN(1.54) + 0.0125]} rotation={[Math.PI / 2, 0, 0]}
             scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Lenght_beam_right_2.geometry}
          material={nodes.Lenght_beam_right_2.material}
          position={[8.67, -1.24, 1.27]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[lScale(100), 100, 100]}
        >
          <StructureMaterial/>
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lenght_beam_right_screws_middle.geometry}
          material={nodes.lenght_beam_right_screws_middle.material}
          position={[-8.67, 1.24, -1.27]}
          rotation={[0, 0, -Math.PI / 2]}
        >
          <HardwareMaterial/>
        </mesh>
      </group>

      {totalWidthInches > 240 && (
        <>
          <group position={[-0.1, hPositionM(2.18), 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Lenght_beam_right_2.geometry}
              material={nodes.Lenght_beam_right_2.material}
              position={[8.67, -1.24, 1.27]}
              rotation={[-Math.PI / 2, 0, 0]}
              scale={[lScale(100), 100, 100]}
            >
              <StructureMaterial/>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.lenght_beam_right_screws_middle.geometry}
              material={nodes.lenght_beam_right_screws_middle.material}
              position={[-8.67, 1.24, -1.27]}
              rotation={[0, 0, -Math.PI / 2]}
            >
              <HardwareMaterial/>
            </mesh>
          </group>
        </>
      )}
    </>
  );
};
