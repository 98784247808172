import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useResizeDetector } from 'react-resize-detector';

import { useUser } from './data-stores/user';
import { useErrors } from './data-stores/errors';
import { usePergola } from './data-stores/pergola';
import { usePricingConfigurations } from './data-stores/pricing-configuration';

import {
  getConfiguration,
  liveConfiguration,
  updateConfiguration
} from './firebase/firestore/configurations';

import { getPostsWeight } from './weight-calculations/calculate-posts';
import { getBeamsWeight } from './weight-calculations/calculate-beams';
import { getCanopyWeight } from './weight-calculations/calculate-canopy';
import { calculateColorsPriceDiscount } from './price-calculations/calculate-color-discounts';
import { calculateTotalPrice } from './price-calculations/calculate-total-price';
import { calculateCanopyPrice } from './price-calculations/calculate-canopy-price';
import { getPricingConfiguration } from './firebase/firestore/pricing-configuration';

import { DesktopLayout } from './components/layouts/DesktopLayout';
import { MobileLayout } from './components/layouts/MobileLayout';
import { Loader } from './components/shared/Loader';
import { ARLayout } from './components/layouts/ARLayout';

import { PergolaConfigurationUpdateData } from './firebase/firestore/types/configuration';

import { reportEvent } from './utilities/gtag/report-event';
import { createConfigurationWithBuildID } from './configurations/create-configuration';
import { useNavigate, useParams } from 'react-router-dom';
import { signInAnonymously } from 'firebase/auth';
import { auth } from './firebase';

const App: FC = (): JSX.Element => {
  const { user, setUser } = useUser(state => state);
  const navigate = useNavigate();
  const { buildNumber } = useParams();
  const dimensionsError = useErrors(state => state.dimensionsError);
  const beamsError = useErrors(state => state.beamsError);

  const updatePergolaPrice = usePergola(state => state.updatePergolaPrice);
  const updateDiscountPrice = usePergola(state => state.updateDiscountPrice);
  const updatePergolaWeight = usePergola(state => state.updatePergolaWeight);
  const updateCanopyPrice = usePergola(state => state.updateCanopyPrice);
  const updatePergolaFromFirebase = usePergola(state => state.updatePergolaFromFirebase);
  const updateConfigurationID = usePergola(state => state.updateConfigurationID);

  const structureDesign = usePergola(state => state.design.pergola);
  const structureColor = usePergola(state => state.design.color);
  const heightFeet = usePergola(state => state.dimensions.height.feet);
  const heightInches = usePergola(state => state.dimensions.height.inches);
  const lengthFeet = usePergola(state => state.dimensions.length.feet);
  const lengthInches = usePergola(state => state.dimensions.length.inches);
  const widthFeet = usePergola(state => state.dimensions.width.feet);
  const widthInches = usePergola(state => state.dimensions.width.inches);
  const hasCanopy = usePergola(state => state.canopy.hasCanopy);
  const canopyColor = usePergola(state => state.canopy.color);
  const beams = usePergola(state => state.beams);
  const price = usePergola(state => state.price);
  const discount = usePergola(state => state.discount);
  const collaborative = usePergola(state => state.collaborative);
  const readyToUpdate = usePergola(state => state.readyToUpdate);
  const configurationID = usePergola(state => state.configurationID);

  const pricingConfiguration = usePricingConfigurations(state => state);
  const updatePricingConfiguration = usePricingConfigurations(state => state.setPricingConfiguration);
  const updateBuildNumber = usePergola(state => state.updatePergolaBuildNumber);

  const [gtagEventIsSubmitted, setGtagEventIsSubmitted] = useState<boolean>(false);

  // Check if app is initialized and ready to update configuration (prevents race conditions where you lose data when you update configuration before it's initialized)
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const onResize = useCallback(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  const { ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 1000,
    onResize
  });

  useEffect(() => {
    if (user) {
      return;
    }

    (async () => {
      try {
        const user = await signInAnonymously(auth);
        setUser(user);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [setUser, user]);

  useEffect(() => {
    if (configurationID) {
      return;
    }

    if (!buildNumber) {
      return;
    }

    updateConfigurationID(buildNumber);
    updateBuildNumber(buildNumber);
  }, [buildNumber, configurationID, updateBuildNumber, updateConfigurationID]);

  // Fetch pricing configuration
  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      try {
        const config = await getPricingConfiguration();

        if (config) {
          updatePricingConfiguration(config);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [updatePricingConfiguration, user]);

  // Check if there is a present configuration
  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      const firebaseConfiguration = await getConfiguration(configurationID);

      if (!firebaseConfiguration) {
        return;
      }

      // If the configuration is NOT collaborative
      if (!firebaseConfiguration.collaborative) {
        const { buildNumber } = await createConfigurationWithBuildID(user.user.uid);
        const newConfigurationID = buildNumber;

        updateConfigurationID(newConfigurationID);
        updateBuildNumber(buildNumber);

        updatePergolaFromFirebase({
          design: {
            ...firebaseConfiguration.design
          },
          dimensions: {
            ...firebaseConfiguration.dimensions
          },
          canopy: {
            ...firebaseConfiguration.canopy
          },
          beams: firebaseConfiguration.beams
        });

        await updateConfiguration(newConfigurationID, {
          design: {
            ...firebaseConfiguration.design
          },
          dimensions: {
            ...firebaseConfiguration.dimensions
          },
          canopy: {
            ...firebaseConfiguration.canopy
          },
          beams: firebaseConfiguration.beams
        });

        navigate(`/${newConfigurationID}`);

        setIsInitialized(true);
      } else {
        updateConfigurationID(configurationID);

        updatePergolaFromFirebase({
          design: {
            ...firebaseConfiguration.design
          },
          dimensions: {
            ...firebaseConfiguration.dimensions
          },
          canopy: {
            ...firebaseConfiguration.canopy
          },
          beams: firebaseConfiguration.beams
        });

        setIsInitialized(true);

        return liveConfiguration(configurationID, (data) => {
          if (data.updatedBy === user.user.uid) {
            return;
          }

          updatePergolaFromFirebase(data);
        });
      }
    })();
  }, [configurationID, navigate, setIsInitialized, updateBuildNumber, updateConfigurationID, updatePergolaFromFirebase, user]);

  const updateConfigurationCallback = useDebouncedCallback(async (configurationID: string, readyToUpdate: boolean, data: PergolaConfigurationUpdateData) => {
    if (!configurationID || !readyToUpdate || !user || !isInitialized) {
      return;
    }

    await updateConfiguration(configurationID, data);
  }, 500);

  // Sync configuration
  useEffect(() => {
    if (!configurationID || !readyToUpdate || !user || dimensionsError || beamsError || !isInitialized) {
      return;
    }

    (async () => {
      await updateConfigurationCallback(
        configurationID,
        readyToUpdate,
        {
          design: {
            pergola: structureDesign,
            color: structureColor
          },
          dimensions: {
            height: {
              feet: heightFeet,
              inches: heightInches
            },
            length: {
              feet: lengthFeet,
              inches: lengthInches
            },
            width: {
              feet: widthFeet,
              inches: widthInches
            }
          },
          canopy: {
            hasCanopy,
            color: canopyColor
          },
          beams,
          collaborative,
          updatedBy: user.user.uid
        }
      );
    })();
  }, [isInitialized, beams, beamsError, canopyColor, collaborative, configurationID, dimensionsError, hasCanopy, heightFeet, heightInches, lengthFeet, lengthInches, readyToUpdate, structureColor, structureDesign, updateCanopyPrice, updateConfigurationCallback, updatePergolaPrice, user, widthFeet, widthInches]);

  // Calculate total price and update store
  useEffect(() => {
    const totalPrice = calculateTotalPrice({
      structureDesign,
      structureColor,
      height: {
        feet: heightFeet,
        inches: heightInches
      },
      length: {
        feet: lengthFeet,
        inches: lengthInches
      },
      width: {
        feet: widthFeet,
        inches: widthInches
      },
      hasCanopy: hasCanopy as boolean,
      canopyColor: canopyColor,
      beams,
      pricingConfiguration
    });

    const length: number = lengthFeet * 12 + lengthInches;
    const width: number = widthFeet * 12 + widthInches;
    const canopyPrice = calculateCanopyPrice(true, length, width, pricingConfiguration.canopyMultiplier);

    const discountedPrice = calculateColorsPriceDiscount(
      hasCanopy,
      structureColor,
      canopyColor,
      totalPrice,
      canopyPrice,
      pricingConfiguration.structureCharcoalGrayDiscountPercentage,
      pricingConfiguration.structureWhiteSnowDiscountPercentage,
      pricingConfiguration.structureBrazilianIpeDiscountPercentage,
      pricingConfiguration.structureTuscanCedarDiscountPercentage,
      pricingConfiguration.canopyCappuccinoDiscountPercentage,
      pricingConfiguration.canopyDoveWhiteDiscountPercentage,
      pricingConfiguration.canopySterlingSilverDiscountPercentage,
      pricingConfiguration.canopyDesertSandDiscountPercentage
    );

    updateCanopyPrice(canopyPrice);
    updatePergolaPrice(totalPrice);
    updateDiscountPrice(discountedPrice);

    // GTAG Event Report
    if (!gtagEventIsSubmitted && price !== 0) {
      reportEvent({
        event: 'VIEW',
        design: structureDesign,
        price,
        discount
      });

      setGtagEventIsSubmitted(true);
    }
  }, [beams, canopyColor, discount, gtagEventIsSubmitted, hasCanopy, heightFeet, heightInches, lengthFeet, lengthInches, price, pricingConfiguration, structureColor, structureDesign, updateCanopyPrice, updateDiscountPrice, updatePergolaPrice, widthFeet, widthInches]);

  // Calculate total weight and update store
  useEffect(() => {
    const postsWeight = getPostsWeight(heightFeet, heightInches, lengthFeet, lengthInches, widthFeet, widthInches, structureDesign);
    const beamsWeight = getBeamsWeight(lengthFeet, lengthInches, widthFeet, widthInches, beams, structureDesign);
    const canopyWeight = getCanopyWeight(lengthFeet, lengthInches, widthFeet, widthInches, hasCanopy);

    const totalWeight = Math.round(postsWeight + beamsWeight + canopyWeight);

    updatePergolaWeight(totalWeight);
  }, [beams, hasCanopy, heightFeet, heightInches, lengthFeet, lengthInches, pricingConfiguration, structureColor, structureDesign, updateCanopyPrice, updatePergolaPrice, updatePergolaWeight, widthFeet, widthInches]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const ar = urlSearchParams.get('ar');

  if (!user) {
    return (
      <Loader/>
    );
  }

  if (ar) {
    return (
      <div>
        <ARLayout/>
      </div>
    );
  }

  return (
    <div ref={ref}>
      <MobileLayout/>
      <DesktopLayout/>
    </div>
  );
};

export default App;
