import React, { FC, useMemo } from 'react';
import { widthBeamScale } from '../../utilities/scaling';
import { StructureMaterial } from '../../materials/StructureMaterial';
import { HardwareMaterial } from '../../materials/HardwareMaterial';
import { PergolaDesign, usePergola } from '../../../data-stores/pergola';

interface BeamProps {
  hPositionM: (x: number) => number;
  wScale: (x: number) => number;
  wPositionCMN: (x: number) => number;
  wPositionCMP: (x: number) => number;
  nodes: any; // TODO add type
  totalWidthInches: number;
  position: number;
  isLast?: boolean;
}

export const Beam: FC<BeamProps> = ({
  hPositionM,
  wPositionCMN,
  wPositionCMP,
  nodes,
  totalWidthInches,
  position,
  isLast
}): JSX.Element => {
  const pergolaDesign = usePergola(s => s.design.pergola)
  const isAttached = pergolaDesign === PergolaDesign.ATTACHED;

  const wScale = useMemo(() => {
    if (isAttached) {
      return widthBeamScale(totalWidthInches - 10);
    }

    return widthBeamScale(totalWidthInches);
  }, [isAttached, totalWidthInches]);

  return (
    <group
      key={`${position}`}
      position={[position, hPositionM(2.317), 0]}
      rotation={[Math.PI / 2, 0, 0]}
      scale={0.01}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Beam_body_3.geometry}
        material={nodes.Beam_body_3.material}
        rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
        scale={[wScale(100), 100, 100]}
        position={[0, isAttached ? -13 : 0, 0]}
      >
        <StructureMaterial/>
      </mesh>
      {!isLast && (
        <>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.beam_element_left_3.geometry}
            material={nodes.beam_element_left_3.material}
            position={[-8.55, wPositionCMN(152.4), 7.5]}
          >
            <HardwareMaterial/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Beam_end_screws_left_3.geometry}
            material={nodes.Beam_end_screws_left_3.material}
            position={[5.55, wPositionCMN(152.4), 8.4]}
            rotation={[0, 1.57, 0]}
          >
            <HardwareMaterial/>
          </mesh>
        </>
      )}

      {!isLast && totalWidthInches > 240 && (
        <>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Beam_element_right_3.geometry}
            material={nodes.Beam_element_right_3.material}
            position={[0, 0, -0.17]}
          >
            <HardwareMaterial/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Beam_end_screws_right_3.geometry}
            material={nodes.Beam_end_screws_right_3.material}
            position={[-0.03, 0, -0.35]}
            rotation={[0, 1.57, 0]}
          >
            <HardwareMaterial/>
          </mesh>
        </>
      )}

      {(!isLast || isAttached) && (
        <>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Beam_element_right_3.geometry}
            material={nodes.Beam_element_right_3.material}
            position={[0, wPositionCMP(-152.4), -0.17]}
          >
            <HardwareMaterial/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Beam_end_screws_right_3.geometry}
            material={nodes.Beam_end_screws_right_3.material}
            position={[-0.03, wPositionCMP(-152.46), -0.35]}
            rotation={[0, 1.57, 0]}
          >
            <HardwareMaterial/>
          </mesh>
        </>
      )}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Beam_screws_middle_3.geometry}
        material={nodes.Beam_screws_middle_3.material}
        position={[0, -26.88, 0]}
      >
        <HardwareMaterial/>
      </mesh>
    </group>
  )
};
