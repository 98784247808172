import React, { FC, useMemo } from 'react';

import { CanvasContainer } from '../3d/CanvasContainer';

import { usePricingConfigurations } from '../../data-stores/pricing-configuration';
import { usePergola } from '../../data-stores/pergola';

import { TabsComponent } from '../tabs/Tabs';
import { HomePopup } from '../modals/HomeModal';
import { PergolaSummary } from '../shared/PergolaSummary';
import FloatingButtons from '../shared/FloatingButtons';
import { NoticeBox } from '../shared/NoticeBox';

import { StructureColor } from '../../configurations/colors';
import packageJSON from '../../../package.json';

export const DesktopLayout: FC = (): JSX.Element => {
  const renderCanvas = useMemo(() => <CanvasContainer/>, []);

  const structureColor = usePergola(state => state.design.color);
  const announcementBarMessage = usePricingConfigurations(state => state.announcementBarMessage);

  return (
    <div
      className={`transition-colors duration-1000 ${structureColor === StructureColor.HAMPTON_WHITE ? 'bg-gray' : ''} desktop_container justify-center items-center hidden lg:flex`}>
      <div className="absolute w-full flex justify-center items-center bg-text top-0" style={{ height: '34px' }}>
        <div className="text-xs text-white font-normal">
          {announcementBarMessage}
        </div>
      </div>

      <div className="main_grid_layout h-screen w-screen">
        <div className="header">
          <div className="grid grid-cols-header h-10 pt-2">
            <div>
              <HomePopup/>
            </div>

            <div className="justify-self-center pt-4">
              <p className="text-xl text-text font-semibold relative">
                Build
                <span className="font-extrabold custom_border"> your own </span>
                pergola
              </p>
            </div>
          </div>
        </div>

        <div className="canvas flex justify-center items-end">
          {renderCanvas}
          <NoticeBox/>
        </div>

        <FloatingButtons/>

        <div className="controls">
          <TabsComponent/>
        </div>

        <div className="summary mr-1 lg:mr-8">
          <PergolaSummary/>
        </div>

        <div className="app_version text-xs font-light text-text">
          v.{packageJSON.version}
        </div>
      </div>
    </div>
  );
};
