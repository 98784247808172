import { StructureColor } from '../configurations/colors';

/**
 * Calculate Wooden color price
 *
 * @param {StructureColor} structureColor The color of the pergola
 * @param {number} structurePrice The structure total price
 * @param {number} heightPrice The height total price
 * @param {number} lengthPrice The length total price
 * @param {number} beamsPrice The beams total price
 * @param {number} woodenColorPricePercentMultiplier The wooden color percent multiplier
 * @return {number}
 */

export const calculateWoodenColorPrice = (
  structureColor: StructureColor,
  structurePrice: number,
  heightPrice: number,
  lengthPrice: number,
  beamsPrice: number,
  woodenColorPricePercentMultiplier: number
): number => {
  // D11 = Wood imitation
  // G2 = Structure Type Total price
  // G3 = Height Total Price
  // G4 = Length Total Price
  // G7 = Beams Total Price

  // =if(D11=true,((G2+G3+G4+G7)*0.05),0)

  if (structureColor === StructureColor.CHARCOAL_GRAY || structureColor === StructureColor.HAMPTON_WHITE) {
    return -((structurePrice + heightPrice + lengthPrice + beamsPrice) * woodenColorPricePercentMultiplier);
  }

  return 0;
};
