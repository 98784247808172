import { SVGProps } from 'react';

export const Cross = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 23}
    height={props.height || 23}
    fill="none"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
      d="M368 368 144 144m224 0L144 368"
    />
  </svg>
);
