import { doc, getDoc } from 'firebase/firestore';

import { firestore } from '../index';
import { Collections } from './constants/collections';

import { transformPricingConfiguration } from './helpers/pricing-configuration-transformer';

import { PricingConfiguration } from './types/pricing-configuration';

export const getPricingConfiguration = async (): Promise<PricingConfiguration | null> => {
  const querySnapshot = await getDoc(
    doc(firestore, Collections.PRICING, 'configuration')
  );

  if (!querySnapshot.exists) {
    return null;
  }

  return transformPricingConfiguration(querySnapshot);
};