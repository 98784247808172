import { functions } from '../index';
import { httpsCallable } from 'firebase/functions';

interface CustomPergolaData {
  design: {
    pergola: string;
    color: string;
  };
  dimensions: {
    height: {
      feet: number;
      inches: number;
    };
    length: {
      feet: number;
      inches: number;
    };
    width: {
      feet: number;
      inches: number;
    };
  };
  canopy: {
    hasCanopy: boolean;
    color: string;
  };
  beams: number;
  price: number;
  buildNumber: string;
  isPingCall: boolean;
}

export const createCustomPergola = (data: CustomPergolaData): Promise<any> => // TODO
  httpsCallable(functions, 'createCustomPergola')(data);
