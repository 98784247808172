import {
  defaultHeightInches,
  inchesToCentimetersRatio
} from './constants';


/**
 Height
 */

export const heightPositionMeters = (totalHeightInches: number) =>
  (x: number, totalInches: number = totalHeightInches): number =>
    x - (defaultHeightInches - totalInches) * inchesToCentimetersRatio / 100;

export const heightInsideGroupPositionCentimeters = (totalHeightInches: number) =>
  (x: number, totalInches: number = totalHeightInches): number =>
    x + (defaultHeightInches - totalInches) * inchesToCentimetersRatio;


/**
  Width
 */

export const widthPositionMetersPositive = (totalWidthInches: number) =>
  (x: number, totalInches: number = totalWidthInches): number => {
    const inchPosition = totalInches / 2 - 11;

    return inchPosition * inchesToCentimetersRatio / 100;
    // return x + (defaultWidthInches - totalInches) / 2 * inchesToCentimetersRatio / 100;
  }

export const widthPositionMetersNegative = (totalWidthInches: number) =>
  (x: number, totalInches: number = totalWidthInches): number => {
    const inchPosition = totalInches / 2 - 11;

    return -(inchPosition * inchesToCentimetersRatio / 100);
    // return x - (defaultWidthInches - totalInches) / 2 * inchesToCentimetersRatio / 100;
  }

/**
 * Calculate width positions in centimeters
 *
 * @param {number} totalWidthInches
 * @return {number}
 */
export const widthPositionCentimeters = (totalWidthInches: number): number => {
  const inchPosition = totalWidthInches / 2 - 11;
  return inchPosition * inchesToCentimetersRatio;
}

/**
 * Calculate width positions in meters
 *
 * @param {number} totalWidthInches
 * @return {number}
 */
export const widthPositionMeters = (totalWidthInches: number): number => {
  const inchPosition = totalWidthInches / 2 - 11;
  return inchPosition * inchesToCentimetersRatio / 100;
}

export const widthInsideGroupPositionCentimetersPositive = (totalWidthInches: number) =>
  (x: number, totalInches: number = totalWidthInches): number => {
    const inchPosition = totalInches / 2 - 11;

    return (inchPosition * inchesToCentimetersRatio);
  }
    // x + (defaultWidthInches - totalInches) / 2 * inchesToCentimetersRatio;

export const widthInsideGroupPositionCentimetersNegative = (totalWidthInches: number) =>
  (x: number, totalInches: number = totalWidthInches): number => {
    const inchPosition = totalInches / 2 - 11;

    return -(inchPosition * inchesToCentimetersRatio);
  }
    // x - (defaultWidthInches - totalInches) / 2 * inchesToCentimetersRatio;


/**
 Length
 */

export const lengthPositionMetersPositive = (totalLengthInches: number) =>
  (x: number, totalInches: number = totalLengthInches): number => {
    const inchPosition = totalInches / 2 - 2.6;

    return (inchPosition * inchesToCentimetersRatio) / 100;
  }
    // x + (defaultLengthInches - totalInches) / 2 * inchesToCentimetersRatio / 100;

export const lengthPositionMetersNegative = (totalLengthInches: number) =>
  (x: number, totalInches: number = totalLengthInches): number => {
    const inchPosition = totalInches / 2 - 2.6;

    return -(inchPosition * inchesToCentimetersRatio) / 100;
  }
    // x - (defaultLengthInches - totalInches) / 2 * inchesToCentimetersRatio / 100;

export const lengthInsideGroupPositionCentimetersPositive = (totalLengthInches: number) =>
  (x: number, totalInches: number = totalLengthInches): number => {
    const inchPosition = totalInches / 2 - 2.6;

    return (inchPosition * inchesToCentimetersRatio);
  }
    // x + (defaultLengthInches - totalInches) / 2 * inchesToCentimetersRatio;

export const lengthInsideGroupPositionCentimetersNegative = (totalLengthInches: number) =>
  (x: number, totalInches: number = totalLengthInches): number => {
    const inchPosition = totalInches / 2 - 2.6;

    return -(inchPosition * inchesToCentimetersRatio);
  }
    // x - (defaultLengthInches - totalInches) / 2 * inchesToCentimetersRatio;
