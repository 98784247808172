import React, { FC, useEffect, useRef, useState } from 'react';

import { DimensionsOperations, usePergola } from '../../data-stores/pergola';
import { useErrors } from '../../data-stores/errors';

import { dimensionsMinMaxValues } from '../../configurations/dimensions';

import notice from '../../assets/notice.svg';
import close from '../../assets/close.svg';
import { DimensionsLabelsSwitch } from '../switches/DimensionsLabelsSwitch';

export const DimensionsTabContent: FC = (): JSX.Element => {
  const changeHeightFeet = usePergola(state => state.changeHeightFeet);
  const changeHeightInches = usePergola(state => state.changeHeightInches);
  const changeLengthFeet = usePergola(state => state.changeLengthFeet);
  const changeLengthInches = usePergola(state => state.changeLengthInches);
  const changeWidthFeet = usePergola(state => state.changeWidthFeet);
  const changeWidthInches = usePergola(state => state.changeWidthInches);
  const setReadyToUpdate = usePergola(state => state.setReadyToUpdate);
  const setDimensionError = useErrors(state => state.setDimensionsError);

  const heightFeet = usePergola(state => state.dimensions.height.feet);
  const heightInches = usePergola(state => state.dimensions.height.inches);
  const lengthFeet = usePergola(state => state.dimensions.length.feet);
  const lengthInches = usePergola(state => state.dimensions.length.inches);
  const widthFeet = usePergola(state => state.dimensions.width.feet);
  const widthInches = usePergola(state => state.dimensions.width.inches);

  const heightFeetInputRef = useRef<HTMLInputElement>(null);
  const heightFeetMobileInputRef = useRef<HTMLInputElement>(null);
  const heightInchesInputRef = useRef<HTMLInputElement>(null);
  const heightInchesMobileInputRef = useRef<HTMLInputElement>(null);
  const lengthFeetInputRef = useRef<HTMLInputElement>(null);
  const lengthFeetMobileInputRef = useRef<HTMLInputElement>(null);
  const lengthInchesInputRef = useRef<HTMLInputElement>(null);
  const lengthInchesMobileInputRef = useRef<HTMLInputElement>(null);
  const widthFeetInputRef = useRef<HTMLInputElement>(null);
  const widthFeetMobileInputRef = useRef<HTMLInputElement>(null);
  const widthInchesInputRef = useRef<HTMLInputElement>(null);
  const widthInchesMobileInputRef = useRef<HTMLInputElement>(null);

  const [showNotice, setShowNotice] = useState<boolean>(false);
  const [noticeBreakpoints, setNoticeBreakpoints] = useState<{ [key: number]: boolean }>({
    20: false,
    40: false,
    60: false
  });

  const [heightFeetErrorMessage, setHeightFeetErrorMessage] = useState<boolean>(false);
  const [heightInchesErrorMessage, setHeightInchesErrorMessage] = useState<boolean>(false);
  const [lengthFeetErrorMessage, setLengthFeetErrorMessage] = useState<boolean>(false);
  const [lengthInchesErrorMessage, setLengthInchesErrorMessage] = useState<boolean>(false);
  const [widthFeetErrorMessage, setWidthFeetErrorMessage] = useState<boolean>(false);
  const [widthInchesErrorMessage, setWidthInchesErrorMessage] = useState<boolean>(false);

  useEffect(() => {
    if (lengthFeet >= 20 && lengthFeet < 40 && !noticeBreakpoints[20]) {
      setNoticeBreakpoints(state => ({
        ...state,
        20: true
      }));

      setShowNotice(true);
    }

    if (lengthFeet >= 40 && lengthFeet < 60 && !noticeBreakpoints[40]) {
      setNoticeBreakpoints(state => ({
        ...state,
        40: true
      }));

      setShowNotice(true);
    }

    if (lengthFeet >= 60 && !noticeBreakpoints[60]) {
      setNoticeBreakpoints(state => ({
        ...state,
        60: true
      }));

      setShowNotice(true);
    }
  }, [lengthFeet, noticeBreakpoints]);

  useEffect(() => {
    // Used to disable Tab next button
    const errors: boolean[] = [];
    setReadyToUpdate(false);

    // Error check for Height Feet
    if (!isNaN(heightFeet) && heightFeet >= dimensionsMinMaxValues.height.min.feet && heightFeet <= dimensionsMinMaxValues.height.max.feet) {
      setHeightFeetErrorMessage(false);
    } else {
      setHeightFeetErrorMessage(true);
      errors.push(true);
    }

    // Error check for Height Inches
    if (isNaN(heightInches) || (heightFeet === dimensionsMinMaxValues.height.min.feet && heightInches < dimensionsMinMaxValues.height.min.inches) || (heightFeet >= dimensionsMinMaxValues.height.max.feet && heightInches > dimensionsMinMaxValues.height.max.inches) || heightInches >= 12 || heightInches < 0) {
      setHeightInchesErrorMessage(true);
      setDimensionError(true);
      errors.push(true);
    } else {
      setHeightInchesErrorMessage(false);
      setDimensionError(false);
    }

    // Error check for Length Feet
    if (!isNaN(lengthFeet) && lengthFeet >= dimensionsMinMaxValues.length.min.feet && lengthFeet <= dimensionsMinMaxValues.length.max.feet) {
      setLengthFeetErrorMessage(false);
      setDimensionError(false);
    } else {
      setLengthFeetErrorMessage(true);
      setDimensionError(true);
      errors.push(true);
    }

    // Error check for Length Inches
    if (isNaN(lengthInches) || (lengthFeet === dimensionsMinMaxValues.length.max.feet && lengthInches > dimensionsMinMaxValues.length.max.inches) || lengthInches >= 12 || lengthInches < 0) {
      setLengthInchesErrorMessage(true);
      setDimensionError(true);
      errors.push(true);
    } else {
      setLengthInchesErrorMessage(false);
      setDimensionError(false);
    }

    // Error check for Width Feet
    if (!isNaN(widthFeet) && widthFeet >= dimensionsMinMaxValues.width.min.feet && widthFeet <= dimensionsMinMaxValues.width.max.feet) {
      setWidthFeetErrorMessage(false);
      setDimensionError(false);
    } else {
      setWidthFeetErrorMessage(true);
      setDimensionError(true);
      errors.push(true);
    }

    // Error check for Width Inches
    if (isNaN(widthInches) || (widthFeet === dimensionsMinMaxValues.width.max.feet && widthInches > dimensionsMinMaxValues.width.max.inches) || widthInches >= 12 || widthInches < 0) {
      setWidthInchesErrorMessage(true);
      setDimensionError(true);
      errors.push(true);
    } else {
      setWidthInchesErrorMessage(false);
      setDimensionError(false);
    }

    setDimensionError(errors.includes(true));

    if (!errors.includes(true)) {
      setReadyToUpdate(true);
    }
  }, [heightFeet, heightInches, lengthFeet, lengthInches, setDimensionError, setReadyToUpdate, widthFeet, widthInches]);

  // On Height Feet Minus Click
  const minusHeightFeet = () => {
    setReadyToUpdate(false);

    if (heightFeet === dimensionsMinMaxValues.height.min.feet + 1 && heightInches < dimensionsMinMaxValues.height.min.inches) {
      changeHeightFeet(DimensionsOperations.MINUS);
      changeHeightInches(DimensionsOperations.VALUE, dimensionsMinMaxValues.height.min.inches);
      return;
    }

    if (heightFeet > dimensionsMinMaxValues.height.min.feet) {
      changeHeightFeet(DimensionsOperations.MINUS);
    }

    if (heightFeet < dimensionsMinMaxValues.height.min.feet || heightFeet > dimensionsMinMaxValues.height.max.feet) {
      changeHeightFeet(DimensionsOperations.VALUE, dimensionsMinMaxValues.height.min.feet);
    }
  };

  // On Height Feet Input Change
  const onHeightFeetChange = (value: number) => {
    setHeightFeetErrorMessage(false);
    setReadyToUpdate(false);

    if (value === dimensionsMinMaxValues.height.min.feet && heightInches < dimensionsMinMaxValues.height.min.inches) {
      changeHeightInches(DimensionsOperations.VALUE, dimensionsMinMaxValues.height.min.inches);
    }

    if (value === dimensionsMinMaxValues.height.max.feet && heightInches > dimensionsMinMaxValues.height.max.inches) {
      changeHeightInches(DimensionsOperations.VALUE, dimensionsMinMaxValues.height.max.inches);
    }

    changeHeightFeet(DimensionsOperations.VALUE, value);
  };

  // On Height Feet Plus Click
  const plusHeightFeet = () => {
    setReadyToUpdate(false);

    if (heightFeet === dimensionsMinMaxValues.height.max.feet - 1 && heightInches > dimensionsMinMaxValues.height.max.inches) {
      changeHeightFeet(DimensionsOperations.PLUS);
      changeHeightInches(DimensionsOperations.VALUE, dimensionsMinMaxValues.height.max.inches);
      return;
    }

    if (heightFeet < dimensionsMinMaxValues.height.max.feet) {
      changeHeightFeet(DimensionsOperations.PLUS);
    }

    if (heightFeet < dimensionsMinMaxValues.height.min.feet || heightFeet > dimensionsMinMaxValues.height.max.feet) {
      changeHeightFeet(DimensionsOperations.VALUE, dimensionsMinMaxValues.height.min.feet);
    }
  };

  // On Height Inches Minus Click
  const minusHeightInches = () => {
    setReadyToUpdate(false);

    if (heightInches === 0 && heightFeet === dimensionsMinMaxValues.height.min.feet) {
      return;
    }

    if (heightInches === 0 && heightFeet > dimensionsMinMaxValues.height.min.feet) {
      changeHeightFeet(DimensionsOperations.MINUS);
      changeHeightInches(DimensionsOperations.VALUE, 11);
      return;
    }

    if (heightFeet === dimensionsMinMaxValues.height.min.feet && heightInches === dimensionsMinMaxValues.height.min.inches) {
      return;
    }

    if (heightInches < 0 || heightInches > 11) {
      if (heightFeet === dimensionsMinMaxValues.height.min.feet) {
        changeHeightInches(DimensionsOperations.VALUE, dimensionsMinMaxValues.height.min.inches);
        return;
      }

      changeHeightInches(DimensionsOperations.VALUE, 0);
      return;
    }

    changeHeightInches(DimensionsOperations.MINUS);
  };

  // On Height Inches Input Change
  const onHeightInchesChange = (value: number) => {
    setReadyToUpdate(false);

    setHeightInchesErrorMessage(false);

    changeHeightInches(DimensionsOperations.VALUE, value);
  };

  const plusHeightInches = () => {
    setReadyToUpdate(false);

    if (heightFeet >= dimensionsMinMaxValues.height.max.feet && heightInches >= dimensionsMinMaxValues.height.max.inches) {
      return;
    }

    if (heightInches === 11) {
      changeHeightFeet(DimensionsOperations.PLUS);
      changeHeightInches(DimensionsOperations.VALUE, 0);

      return;
    }

    if (heightInches < 0 || heightInches > 11) {
      changeHeightInches(DimensionsOperations.VALUE, 0);
      return;
    }

    changeHeightInches(DimensionsOperations.PLUS);
  };

  // On Length Feet Minus Click
  const minusLengthFeet = () => {
    setReadyToUpdate(false);

    if (lengthFeet > dimensionsMinMaxValues.length.min.feet) {
      changeLengthFeet(DimensionsOperations.MINUS);
    }

    if (lengthFeet < dimensionsMinMaxValues.length.min.feet || lengthFeet > dimensionsMinMaxValues.length.max.feet) {
      changeHeightFeet(DimensionsOperations.VALUE, dimensionsMinMaxValues.length.min.feet);
    }
  };

  // On Length Feet Input Change
  const onLengthFeetChange = (value: number) => {
    setReadyToUpdate(false);

    setHeightFeetErrorMessage(false);

    if (value >= dimensionsMinMaxValues.length.max.feet) {
      changeLengthInches(DimensionsOperations.VALUE, 0);
    }

    changeLengthFeet(DimensionsOperations.VALUE, value);
  };

  // On Length Feet Plus Click
  const plusLengthFeet = () => {
    setReadyToUpdate(false);

    if (lengthFeet === dimensionsMinMaxValues.length.max.feet - 1) {
      changeLengthFeet(DimensionsOperations.PLUS);
      changeLengthInches(DimensionsOperations.VALUE, 0);
      return;
    }

    if (lengthFeet < dimensionsMinMaxValues.length.max.feet) {
      changeLengthFeet(DimensionsOperations.PLUS);
    }

    if (lengthFeet < dimensionsMinMaxValues.length.min.feet || lengthFeet > dimensionsMinMaxValues.length.max.feet) {
      changeLengthFeet(DimensionsOperations.VALUE, dimensionsMinMaxValues.length.min.feet);
    }
  };

  // On Length Inches Minus Click
  const minusLengthInches = () => {
    setReadyToUpdate(false);

    if (lengthInches === 0 && lengthFeet === dimensionsMinMaxValues.length.min.feet) {
      return;
    }

    if (lengthInches === 0 && lengthFeet > dimensionsMinMaxValues.length.min.feet) {
      changeLengthFeet(DimensionsOperations.MINUS);
      changeLengthInches(DimensionsOperations.VALUE, 11);
      return;
    }

    if (lengthInches < 0 || lengthInches > 11) {
      changeLengthInches(DimensionsOperations.VALUE, 0);
      return;
    }

    changeLengthInches(DimensionsOperations.MINUS);
  };

  // On Length Inches Input Change
  const onLengthInchesChange = (value: number) => {
    setReadyToUpdate(false);

    setLengthInchesErrorMessage(false);

    if (lengthFeet === dimensionsMinMaxValues.length.max.feet) {
      changeLengthInches(DimensionsOperations.VALUE, 0);
      return;
    }

    changeLengthInches(DimensionsOperations.VALUE, value);
  };

  // On Length Inches Plus Click
  const plusLengthInches = () => {
    setReadyToUpdate(false);

    if (lengthFeet === dimensionsMinMaxValues.length.max.feet) {
      changeLengthInches(DimensionsOperations.VALUE, 0);
      return;
    }

    if (lengthInches === 11) {
      changeLengthFeet(DimensionsOperations.PLUS);
      changeLengthInches(DimensionsOperations.VALUE, 0);

      return;
    }

    if (lengthInches < 0 || lengthInches > 11) {
      changeLengthInches(DimensionsOperations.VALUE, 0);
      return;
    }

    changeLengthInches(DimensionsOperations.PLUS);
  };

  // On Width Feet Minus Click
  const minusWidthFeet = () => {
    setReadyToUpdate(false);

    if (widthFeet > dimensionsMinMaxValues.width.min.feet) {
      changeWidthFeet(DimensionsOperations.MINUS);
    }

    if (widthFeet < dimensionsMinMaxValues.width.min.feet || widthFeet > dimensionsMinMaxValues.width.max.feet) {
      changeWidthFeet(DimensionsOperations.VALUE, dimensionsMinMaxValues.width.min.feet);
    }
  };

  // On Width Feet Input Change
  const onWidthFeetChange = (value: number) => {
    setReadyToUpdate(false);

    setHeightFeetErrorMessage(false);

    changeWidthFeet(DimensionsOperations.VALUE, value);
  };

  // On Width Feet Plus Click
  const plusWidthFeet = () => {
    setReadyToUpdate(false);

    if (widthFeet === dimensionsMinMaxValues.width.max.feet - 1) {
      changeWidthFeet(DimensionsOperations.PLUS);
      changeWidthInches(DimensionsOperations.VALUE, 0);
      return;
    }

    if (widthFeet < dimensionsMinMaxValues.width.max.feet) {
      changeWidthFeet(DimensionsOperations.PLUS);
    }

    if (widthFeet < dimensionsMinMaxValues.width.min.feet || widthFeet > dimensionsMinMaxValues.width.max.feet) {
      changeWidthFeet(DimensionsOperations.VALUE, dimensionsMinMaxValues.width.min.feet);
    }
  };

  // On Width Inches Minus Click
  const minusWidthInches = () => {
    setReadyToUpdate(false);

    if (widthInches === 0 && widthFeet === dimensionsMinMaxValues.width.min.feet) {
      return;
    }

    if (widthInches === 0 && widthFeet > dimensionsMinMaxValues.width.min.feet) {
      changeWidthFeet(DimensionsOperations.MINUS);
      changeWidthInches(DimensionsOperations.VALUE, 11);
      return;
    }

    if (widthInches < 0 || widthInches > 11) {
      changeWidthInches(DimensionsOperations.VALUE, 0);
      return;
    }

    changeWidthInches(DimensionsOperations.MINUS);
  };

  // On Width Inches Input Change
  const onWidthInchesChange = (value: number) => {
    setReadyToUpdate(false);

    setWidthInchesErrorMessage(false);

    changeWidthInches(DimensionsOperations.VALUE, value);
  };

  // On Width Inches Plus Click
  const plusWidthInches = () => {
    setReadyToUpdate(false);

    if (widthFeet === dimensionsMinMaxValues.width.max.feet) {
      changeLengthInches(DimensionsOperations.VALUE, 0);
      return;
    }

    if (widthInches === 11) {
      changeWidthFeet(DimensionsOperations.PLUS);
      changeWidthInches(DimensionsOperations.VALUE, 0);

      return;
    }

    if (widthInches < 0 || widthInches > 11) {
      changeWidthInches(DimensionsOperations.VALUE, 0);
      return;
    }

    changeWidthInches(DimensionsOperations.PLUS);
  };

  return (
    <>
      <div className="hidden lg:block">
        <div className="text-center mt-8 mb-4 text-text text-sm font-normal">Choose the <span
          className="font-semibold text-text">size</span> of your pergola:
        </div>

        <div className="font-semibold text-sm pl-12 mb-2 height_dimensions_label_margin">Height</div>

        <div className="grid grid-cols-2 gap-5">
          <div className="flex custom_dimension_input_container justify-self-end relative">
            <button
              className={`custom_minus_dimension_button ${heightFeetErrorMessage && 'custom_danger_bg'}`}
              onClick={minusHeightFeet}
            >
              -
            </button>
            <input
              onFocus={e => e.target.select()}
              ref={heightFeetInputRef}
              className={`custom_dimension_input ${heightFeetErrorMessage && 'custom_danger_border'}`}
              type="number"
              value={heightFeet}
              onChange={(e) => onHeightFeetChange(parseInt(e.target.value))}
              onBlur={(e) => {
                if (e.target.value === '' || parseInt(e.target.value) < 0) {
                  setReadyToUpdate(false);

                  changeHeightFeet(DimensionsOperations.VALUE, dimensionsMinMaxValues.height.min.feet);
                }
              }}
            />
            <div
              onClick={() => {
                if (heightFeetInputRef.current) {
                  heightFeetInputRef.current.focus();
                }
              }}
              style={{
                position: 'absolute',
                top: '6px',
                left: `calc(53% + ${heightFeet.toString().length}%)`
              }}
            >
              ′
            </div>
            <button
              className={`custom_plus_dimension_button ${heightFeetErrorMessage && 'custom_danger_bg'}`}
              onClick={plusHeightFeet}
            >
              +
            </button>

            <div
              className={`absolute font-light text-xs mt-1 ${heightFeetErrorMessage ? 'text-red' : 'text-text'}`}
              style={{
                top: '100%',
                left: heightFeetErrorMessage ? '-3%' : '28%',
                width: 'max-content'
              }}
            >
              {
                heightFeetErrorMessage
                  ? `Enter a value between ${dimensionsMinMaxValues.height.min.feet}′ and ${dimensionsMinMaxValues.height.max.feet}′`
                  : `Range: ${dimensionsMinMaxValues.height.min.feet}′ - ${dimensionsMinMaxValues.height.max.feet}′`
              }
            </div>
          </div>

          <div className="flex custom_dimension_input_container justify-self-start relative">
            <button
              className={`custom_minus_dimension_button ${heightInchesErrorMessage && 'custom_danger_bg'}`}
              onClick={minusHeightInches}
            >
              -
            </button>
            <input
              onFocus={e => e.target.select()}
              ref={heightInchesInputRef}
              className={`custom_dimension_input ${heightInchesErrorMessage && 'custom_danger_border'}`}
              type="number"
              value={heightInches}
              onChange={(e) => onHeightInchesChange(parseInt(e.target.value))}
              onBlur={(e) => {
                setReadyToUpdate(false);

                if (e.target.value === '' || parseInt(e.target.value) < 0) {
                  if (heightFeet === dimensionsMinMaxValues.height.min.feet) {
                    changeHeightInches(DimensionsOperations.VALUE, dimensionsMinMaxValues.height.min.inches);
                    return;
                  }

                  changeHeightInches(DimensionsOperations.VALUE, 0);
                }
              }}
            />
            <div
              onClick={() => {
                if (heightInchesInputRef.current) {
                  heightInchesInputRef.current.focus();
                }
              }}
              style={{
                position: 'absolute',
                top: '6px',
                left: `calc(53% + ${heightInches.toString().length}%)`
              }}
            >
              ″
            </div>
            <button
              className={`custom_plus_dimension_button ${heightInchesErrorMessage && 'custom_danger_bg'}`}
              onClick={plusHeightInches}
            >
              +
            </button>

            <div
              className={`absolute font-light text-xs mt-1 ${heightInchesErrorMessage ? 'text-red' : 'text-text'}`}
              style={{
                top: '100%',
                left: heightInchesErrorMessage ? '-3%' : '28%',
                width: 'max-content'
              }}
            >
              {
                heightInchesErrorMessage
                  ? `Enter a value between ${heightFeet === dimensionsMinMaxValues.height.min.feet ? dimensionsMinMaxValues.height.min.inches : 0}″ and ${heightFeet === dimensionsMinMaxValues.height.max.feet ? dimensionsMinMaxValues.height.max.inches : 11}″`
                  : `Range: ${heightFeet === dimensionsMinMaxValues.height.min.feet ? dimensionsMinMaxValues.height.min.inches : 0}″ - ${heightFeet === dimensionsMinMaxValues.height.max.feet ? dimensionsMinMaxValues.height.max.inches : 11}″`
              }
            </div>
          </div>
        </div>

        <div className="font-semibold text-sm pl-12 mb-2 mt-10">Length</div>

        <div className="grid grid-cols-2 gap-5">
          <div className="flex custom_dimension_input_container justify-self-end relative">
            <button
              className={`custom_minus_dimension_button ${lengthFeetErrorMessage && 'custom_danger_bg'}`}
              onClick={minusLengthFeet}
            >
              -
            </button>
            <input
              onFocus={e => e.target.select()}
              ref={lengthFeetInputRef}
              className={`custom_dimension_input ${lengthFeetErrorMessage && 'custom_danger_border'}`}
              type="number"
              value={lengthFeet}
              onChange={(e) => onLengthFeetChange(parseInt(e.target.value))}
              onBlur={(e) => {
                setReadyToUpdate(false);


                if (e.target.value === '' || parseInt(e.target.value) < 0) {
                  changeLengthFeet(DimensionsOperations.VALUE, dimensionsMinMaxValues.length.min.feet);
                }
              }}
            />
            <div
              onClick={() => {
                if (lengthFeetInputRef.current) {
                  lengthFeetInputRef.current.focus();
                }
              }}
              style={{
                position: 'absolute',
                top: '6px',
                left: `calc(53% + ${lengthFeet.toString().length}%)`
              }}
            >
              ′
            </div>
            <button
              className={`custom_plus_dimension_button ${lengthFeetErrorMessage && 'custom_danger_bg'}`}
              onClick={plusLengthFeet}
            >
              +
            </button>

            <div
              className={`absolute font-light text-xs mt-1 ${lengthFeetErrorMessage ? 'text-red' : 'text-text'}`}
              style={{
                top: '100%',
                left: lengthFeetErrorMessage ? '-3%' : '28%',
                width: 'max-content'
              }}
            >
              {
                lengthFeetErrorMessage
                  ? `Enter a value between ${dimensionsMinMaxValues.length.min.feet}′ and ${dimensionsMinMaxValues.length.max.feet}′`
                  : `Range: ${dimensionsMinMaxValues.length.min.feet}′ - ${dimensionsMinMaxValues.length.max.feet}′`
              }
            </div>
          </div>

          <div className="flex custom_dimension_input_container justify-self-start relative">
            <button
              className={`custom_minus_dimension_button ${lengthInchesErrorMessage && 'custom_danger_bg'}`}
              onClick={minusLengthInches}
            >
              -
            </button>
            <input
              onFocus={e => e.target.select()}
              ref={lengthInchesInputRef}
              className={`custom_dimension_input ${lengthInchesErrorMessage && 'custom_danger_border'}`}
              type="number"
              value={lengthInches}
              onChange={(e) => onLengthInchesChange(parseInt(e.target.value))}
              onBlur={(e) => {
                setReadyToUpdate(false);


                if (e.target.value === '' || parseInt(e.target.value) < 0) {
                  changeLengthInches(DimensionsOperations.VALUE, 0);
                }
              }}
            />
            <div
              onClick={() => {
                if (lengthInchesInputRef.current) {
                  lengthInchesInputRef.current.focus();
                }
              }}
              style={{
                position: 'absolute',
                top: '6px',
                left: `calc(53% + ${lengthInches.toString().length}%)`
              }}
            >
              ″
            </div>
            <button
              className={`custom_plus_dimension_button ${lengthInchesErrorMessage && 'custom_danger_bg'}`}
              onClick={plusLengthInches}
            >
              +
            </button>

            <div
              className={`absolute font-light text-xs mt-1 ${lengthInchesErrorMessage ? 'text-red' : 'text-text'}`}
              style={{
                top: '100%',
                left: lengthInchesErrorMessage ? '-3%' : '28%',
                width: 'max-content'
              }}
            >
              {
                lengthInchesErrorMessage
                  ? `Enter a value between ${lengthFeet === dimensionsMinMaxValues.length.min.feet ? dimensionsMinMaxValues.length.min.inches : 0}″ and ${lengthFeet === dimensionsMinMaxValues.length.max.feet ? dimensionsMinMaxValues.length.max.inches : 11}″`
                  : `Range: ${lengthFeet === dimensionsMinMaxValues.length.min.feet ? dimensionsMinMaxValues.length.min.inches : 0}″ - ${lengthFeet === dimensionsMinMaxValues.length.max.feet ? dimensionsMinMaxValues.length.max.inches : 11}″`
              }
            </div>
          </div>
        </div>

        <div className="font-semibold text-sm pl-12 mb-2 mt-10">Width</div>

        <div className="grid grid-cols-2 gap-5">
          <div className="flex custom_dimension_input_container justify-self-end relative">
            <button
              className={`custom_minus_dimension_button ${widthFeetErrorMessage && 'custom_danger_bg'}`}
              onClick={minusWidthFeet}
            >
              -
            </button>
            <input
              onFocus={e => e.target.select()}
              ref={widthFeetInputRef}
              className={`custom_dimension_input ${widthFeetErrorMessage && 'custom_danger_border'}`}
              type="number"
              value={widthFeet}
              onChange={(e) => onWidthFeetChange(parseInt(e.target.value))}
              onBlur={(e) => {
                setReadyToUpdate(false);


                if (e.target.value === '' || parseInt(e.target.value) < 0) {
                  changeWidthFeet(DimensionsOperations.VALUE, dimensionsMinMaxValues.width.min.feet);
                }
              }}
            />
            <div
              onClick={() => {
                if (widthFeetInputRef.current) {
                  widthFeetInputRef.current.focus();
                }
              }}
              style={{
                position: 'absolute',
                top: '6px',
                left: `calc(53% + ${widthFeet.toString().length}%)`
              }}
            >
              ′
            </div>
            <button
              className={`custom_plus_dimension_button ${widthFeetErrorMessage && 'custom_danger_bg'}`}
              onClick={plusWidthFeet}
            >
              +
            </button>

            <div
              className={`absolute font-light text-xs mt-1 ${widthFeetErrorMessage ? 'text-red' : 'text-text'}`}
              style={{
                top: '100%',
                left: widthFeetErrorMessage ? '-3%' : '28%',
                width: 'max-content'
              }}
            >
              {
                widthFeetErrorMessage
                  ? `Enter a value between ${dimensionsMinMaxValues.width.min.feet}′ and ${dimensionsMinMaxValues.width.max.feet}′`
                  : `Range: ${dimensionsMinMaxValues.width.min.feet}′ - ${dimensionsMinMaxValues.width.max.feet}′`
              }
            </div>
          </div>

          <div className="flex custom_dimension_input_container justify-self-start relative">
            <button
              className={`custom_minus_dimension_button ${widthInchesErrorMessage && 'custom_danger_bg'}`}
              onClick={minusWidthInches}
            >
              -
            </button>
            <input
              onFocus={e => e.target.select()}
              ref={widthInchesInputRef}
              className={`custom_dimension_input ${widthInchesErrorMessage && 'custom_danger_border'}`}
              type="number"
              value={widthInches}
              onChange={(e) => onWidthInchesChange(parseInt(e.target.value))}
              onBlur={(e) => {
                setReadyToUpdate(false);


                if (e.target.value === '' || parseInt(e.target.value) < 0) {
                  changeWidthInches(DimensionsOperations.VALUE, 0);
                }
              }}
            />
            <div
              onClick={() => {
                if (widthInchesInputRef.current) {
                  widthInchesInputRef.current.focus();
                }
              }}
              style={{
                position: 'absolute',
                top: '6px',
                left: `calc(53% + ${widthInches.toString().length}%)`
              }}
            >
              ″
            </div>
            <button
              className={`custom_plus_dimension_button ${widthInchesErrorMessage && 'custom_danger_bg'}`}
              onClick={plusWidthInches}
            >
              +
            </button>

            <div
              className={`absolute font-light text-xs mt-1 ${widthInchesErrorMessage ? 'text-red' : 'text-text'}`}
              style={{
                top: '100%',
                left: widthInchesErrorMessage ? '-3%' : '28%',
                width: 'max-content'
              }}
            >
              {
                widthInchesErrorMessage
                  ? `Enter a value between ${widthFeet === dimensionsMinMaxValues.width.min.feet ? dimensionsMinMaxValues.width.min.inches : 0}″ and ${widthFeet === dimensionsMinMaxValues.width.max.feet ? dimensionsMinMaxValues.width.max.inches : 11}″`
                  : `Range: ${widthFeet === dimensionsMinMaxValues.width.min.feet ? dimensionsMinMaxValues.width.min.inches : 0}″ - ${widthFeet === dimensionsMinMaxValues.width.max.feet ? dimensionsMinMaxValues.width.max.inches : 11}″`
              }
            </div>
          </div>

        </div>

        <div className="my-16 px-4">
          <DimensionsLabelsSwitch/>
        </div>
      </div>

      <div className="lg:hidden h-full flex flex-col justify-between pb-3">
        <div>
          <div className="text-center mt-2 mb-4 text-text text-sm font-light">Choose the <span
            className="font-semibold text-text">size</span> of your pergola:
          </div>
          <div className="grid grid-cols-3 items-center justify-around">
            <div className="justify-self-center text-text font-semibold text-sm">Height</div>
            <div className="justify-self-center text-text font-semibold text-sm">Length</div>
            <div className="justify-self-center text-text font-semibold text-sm">Width</div>
          </div>

          <div className="relative grid grid-cols-3 items-center justify-around mt-2">
            <div className="flex justify-self-center">
              <div className="relative">
                <input
                  onFocus={e => e.target.select()}
                  inputMode="decimal"
                  ref={heightFeetMobileInputRef}
                  className={`custom_dimension_mobile_input custom_dimension_feet_input ${heightFeetErrorMessage && 'custom_danger_border'}`}
                  type="number"
                  value={heightFeet}
                  onChange={(e) => onHeightFeetChange(parseInt(e.target.value))}
                  onBlur={(e) => {
                    setReadyToUpdate(false);


                    if (e.target.value === '' || parseInt(e.target.value) < 0) {
                      changeHeightFeet(DimensionsOperations.VALUE, dimensionsMinMaxValues.height.min.feet);
                    }
                  }}
                />
                <div
                  onClick={() => {
                    if (heightFeetMobileInputRef.current) {
                      heightFeetMobileInputRef.current.focus();
                    }
                  }}
                  style={{
                    position: 'absolute',
                    top: '6px',
                    left: `calc(65% + ${heightFeet.toString().length}%)`
                  }}
                >
                  ′
                </div>
                <div
                  className={`absolute font-light text-xs mt-1 ${heightFeetErrorMessage || heightInchesErrorMessage ? 'text-red' : 'text-text'}`}
                  style={{
                    top: '100%',
                    left: '0',
                    width: 'max-content'
                  }}
                >
                  Range: {dimensionsMinMaxValues.height.min.feet}′{dimensionsMinMaxValues.height.min.inches}″
                  - {dimensionsMinMaxValues.height.max.feet}′{dimensionsMinMaxValues.height.max.inches}″
                </div>
              </div>
              <div className="relative">
                <input
                  onFocus={e => e.target.select()}
                  inputMode="decimal"
                  ref={heightInchesMobileInputRef}
                  className={`custom_dimension_mobile_input custom_dimension_inches_input ${heightInchesErrorMessage && 'custom_danger_border'}`}
                  type="number"
                  value={heightInches}
                  onChange={(e) => onHeightInchesChange(parseInt(e.target.value))}
                  onBlur={(e) => {
                    setReadyToUpdate(false);


                    if (e.target.value === '' || parseInt(e.target.value) < 0) {
                      if (heightFeet === dimensionsMinMaxValues.height.min.feet) {
                        changeHeightInches(DimensionsOperations.VALUE, dimensionsMinMaxValues.height.min.inches);
                        return;
                      }

                      changeHeightInches(DimensionsOperations.VALUE, 0);
                    }
                  }}
                />
                <div
                  onClick={() => {
                    if (heightInchesMobileInputRef.current) {
                      heightInchesMobileInputRef.current.focus();
                    }
                  }}
                  style={{
                    position: 'absolute',
                    top: '6px',
                    left: `calc(65% + ${heightInches.toString().length}%)`
                  }}
                >
                  ″
                </div>
              </div>
            </div>

            <div className="flex justify-self-center">
              <div className="relative">
                <input
                  onFocus={e => e.target.select()}
                  inputMode="decimal"
                  ref={lengthFeetMobileInputRef}
                  className={`custom_dimension_mobile_input custom_dimension_feet_input ${lengthFeetErrorMessage && 'custom_danger_border'}`}
                  type="number"
                  value={lengthFeet}
                  onChange={(e) => onLengthFeetChange(parseInt(e.target.value))}
                  onBlur={(e) => {
                    setReadyToUpdate(false);


                    if (e.target.value === '' || parseInt(e.target.value) < 0) {
                      changeLengthFeet(DimensionsOperations.VALUE, dimensionsMinMaxValues.length.min.feet);
                    }
                  }}
                />
                <div
                  onClick={() => {
                    if (lengthFeetMobileInputRef.current) {
                      lengthFeetMobileInputRef.current.focus();
                    }
                  }}
                  style={{
                    position: 'absolute',
                    top: '6px',
                    left: `calc(65% + ${heightFeet.toString().length}%)`
                  }}
                >
                  ′
                </div>

                <div
                  className={`absolute font-light text-xs mt-1 ${lengthFeetErrorMessage || lengthInchesErrorMessage ? 'text-red' : 'text-text'}`}
                  style={{
                    top: '100%',
                    left: '0',
                    width: 'max-content'
                  }}
                >
                  Range: {dimensionsMinMaxValues.length.min.feet}′{dimensionsMinMaxValues.length.min.inches}″
                  - {dimensionsMinMaxValues.length.max.feet}′{dimensionsMinMaxValues.length.max.inches}″
                </div>
              </div>
              <div className="relative">
                <input
                  onFocus={e => e.target.select()}
                  inputMode="decimal"
                  ref={lengthInchesMobileInputRef}
                  className={`custom_dimension_mobile_input custom_dimension_inches_input ${lengthInchesErrorMessage && 'custom_danger_border'}`}
                  type="number"
                  value={lengthInches}
                  onChange={(e) => onLengthInchesChange(parseInt(e.target.value))}
                  onBlur={(e) => {
                    setReadyToUpdate(false);


                    if (e.target.value === '' || parseInt(e.target.value) < 0) {
                      changeLengthInches(DimensionsOperations.VALUE, 0);
                    }
                  }}
                />
                <div
                  onClick={() => {
                    if (lengthInchesMobileInputRef.current) {
                      lengthInchesMobileInputRef.current.focus();
                    }
                  }}
                  style={{
                    position: 'absolute',
                    top: '6px',
                    left: `calc(65% + ${heightInches.toString().length}%)`
                  }}
                >
                  ″
                </div>
              </div>
            </div>

            <div className="flex justify-self-center">
              <div className="relative">
                <input
                  onFocus={e => e.target.select()}
                  inputMode="decimal"
                  ref={widthFeetMobileInputRef}
                  className={`custom_dimension_mobile_input custom_dimension_feet_input ${widthFeetErrorMessage && 'custom_danger_border'}`}
                  type="number"
                  value={widthFeet}
                  onChange={(e) => onWidthFeetChange(parseInt(e.target.value))}
                  onBlur={(e) => {
                    setReadyToUpdate(false);

                    if (e.target.value === '' || parseInt(e.target.value) < 0) {
                      changeWidthFeet(DimensionsOperations.VALUE, dimensionsMinMaxValues.width.min.feet);
                    }
                  }}
                />
                <div
                  onClick={() => {
                    if (widthFeetMobileInputRef.current) {
                      widthFeetMobileInputRef.current.focus();
                    }
                  }}
                  style={{
                    position: 'absolute',
                    top: '6px',
                    left: `calc(65% + ${heightFeet.toString().length}%)`
                  }}
                >
                  ′
                </div>

                <div
                  className={`absolute font-light text-xs mt-1 ${widthFeetErrorMessage || widthInchesErrorMessage ? 'text-red' : 'text-text'}`}
                  style={{
                    top: '100%',
                    left: '0',
                    width: 'max-content'
                  }}
                >
                  Range: {dimensionsMinMaxValues.width.min.feet}′{dimensionsMinMaxValues.width.min.inches}″
                  - {dimensionsMinMaxValues.width.max.feet}′{dimensionsMinMaxValues.width.max.inches}″
                </div>
              </div>
              <div className="relative">
                <input
                  onFocus={e => e.target.select()}
                  inputMode="decimal"
                  ref={widthInchesMobileInputRef}
                  className={`custom_dimension_mobile_input custom_dimension_inches_input ${widthInchesErrorMessage && 'custom_danger_border'}`}
                  type="number"
                  value={widthInches}
                  onChange={(e) => onWidthInchesChange(parseInt(e.target.value))}
                  onBlur={(e) => {
                    setReadyToUpdate(false);


                    if (e.target.value === '' || parseInt(e.target.value) < 0) {
                      changeWidthInches(DimensionsOperations.VALUE, 0);
                    }
                  }}
                />
                <div
                  onClick={() => {
                    if (widthInchesMobileInputRef.current) {
                      widthInchesMobileInputRef.current.focus();
                    }
                  }}
                  style={{
                    position: 'absolute',
                    top: '6px',
                    left: `calc(65% + ${heightInches.toString().length}%)`
                  }}
                >
                  ″
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="my-10 px-4">
          <DimensionsLabelsSwitch/>
        </div>

        {showNotice && (
          <div className={`mt-10 mb-2 px-2`}>
            <div className={`notice_mobile_container pb-2 transition-opacity`}>
              <div
                className="grid notice_mobile_grid border-0 p-2 p2-4 mt-4">
                <img
                  src={notice}
                  alt="notice icon"
                />

                <div className="text-sm font-semibold text-green mt-1">Please Notice</div>

                <img
                  className="cursor-pointer"
                  src={close}
                  alt="close icon"
                  style={{
                    marginTop: '5px'
                  }}
                  onClick={() => setShowNotice(false)}
                />
              </div>
              <div className="text-sm font-extralight text-text pl-2 pr-12">
                For your safety, length or width
                of {(lengthFeet >= 20 && lengthFeet < 40) || (widthFeet >= 20 && widthFeet < 40) ? '20' : (lengthFeet >= 40 && lengthFeet < 60) || widthFeet >= 40 ? '40' : '60'}’
                and above comes with 2 additional posts respectively for each side.
              </div>
            </div>
          </div>
        )}

      </div>
    </>
  );
};
