import React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { usePergola } from '../../data-stores/pergola';

import { StructureColor } from '../../configurations/colors';

import hamptonWhite from '../../assets/hampton-white.png';
import charcoalGray from '../../assets/charcoal-gray.png';
import brazilianIpe from '../../assets/brazilian-ipe.png';
import tuscanCedar from '../../assets/tuscan-cedar.png';
import { Cross } from '../icons/Cross';

export const StructureColorRadio = () => {
  const structureColor = usePergola(state => state.design.color);
  const changePergolaColor = usePergola(state => state.changePergolaColor);

  return (
    <RadioGroupPrimitive.Root
      className="grid grid-cols-4 justify-around pergola_design_color_container"
      defaultValue={structureColor}
      aria-label="View density"
    >
      <div className="flex flex-col items-center justify-center pergola_design_color_label_wrapper">
        <RadioGroupPrimitive.Item
          id="r1"
          className="pergola_design_color_item opacity-50 disabled"
          checked={structureColor === StructureColor.HAMPTON_WHITE}
          value={StructureColor.HAMPTON_WHITE}
          onClick={() => changePergolaColor(StructureColor.HAMPTON_WHITE)}
          disabled={true}
        >
          <div
            className="pergola_design_color_circle"
          >
            <img
              className="rounded-full swatchBorder"
              src={hamptonWhite}
              alt="Hampton White color"
            />
            <div className="cross_icon_container">
              <Cross width={40} height={40}/>
            </div>
          </div>
          <RadioGroupPrimitive.Indicator className="pergola_design_color_indicator"/>
        </RadioGroupPrimitive.Item>
        <label
          htmlFor="r1"
          className={`opacity-50 disabled-color-label pocket-text text-xs lg:text-sm ${structureColor === StructureColor.HAMPTON_WHITE ? 'pergola_design_color_selected_label' : 'pergola_design_color_label'}`}
        >
          <s>Hampton White</s>
          <span className="tooltip_text hidden lg:block">Out of stock</span>
        </label>
      </div>

      <div className="flex flex-col items-center justify-center">
        <RadioGroupPrimitive.Item
          id="r2"
          className="pergola_design_color_item"
          checked={structureColor === StructureColor.CHARCOAL_GRAY}
          value={StructureColor.CHARCOAL_GRAY}
          onClick={() => changePergolaColor(StructureColor.CHARCOAL_GRAY)}
        >
          <div
            className="pergola_design_color_circle"
          >
            <img
              className="rounded-full"
              src={charcoalGray}
              alt="Charcoal Gray color"
            />
          </div>
          <RadioGroupPrimitive.Indicator className="pergola_design_color_indicator"/>
        </RadioGroupPrimitive.Item>
        <label
          htmlFor="r2"
          className={`pocket-text text-xs lg:text-sm ${structureColor === StructureColor.CHARCOAL_GRAY ? 'pergola_design_color_selected_label' : 'pergola_design_color_label'}`}
        >
          Charcoal Gray
        </label>
      </div>

      <div className="flex flex-col items-center justify-center">
        <RadioGroupPrimitive.Item
          id="r3"
          className="pergola_design_color_item"
          checked={structureColor === StructureColor.BRAZILIAN_IPE}
          value={StructureColor.BRAZILIAN_IPE}
          onClick={() => changePergolaColor(StructureColor.BRAZILIAN_IPE)}
        >
          <div
            className="pergola_design_color_circle"
          >
            <img
              className="rounded-full"
              src={brazilianIpe}
              alt="Brazilian Ipe color"
            />
          </div>
          <RadioGroupPrimitive.Indicator className="pergola_design_color_indicator"/>
        </RadioGroupPrimitive.Item>
        <label
          htmlFor="r3"
          className={`pocket-text text-xs lg:text-sm ${structureColor === StructureColor.BRAZILIAN_IPE ? 'pergola_design_color_selected_label' : 'pergola_design_color_label'}`}
        >
          Brazilian Ipe
        </label>
      </div>

      <div className="flex flex-col items-center justify-center">
        <RadioGroupPrimitive.Item
          id="r4"
          className="pergola_design_color_item"
          checked={structureColor === StructureColor.TUSCAN_CEDAR}
          value={StructureColor.TUSCAN_CEDAR}
          onClick={() => changePergolaColor(StructureColor.TUSCAN_CEDAR)}
        >
          <div
            className="pergola_design_color_circle"
          >
            <img
              className="rounded-full"
              src={tuscanCedar}
              alt="Tuscan Cedar color"
            />
          </div>
          <RadioGroupPrimitive.Indicator className="pergola_design_color_indicator"/>
        </RadioGroupPrimitive.Item>
        <label
          htmlFor="r4"
          className={`pocket-text text-xs lg:text-sm ${structureColor === StructureColor.TUSCAN_CEDAR ? 'pergola_design_color_selected_label' : 'pergola_design_color_label'}`}
        >
          Tuscan Cedar
        </label>
      </div>
    </RadioGroupPrimitive.Root>
  );
};

export default StructureColorRadio;
