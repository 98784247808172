/**
 * Convert inches to feet and inches
 *
 * @param {number} value The inches that need to be transformed into Feet and Inches
 *
 * @return {
 *   feet: number,
 *   inches: number
 * }
 */

export const convertInchesToFeetAndInches = (value: number) => {
  const feet = Math.floor(value / 12);
  const inches = value % 12;

  return {
    feet,
    inches
  };
};

/**
 * Calculate internal Height
 *
 * @param {number} heightFeet The Height feet of the pergola
 * @param {number} heightInches The Height inches of the pergola
 *
 * @return {
 *   feet: number,
 *   inches: number
 * }
 */

export const getInternalHeight = (heightFeet: number, heightInches: number) => {
  return {
    feet: heightFeet - 1,
    inches: heightInches
  };
};

/**
 * Calculate internal Length
 *
 * @param {number} lengthFeet The Length feet of the pergola
 * @param {number} lengthInches The Length inches of the pergola
 *
 * @return {
 *   feet: number,
 *   inches: number
 * }
 */
export const getInternalLength = (lengthFeet: number, lengthInches: number) => {
  return {
    feet: lengthFeet - 1,
    inches: lengthInches
  };
};

/**
 * Calculate internal Width
 *
 * @param {number} widthFeet The Width feet of the pergola
 * @param {number} widthInches The Width inches of the pergola
 *
 * @return {
 *   feet: number,
 *   inches: number
 * }
 */

export const getInternalWidth = (widthFeet: number, widthInches: number) => {
  // Convert external height to inches
  const externalInches: number = Math.round(widthFeet * 12) + widthInches;

  // Calculate internal height in inches
  const internalInches = externalInches - 28;

  // Convert internal inches to feet and inches
  return convertInchesToFeetAndInches(internalInches);
};