import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';

interface ContextProps {
  onMobile: boolean;
}

const defaultContextValues: ContextProps = {
  onMobile: false,
};

const MediaQueryContext = React.createContext<ContextProps>(defaultContextValues);

interface Props {
  children?: ReactNode;
}

export const MediaQueryContextProvider: FC<Props> = ({ children }: Props) => {
  const [onMobile, setOnMobile] = useState<boolean>(false);
  const mediaQueryChanged = (e: MediaQueryListEvent): void => setOnMobile(e.matches);

  useEffect(() => {
    const mql = window.matchMedia('(max-width: 1067px)');
    mql.addEventListener('change', mediaQueryChanged);
    setOnMobile(mql.matches);
    return (): void => mql.removeEventListener('change', mediaQueryChanged);
  }, []);

  const providerValue = {
    onMobile,
  };

  return (
    <MediaQueryContext.Provider value={providerValue}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export const useMediaQuery = () => {
  const context = useContext(MediaQueryContext);

  if (context === undefined) {
    throw new Error('You must use this context within MediaQueryContext.Provider');
  }

  return context;
};

export default MediaQueryContext;
