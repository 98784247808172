import React, { FC } from 'react';
import * as SwitchPrimitive from '@radix-ui/react-switch';
import { usePergola } from '../../data-stores/pergola';


export const DimensionsLabelsSwitch: FC = (): JSX.Element => {
  const { showDimensionsLabels, toggleDimensionsLabels } = usePergola(s => s);

  return (
    <div className="bg-[#F4F3F3] p-4 flex items-center justify-between w-full rounded-lg">
      <div className="flex w-full">
        <div className="font-semibold	text-sm text-text w-full">Show dimensions labels</div>
      </div>

      <SwitchPrimitive.Root
        className="canopy_switch_root"
        id="s1"
        checked={showDimensionsLabels}
        onClick={toggleDimensionsLabels}
      >
        <SwitchPrimitive.Thumb className="canopy_switch_thumb"/>
      </SwitchPrimitive.Root>
    </div>
  );
};
