import React, { FC, useEffect, useState } from 'react';

import { DimensionsOperations, usePergola } from '../../data-stores/pergola';
import { useErrors } from '../../data-stores/errors';
import { useBeamsConfiguration } from '../../data-stores/beams-configuration';

export const BeamsTabContent: FC = (): JSX.Element => {
  const lengthFeet = usePergola(state => state.dimensions.length.feet);
  const lengthInches = usePergola(state => state.dimensions.length.inches);
  const hasCanopy = usePergola(state => state.canopy.hasCanopy);
  const beams = usePergola(state => state.beams);
  const minBeams = useBeamsConfiguration(state => state.minBeams);
  const maxBeams = useBeamsConfiguration(state => state.maxBeams);
  const beamsStep = useBeamsConfiguration(state => state.beamsStep);
  const distanceBetweenBeams = useBeamsConfiguration(state => state.distanceBetweenBeams);

  const setReadyToUpdate = usePergola(state => state.setReadyToUpdate);
  const setBeamsError = useErrors(state => state.setBeamsError);
  const changeBeamsCount = usePergola(state => state.changeBeamsCount);

  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [beamsMustBeOddError, setBeamsMustBeOddError] = useState<boolean>(false);

  useEffect(() => {
    if (beams < minBeams) {
      changeBeamsCount(DimensionsOperations.VALUE, beamsStep, minBeams);
    }

    if (beams > maxBeams) {
      changeBeamsCount(DimensionsOperations.VALUE, beamsStep, maxBeams);
    }

    if ((beams - minBeams) % beamsStep !== 0) {
      changeBeamsCount(DimensionsOperations.VALUE, beamsStep, minBeams);
    }
  }, [minBeams, maxBeams, changeBeamsCount, beams, beamsStep]);

  useEffect(() => {
    if (beams === 0 || beams < minBeams) {
      changeBeamsCount(DimensionsOperations.VALUE, beamsStep, minBeams);
    }

    if (beams === minBeams && minBeams % 2 === 0) {
      changeBeamsCount(DimensionsOperations.VALUE, beamsStep, minBeams + 1);
    }
  }, [beams, beamsStep, changeBeamsCount, lengthFeet, lengthInches, minBeams]);

  useEffect(() => {
    if (!minBeams || !maxBeams || !beams) {
      setErrorMessage(true);
      setBeamsError(true);
      return;
    }

    if (beams >= minBeams && beams <= maxBeams) {
      setErrorMessage(false);
      setBeamsError(false);
      setReadyToUpdate(true);
    }

    if (beams % 2 === 0) {
      setBeamsMustBeOddError(true);
      setErrorMessage(true);
      setBeamsError(true);
    } else {
      setBeamsMustBeOddError(false);
      setBeamsError(false);
      setErrorMessage(false);
    }
  }, [maxBeams, minBeams, setBeamsError, beams, setReadyToUpdate]);

  return (
    <div className="flex lg:block flex-col">
      <div className="text-center text-text mt-2 lg:mt-8 mb-6 text-sm font-light lg:font-normal">Choose the
        <span className="font-semibold lg:font-bold text-text"> amount of beams </span>
        for your pergola:
      </div>

      <div className="custom_beams_number_container px-4 mx-4 lg:mx-0">
        <div className="flex items-center">
          <div className="justify-self-end font-extralight text-extralight text-xs">min {minBeams}</div>

          <div className="flex custom_dimension_input_container justify-self-end mx-4">
            <button
              className={`custom_minus_dimension_button ${errorMessage && 'custom_danger_bg'}`}
              onClick={() => {
                setReadyToUpdate(false);

                if (beams > minBeams) {
                  changeBeamsCount(DimensionsOperations.MINUS, beamsStep);
                }

                if (beams < minBeams || beams > maxBeams || beams === 0) {
                  changeBeamsCount(DimensionsOperations.VALUE, beamsStep, minBeams);
                }
              }}
            >
              -
            </button>
            <input
              onFocus={e => e.target.select()}
              inputMode="decimal"
              className={`custom_dimension_input ${errorMessage && 'custom_danger_border'}`}
              style={{ width: '90px' }}
              type="number"
              value={beams}
              step={beamsStep}
              onBlur={(e) => {
                setReadyToUpdate(false);

                if (e.target.value === '' || parseInt(e.target.value) < 0) {
                  changeBeamsCount(DimensionsOperations.VALUE, beamsStep, minBeams);
                }
              }}
              onChange={(e) => {
                setReadyToUpdate(false);
                setErrorMessage(false);

                changeBeamsCount(DimensionsOperations.VALUE, beamsStep, parseInt(e.target.value));
              }}
            />
            <button
              className={`custom_plus_dimension_button ${errorMessage && 'custom_danger_bg'}`}
              onClick={() => {
                setReadyToUpdate(false);

                if (beams < maxBeams) {
                  changeBeamsCount(DimensionsOperations.PLUS, beamsStep);
                }

                if (beams < minBeams || beams > maxBeams || beams === 0) {
                  changeBeamsCount(DimensionsOperations.VALUE, beamsStep, minBeams);
                }
              }}
            >
              +
            </button>
          </div>

          <div className="justify-self-start font-extralight text-xs text-extralight">max {maxBeams}</div>
        </div>

        {
          errorMessage && !beamsMustBeOddError && (
            <div className="text-red font-normal text-sm mt-4">Beams count must be between {minBeams} and {maxBeams}</div>
          )
        }

        {
          beamsMustBeOddError && (
            <div className="text-red font-normal text-sm mt-4">Beams count must be an odd number</div>
          )
        }

        <div className="font-normal text-text text-sm mt-4">
          You’ll have
          <span className="text-green font-bold"> {distanceBetweenBeams}’’ </span>
          between beams
        </div>
      </div>

      {
        (!hasCanopy && beams <= ((maxBeams - minBeams) / 2)) && (
          <div className="text-orange font-medium text-xs text-center px-8 lg:px-0 mt-2 lg:mt-8">
            Few beams means more sun. Consider adding canopy!
          </div>
        )
      }
    </div>
  );
};
