// import * as THREE from 'three'
import React, { FC, useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

import { CanopyMaterial } from '../../materials/CanopyMaterial';
import { lengthScale } from '../../utilities/scaling';
import { PergolaDesign, usePergola } from '../../../data-stores/pergola';
import { Ref } from 'react';
import { Group, Mesh } from 'three';

type GLTFResult = GLTF & {
  nodes: {
    Plane: Mesh
  }
  materials: {}
}

interface CanopyCoverProps {
  hPositionM: (x: number) => number;
  wPositionMP: (x: number) => number;
  totalWidthInches: number;
  totalLengthInches: number;
}

export const CanopyCover: FC<CanopyCoverProps & JSX.IntrinsicElements['group']> = ({
  hPositionM,
  wPositionMP,
  totalWidthInches,
  totalLengthInches,
  ...props
}) => {
  const group = useRef() as Ref<Group> | undefined;

  const { nodes } = useGLTF('/3d-models/canopy-cover.glb') as unknown as GLTFResult;

  const pergolaDesign = usePergola(s => s.design.pergola)
  const isAttached = pergolaDesign === PergolaDesign.ATTACHED;
  const lScale = lengthScale(totalLengthInches - 3);

  return (
    <>
      <group ref={group} {...props} position={[0, hPositionM(2.3956), wPositionMP(0) - (isAttached ? 0.125 : 0.073)]} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane.geometry}
          material={nodes.Plane.material}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.15, 1, lScale(1.8)]}
        >
          <CanopyMaterial/>
        </mesh>
      </group>
      {totalWidthInches > 240 && (
        <group ref={group} {...props} position={[0, hPositionM(2.3956), -wPositionMP(0) + 0.073]} dispose={null}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane.geometry}
            material={nodes.Plane.material}
            rotation={[0, Math.PI / 2, 0]}
            scale={[0.15, 1, lScale(1.8)]}
          >
            <CanopyMaterial/>
          </mesh>
        </group>
      )}
    </>
  );
}

useGLTF.preload('/3d-models/canopy-cover.glb')
