import { firestore } from '../index';
import {
  doc,
  addDoc,
  getDoc,
  updateDoc,
  UpdateData,
  collection,
  onSnapshot
} from 'firebase/firestore';

import { createTimestamps } from './utilities/timestamps';
import { transformConfiguration } from './helpers/configuration';

import { PergolaConfiguration, PergolaConfigurationData, PergolaConfigurationUpdateData } from './types/configuration';
import { Collections } from './constants/collections';

export const createConfiguration = async (configurationData: PergolaConfigurationData) => {
  const docRef = await addDoc(
    collection(firestore, Collections.CONFIGURATIONS), {
      ...configurationData,
      ...createTimestamps()
    }
  );

  return docRef.id;
};

export const getConfiguration = async (id: string): Promise<PergolaConfiguration | null> => {
  const querySnapshot = await getDoc(
    doc(firestore, Collections.CONFIGURATIONS, id)
  );

  if (!querySnapshot.exists) {
    return null;
  }

  return transformConfiguration(querySnapshot);
};

export const updateConfiguration = async (id: string, data: UpdateData<PergolaConfigurationUpdateData>) => {
  await updateDoc(
    doc(firestore, Collections.CONFIGURATIONS, id), {
      ...data,
      updatedAt: createTimestamps().updatedAt
    }
  );
};

export const onConfigurationSnapshot = (id: string, callback: (changes: PergolaConfiguration | null) => void): () => void =>
  onSnapshot(
    doc(firestore, Collections.CONFIGURATIONS, id),
    (doc) => {
      callback(transformConfiguration(doc));
    });

export const liveConfiguration = (id: string, callback: (data: PergolaConfigurationUpdateData) => void) => {
  return onConfigurationSnapshot(id, (data) => {
    if (!data) {
      return;
    }

    callback({
      design: data.design,
      dimensions: data.dimensions,
      canopy: data.canopy,
      beams: data.beams,
      collaborative: data.collaborative,
      updatedBy: data.updatedBy,
    });
  });
};
