import React, { FC } from 'react';
import { usePergola } from '../../data-stores/pergola';
import { StructureColor } from '../../configurations/colors';

export const HardwareMaterial: FC = () => {
  const structureColor = usePergola(s => s.design.color);

  return (
    <meshStandardMaterial
      metalness={structureColor === StructureColor.HAMPTON_WHITE ? 0.6 : 0.5}
      roughness={structureColor === StructureColor.HAMPTON_WHITE ? 0.5 : 0.7}
      color={structureColor === StructureColor.HAMPTON_WHITE ? 'white' : '#212121'}
    />
  );
}
