import create from 'zustand';

export interface BeamsConfigurationStore {
  minBeams: number;
  maxBeams: number;
  beamsStep: number;
  distanceBetweenBeams: number;
  setBeamsConfig: (config: Omit<BeamsConfigurationStore, 'setBeamsConfig'>) => void;
}

export const useBeamsConfiguration = create<BeamsConfigurationStore>(set => ({
  minBeams: 3,
  maxBeams: 70,
  beamsStep: 2,
  distanceBetweenBeams: 20,
  setBeamsConfig: (config) => set(config)
}));
