import React, { FC, useEffect, useState } from 'react';
import notice from '../../assets/notice.svg';
import close from '../../assets/close.svg';
import { usePergola } from '../../data-stores/pergola';

export const NoticeBox: FC = () => {
  const lengthFeet = usePergola(state => state.dimensions.length.feet);
  const widthFeet = usePergola(state => state.dimensions.width.feet);

  const [shotWidthNotice, setShowWidthNotice] = useState<boolean>(false);

  const [showNotice, setShowNotice] = useState<boolean>(false);
  const [noticeBreakpoints, setNoticeBreakpoints] = useState<{ [key: number]: { [key: string]: boolean } }>({
    20: {
      length: false,
      width: false
    },
    40: {
      length: false,
      width: false
    },
    60: {
      length: false
    }
  });

  useEffect(() => {
    if (lengthFeet >= 20 && lengthFeet < 40 && !noticeBreakpoints[20].length) {
      setNoticeBreakpoints(state => ({
        ...state,
        20: {
          ...state[20],
          length: true
        }
      }));

      setShowNotice(true);
    }

    if (lengthFeet >= 40 && lengthFeet < 60 && !noticeBreakpoints[40].length) {
      setNoticeBreakpoints(state => ({
        ...state,
        40: {
          ...state[40],
          length: true
        }
      }));

      setShowNotice(true);
    }

    if (lengthFeet >= 60 && !noticeBreakpoints[60].length) {
      setNoticeBreakpoints(state => ({
        ...state,
        60: {
          length: true
        }
      }));

      setShowNotice(true);
    }
  }, [lengthFeet, noticeBreakpoints]);

  useEffect(() => {
    if (widthFeet >= 20 && widthFeet < 40 && !noticeBreakpoints[20].width) {
      setNoticeBreakpoints(state => ({
        ...state,
        20: {
          ...state[20],
          width: true
        }
      }));

      setShowWidthNotice(true);
    }

    if (widthFeet >= 40 && widthFeet < 60 && !noticeBreakpoints[40].width) {
      setNoticeBreakpoints(state => ({
        ...state,
        40: {
          ...state[40],
          width: true
        }
      }));

      setShowWidthNotice(true);
    }
  }, [noticeBreakpoints, widthFeet]);

  return (
    <div className="hidden lg:block notice_boxes_container">
      <div className="px-6">
        <div
          className={`notice_container px-2 py-4 ${showNotice && shotWidthNotice ? 'mt-8' : 'my-4'} ${showNotice ? 'grid' : 'hidden'}`}>
          <img
            src={notice}
            alt="notice icon"
            style={{
              marginTop: '5px'
            }}
          />

          <div>
            <div className="text-sm font-semibold text-green">Please Notice</div>
            <div className="text-sm font-light text-text">
              For your safety, length
              of {lengthFeet < 40 ? '20' : lengthFeet >= 40 && lengthFeet < 60 ? '40' : '60'}’ and
              above comes
              with 2 additional posts.
            </div>
          </div>

          <img
            className="cursor-pointer"
            src={close}
            alt="close icon"
            style={{
              marginTop: '3px'
            }}
            onClick={() => setShowNotice(false)}
          />
        </div>
      </div>


      <div className="px-6">
        <div className={`notice_container px-2 py-4 my-4 ${shotWidthNotice ? 'grid' : 'hidden'}`}>
          <img
            src={notice}
            alt="notice icon"
            style={{
              marginTop: '5px'
            }}
          />

          <div>
            <div className="text-sm font-semibold text-green">Please Notice</div>
            <div className="text-sm font-light text-text">
              For your safety, width of {widthFeet < 40 ? '20' : '40'}’ and above comes with
              2 additional posts.
            </div>
          </div>

          <img
            className="cursor-pointer"
            src={close}
            alt="close icon"
            style={{
              marginTop: '3px'
            }}
            onClick={() => setShowWidthNotice(false)}
          />
        </div>
      </div>
    </div>
  );
};