import create from 'zustand';

export interface UserStore {
  user: any;
  setUser: (user: any) => void;
}

export const useUser = create<UserStore>(set => ({
  user: null,
  setUser: (user: any) => set((state: UserStore) => ({
    ...state,
    user
  }))
}));
