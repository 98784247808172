import React, { FC, useRef, useState } from 'react';
import { styled, keyframes } from '@stitches/react';
import { blackA } from '@radix-ui/colors';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import close from '../../assets/close.svg';
import notice from '../../assets/notice.svg';
import dimensionsMain from '../../assets/dimensionsMain.svg';
import dHeight from '../../assets/dHeight.svg';
import dWidth from '../../assets/dWidth.svg';
import dLength from '../../assets/dLength.svg';
import tip from '../../assets/tip.svg';
import { useMediaQuery } from '../../contexts/MediaQueryContext';
import { DimensionsModal } from '../../content/content';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' }
});

const StyledOverlay = styled(AlertDialogPrimitive.Overlay, {
  backgroundColor: blackA.blackA9,
  position: 'fixed',
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  }
});

const StyledContent = styled(AlertDialogPrimitive.Content, {
  backgroundColor: 'white',
  borderRadius: '10px',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  maxWidth: '769px',
  maxHeight: '85vh',
  overflow: 'auto',
  zIndex: 9999,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  },
  '&:focus': { outline: 'none' }
});

const AlertDialog = AlertDialogPrimitive.Root;
const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
const AlertDialogContent = StyledContent;
const AlertDialogCancel = AlertDialogPrimitive.Cancel;

const enum DimensionsModalTabs {
  HEIGHT = 'height',
  LENGTH = 'length',
  WIDTH = 'width'
}

export const DimensionsPopup: FC = (): JSX.Element => {
  const mediaQueryContext = useMediaQuery();

  const [activeTab, setActiveTab] = useState<DimensionsModalTabs>(DimensionsModalTabs.HEIGHT);

  const closeDimensionsRef = useRef<any>(null);

  const closeDimensionsPopup = () => {
    if (closeDimensionsRef.current) {
      closeDimensionsRef.current.click();
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        {
          mediaQueryContext.onMobile
            ? (
              <button className="home_arrow_mobile_modal_trigger">
                <div className="underline font-light text-sm cursor-pointer hover_primary">
                  Dimensions Guide {`>`}
                </div>
              </button>
            )
            : (
              <button className="home_arrow_mobile_modal_trigger">
                <div className="underline font-light text-sm cursor-pointer hover_primary">
                  How to measure correctly? {`>`}
                </div>
              </button>
            )
        }
      </AlertDialogTrigger>
      <AlertDialogPrimitive.Portal>
        <StyledOverlay onClick={closeDimensionsPopup}/>
        <AlertDialogContent className="alert-dialog_fullScreen">
          <div
            className="sticky top-0 bg-white grid grid-cols-home-modal-header lg:grid-cols-3 items-center py-4 px-4 custom_modal_title_border">
            <div/>

            <div className="justify-self-center font-bold lg:font-semibold text-sm lg:text-base text-text">
              {DimensionsModal.HEADING}
            </div>

            <AlertDialogCancel asChild ref={closeDimensionsRef}>
              <img
                className="justify-self-end cursor-pointer"
                src={close}
                alt="close canopy modal"
              />
            </AlertDialogCancel>
          </div>

          <div className="px-4 lg:px-16 pt-8 dimensions_modal_container">
            <div className="mb-4">
              <div className="text-extralight text-sm font-light">
                {/*{DimensionsModal.MAIN_PARAGRAPH}*/}
                {DimensionsModal.SECOND_PARAGRAPH}
              </div>
            </div>
            {/*<div className="text-extralight text-sm font-light mb-4">*/}
            {/*  {DimensionsModal.SECOND_PARAGRAPH}*/}
            {/*</div>*/}
            <div className="mb-8">
              <ul className="flex bullet-list item-props">
                <li className="text-text text-sm font-semibold mb-2">{DimensionsModal.EXTERNAL_TITLE}</li>
              </ul>
              <div className="text-extralight text-sm font-light">
                {DimensionsModal.EXTERNAL_CONTENT}
              </div>
              <ul className="flex bullet-list item-props">
                <li className="text-text text-sm font-semibold my-2">{DimensionsModal.INTERNAL_TITLE}</li>
              </ul>
              <div className="text-extralight text-sm font-light">
                {DimensionsModal.INTERNAL_CONTENT}
              </div>
              <div className="hidden lg:grid notice_dimensions_modal_container px-4 py-4 mt-8 mb-4">
                <img
                  src={notice}
                  alt="notice icon"
                />
                <div>
                  <div className="text-sm font-semibold text-green mb-1">{DimensionsModal.NOTICE_TITLE}</div>
                  <div className="text-xs font-light text-text">
                    {DimensionsModal.NOTICE_CONTENT}
                  </div>
                </div>
              </div>

              <div className="lg:hidden notice_mobile_modal_container px-4 py-4 mt-4 mb-8">
                <div className="flex items-end mb-4">
                  <img
                    src={notice}
                    alt="notice icon"
                    style={{
                      marginTop: '5px',
                      marginRight: '5px'
                    }}
                  />
                  <div className="text-sm font-semibold text-green">{DimensionsModal.NOTICE_TITLE}</div>
                </div>

                <div className="text-sm font-light text-text">
                  {DimensionsModal.NOTICE_CONTENT}
                </div>
              </div>

              <div className="custom_modal_pergola_dimensions_container">
                <img src={dimensionsMain} alt="pergola dimensions map"/>
              </div>

              <div className="flex justify-around pt-8 mb-8">
                <div
                  className={activeTab === DimensionsModalTabs.HEIGHT ? 'custom_dimensions_modal_tab_button_active' : 'custom_dimensions_modal_tab_button hover_primary'}
                  onClick={() => setActiveTab(DimensionsModalTabs.HEIGHT)}
                >
                  {DimensionsModal.HEIGHT_TAB_TITLE}
                  <div
                    style={{
                      display: activeTab === 'height' ? 'block' : 'none',
                      backgroundColor: '#71903E',
                      position: 'absolute',
                      width: '83px',
                      height: '4px',
                      top: '98%',
                      opacity: activeTab === 'height' ? 1 : 0.5,
                      borderRadius: '9px',
                      zIndex: 1
                    }}
                  />
                </div>
                <div
                  className={activeTab === DimensionsModalTabs.LENGTH ? 'custom_dimensions_modal_tab_button_active' : 'custom_dimensions_modal_tab_button hover_primary'}
                  onClick={() => setActiveTab(DimensionsModalTabs.LENGTH)}
                >
                  {DimensionsModal.LENGTH_TAB_TITLE}
                  <div
                    style={{
                      display: activeTab === 'length' ? 'block' : 'none',
                      backgroundColor: '#71903E',
                      position: 'absolute',
                      width: '83px',
                      height: '4px',
                      top: '98%',
                      opacity: activeTab === 'length' ? 1 : 0.5,
                      borderRadius: '9px',
                      zIndex: 1
                    }}
                  />
                </div>
                <div
                  className={activeTab === DimensionsModalTabs.WIDTH ? 'custom_dimensions_modal_tab_button_active' : 'custom_dimensions_modal_tab_button hover_primary'}
                  onClick={() => setActiveTab(DimensionsModalTabs.WIDTH)}
                >
                  {DimensionsModal.WIDTH_TAB_TITLE}
                  <div
                    style={{
                      display: activeTab === 'width' ? 'block' : 'none',
                      backgroundColor: '#71903E',
                      position: 'absolute',
                      width: '83px',
                      height: '4px',
                      top: '98%',
                      opacity: activeTab === 'width' ? 1 : 0.5,
                      borderRadius: '9px',
                      zIndex: 1
                    }}
                  />
                </div>
              </div>


              {activeTab === DimensionsModalTabs.HEIGHT && (
                <div className="flex flex-col lg:flex-row flex-col-reverse min-h-full justify-end">
                  <img
                    className="hidden lg:block"
                    src={dHeight}
                    alt=""
                  />

                  <img
                    className="lg:hidden"
                    src={dHeight}
                    alt=""
                  />
                  <div className="mb-4 lg:mb-0 text-text text-xs font-light ml-4">
                    <p>{DimensionsModal.HEIGHT_TAB_CONTENT1}</p><br/>
                    <p> {DimensionsModal.HEIGHT_TAB_CONTENT2}</p><br/>
                    {/*<p>{DimensionsModal.HEIGHT_TAB_CONTENT3}</p>*/}
                  </div>
                </div>
              )}

              {activeTab === DimensionsModalTabs.LENGTH && (
                <div className="flex flex-col lg:flex-row flex-col-reverse min-h-full justify-end">
                  <img
                    className="hidden lg:block"
                    src={dLength}
                    alt=""
                  />

                  <img
                    className="lg:hidden"
                    src={dLength}
                    alt=""
                  />
                  <div className="mb-4 lg:mb-0 text-text text-xs font-light ml-4">
                    <p>{DimensionsModal.LENGTH_TAB_CONTENT1}</p><br/>
                    {/*<p>{DimensionsModal.LENGTH_TAB_CONTENT2}</p>*/}
                  </div>
                </div>
              )}

              {activeTab === DimensionsModalTabs.WIDTH && (
                <div className="flex flex-col lg:flex-row flex-col-reverse min-h-full justify-end">
                  <img
                    className="hidden lg:block"
                    src={dWidth}
                    alt=""
                  />

                  <img
                    className="lg:hidden"
                    src={dWidth}
                    alt=""
                  />
                  <div className="mb-4 lg:mb-0 text-text text-xs font-light ml-4">
                    <p>{DimensionsModal.WIDTH_TAB_CONTENT1}</p>
                  </div>
                </div>
              )}

              {
                activeTab === DimensionsModalTabs.HEIGHT && (
                  <>
                    <div className="hidden lg:grid grid notice_dimensions_modal_container px-4 py-4 my-8">
                      <img
                        src={tip}
                        alt="tip icon"
                      />

                      <div>
                        <div className="text-sm font-semibold text-green mb-1">Pro Tip</div>
                        <div className="text-xs font-light text-text">
                          {DimensionsModal.HEIGHT_PRO_TIP}
                        </div>
                      </div>
                    </div>

                    <div className="lg:hidden notice_mobile_modal_container px-4 py-4 mt-4 mb-8">
                      <div className="flex items-end mb-4">
                        <img
                          src={tip}
                          alt="tip icon"
                          style={{
                            marginTop: '5px',
                            marginRight: '5px'
                          }}
                        />
                        <div className="text-sm font-semibold text-green mb-1">Pro Tip</div>
                      </div>

                      <div className="text-sm font-light text-text">
                        {DimensionsModal.HEIGHT_PRO_TIP}
                      </div>
                    </div>
                  </>
                )
              }

              {
                activeTab === DimensionsModalTabs.LENGTH && (
                  <>
                    <div className="hidden lg:grid grid notice_dimensions_modal_container px-4 py-4 my-8">
                      <img
                        src={tip}
                        alt="tip icon"
                      />

                      <div>
                        <div className="text-sm font-semibold text-green mb-1">Pro Tip</div>
                        <div className="text-xs font-light text-text">
                          {DimensionsModal.LENGTH_PRO_TIP}
                        </div>
                      </div>
                    </div>

                    <div className="lg:hidden notice_mobile_modal_container px-4 py-4 mt-4 mb-8">
                      <div className="flex items-end mb-4">
                        <img
                          src={tip}
                          alt="tip icon"
                          style={{
                            marginTop: '5px',
                            marginRight: '5px'
                          }}
                        />
                        <div className="text-sm font-semibold text-green mb-1">Pro Tip</div>
                      </div>

                      <div className="text-sm font-light text-text">
                        {DimensionsModal.LENGTH_PRO_TIP}
                      </div>
                    </div>
                  </>
                )
              }
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialogPrimitive.Portal>
    </AlertDialog>
  );
};
