import React, { FC, useMemo, useRef, useState } from 'react';
import { styled, keyframes } from '@stitches/react';
import { blackA } from '@radix-ui/colors';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import { CanvasContainer } from '../3d/CanvasContainer';
import { PergolaDesign, usePergola } from '../../data-stores/pergola';

import { roundDecimalNumber } from '../../price-calculations/round-decimal-number';
import { getInternalHeight, getInternalLength, getInternalWidth } from '../../internal-dimensions-calculations';

import { createCustomPergola } from '../../firebase/functions/shopify-request';
import { createCheckout } from '../../mutations';
import { CanopyColor, StructureColor } from '../../configurations/colors';
import { ControlTabs } from '../../configurations/tabs';
import { useErrors } from '../../data-stores/errors';

import { ShippingBox } from '../icons/ShippingBox';
import { ShareModal } from './ShareModal';
import { ArModal } from './ArModal';

import hamptonWhite from '../../assets/hampton-white.png';
import charcoalGray from '../../assets/charcoal-gray.png';
import brazilianIpe from '../../assets/brazilian-ipe.png';
import tuscanCedar from '../../assets/tuscan-cedar.png';
import doveWhite from '../../assets/dove-white.png';
import cappuccino from '../../assets/cappuccino.png';
import sterlingSilver from '../../assets/sterling-silver.png';
import desertSand from '../../assets/desert-sand.png';
import close from '../../assets/close.svg';
import phone from '../../assets/phone.svg';
import { reportEvent } from '../../utilities/gtag/report-event';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' }
});

const StyledOverlay = styled(AlertDialogPrimitive.Overlay, {
  backgroundColor: blackA.blackA9,
  position: 'fixed',
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  }
});

const StyledContent = styled(AlertDialogPrimitive.Content, {
  backgroundColor: 'white',
  borderRadius: '10px',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: '933px',
  maxHeight: '85vh',
  overflow: 'hidden',
  zIndex: 9999,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  },
  '&:focus': { outline: 'none' }
});

const AlertDialog = AlertDialogPrimitive.Root;
const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
const AlertDialogContent = StyledContent;
const AlertDialogCancel = AlertDialogPrimitive.Cancel;

export const CartPopup: FC<{ activeTab: ControlTabs }> = ({ activeTab }): JSX.Element => {
  const state = usePergola(state => state);
  const structureColor = usePergola(state => state.design.color);
  const hasCanopy = usePergola(state => state.canopy.hasCanopy);
  const canopyColor = usePergola(state => state.canopy.color);

  const buildNumber = usePergola(state => state.buildNumber);

  const price = usePergola(state => state.price);
  const discount = usePergola(state => state.discount);
  const pergolaStructure = usePergola(state => state.design.pergola);

  const dimensionsError = useErrors(state => state.dimensionsError);
  const beamsError = useErrors(state => state.beamsError);

  const [addToCartLoading, setAddToCartLoading] = useState<boolean>(false);
  const [checkoutLoading, setCheckoutLoading] = useState<boolean>(false);
  const [termsChecked, setTermsChecked] = useState<boolean>(false);

  const checkboxRef = useRef<HTMLInputElement>(null);

  const closeRef = useRef<any>(null);

  const internalDimensions = useMemo(() => {
    return {
      height: {
        ...getInternalHeight(state.dimensions.height.feet, state.dimensions.height.inches)
      },
      length: {
        ...getInternalLength(state.dimensions.length.feet, state.dimensions.length.inches)
      },
      width: {
        ...getInternalWidth(state.dimensions.width.feet, state.dimensions.width.inches)
      }
    };
  }, [state.dimensions.height.feet, state.dimensions.height.inches, state.dimensions.length.feet, state.dimensions.length.inches, state.dimensions.width.feet, state.dimensions.width.inches]);

  const finalObject = {
    design: {
      pergola: state.design.pergola,
      color: state.design.color
    },
    dimensions: state.dimensions,
    canopy: {
      color: state.canopy.hasCanopy ? state.canopy.color : '-',
      hasCanopy: state.canopy.hasCanopy
    },
    beams: state.beams,
    price: state.price - state.discount,
    buildNumber: state.buildNumber,
    isPingCall: false
  };

  const addToShopifyCart = async () => {
    setAddToCartLoading(true);

    // GTAG Event Report
    reportEvent({
      event: 'ADD_TO_CART',
      design: state.design.pergola,
      price: state.price,
      discount: state.discount
    });

    try {
      const createProductResponse = await createCustomPergola(finalObject);
      setAddToCartLoading(false);
      const variantId = createProductResponse.data.product.variants[0].id;
      console.log(variantId);
      window.location.href = `https://paragon-outdoor.com/cart/add?id=${variantId}&quantity=1`;
    } catch (e: any) {
      setAddToCartLoading(false);
      throw new Error(`Create Cart Error: ${e}`);
    }
  };

  const createShopifyCheckout = async () => {
    setCheckoutLoading(true);

    // GTAG Event Report
    reportEvent({
      event: 'CHECKOUT',
      design: state.design.pergola,
      price: state.price,
      discount: state.discount
    });

    try {
      const createProductResponse = await createCustomPergola(finalObject);
      const variantId = createProductResponse.data.variantId;
      console.log('Create Product Response: variantId', variantId);
      // convert to base64 in the browser
      const checkoutResponse = await createCheckout(variantId);
      console.log('Create Checkout Response', checkoutResponse);
      const redirectUrl = checkoutResponse.data.checkoutCreate.checkout.webUrl;
      console.log('URL: ', redirectUrl);
      setCheckoutLoading(false);
      window.location.href = redirectUrl;
    } catch (e: any) {
      setCheckoutLoading(false);
      throw new Error(`Create Checkout Error: ${e}`);
    }
  };

  const pingCloudFunction = async () => {
    try {
      await createCustomPergola({ ...finalObject, isPingCall: true });
    } catch (e: any) {
      throw new Error(`Create Cart Error: ${e}`);
    }
  };

  const closePopup = () => {
    if (closeRef.current) {
      closeRef.current.click();
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <button
          className={`cart_modal_trigger_button font-medium text-lg ${(!dimensionsError && !beamsError) ? 'hover_brightness cursor-pointer' : 'cursor-not-allowed'}`}
          style={{
            backgroundColor: (activeTab === ControlTabs.CANOPY && (hasCanopy && canopyColor === ''))
            || dimensionsError
            || beamsError
              ? '#B8C79F'
              : '#71903E'
          }}
          disabled={dimensionsError || beamsError}
          onClick={() => pingCloudFunction()}
        >
          View Summary
        </button>
      </AlertDialogTrigger>
      <StyledOverlay onClick={closePopup}/>
      <AlertDialogContent>
        <div className="sticky top-0 bg-white grid grid-cols-header py-6 px-4 px-4 bg-background z-10">
          <div/>

          <div className="justify-self-center font-normal text-22 text-text text-center">
            Your pergola is looking <span className="text-green font-bold text-2">great!</span>
          </div>

          <AlertDialogCancel
            asChild
            ref={closeRef}
          >
            <img
              className="cursor-pointer mr-4 justify-self-end self-center"
              src={close}
              alt="close canopy modal"
            />
          </AlertDialogCancel>
        </div>

        <div className="px-8 pt-2 cart_modal_container">
          <div className="mb-8">
            <div className="cart_modal_grid">
              <div className="cart_modal_canvas">
                <CanvasContainer/>
              </div>

              <div className="cart_modal_summary px-6">
                <div className="flex items-center py-4">
                  <div className="text-text text-sm font-normal">Build Number:</div>
                  <div
                    style={{
                      width: 130,
                      height: 27,
                      borderRadius: '50%',
                      marginLeft: '3rem'
                    }}
                  >
                    {buildNumber}
                  </div>
                </div>

                <div className="flex items-center py-4">
                  <div className="text-text text-sm font-normal">Structure Color:</div>
                  <div
                    style={{
                      width: 27,
                      height: 27,
                      borderRadius: '50%',
                      marginLeft: '3rem'
                    }}
                  >
                    {structureColor === StructureColor.HAMPTON_WHITE && (
                      <img className="rounded-full" src={hamptonWhite} alt="hampton white color"/>
                    )}
                    {structureColor === StructureColor.CHARCOAL_GRAY && (
                      <img className="rounded-full" src={charcoalGray} alt="charcoal gray color"/>
                    )}
                    {structureColor === StructureColor.BRAZILIAN_IPE && (
                      <img className="rounded-full" src={brazilianIpe} alt="brazilian ipe color"/>
                    )}
                    {structureColor === StructureColor.TUSCAN_CEDAR && (
                      <img className="rounded-full" src={tuscanCedar} alt="tuscan cedar color"/>
                    )}
                  </div>
                </div>

                <div className="flex items-center pb-4 checkout_modal_summary_border">
                  <div className="text-text text-sm font-normal">Canopy Color:</div>
                  {
                    !state.canopy.hasCanopy
                      ? (
                        <div className="text-text text-xs font-medium ml-16">-</div>
                      )
                      : (
                        <div
                          style={{
                            width: 27,
                            height: 27,
                            borderRadius: '50%',
                            marginLeft: '3.5rem'
                          }}
                        >
                          {canopyColor === CanopyColor.DOVE_WHITE && (
                            <img className="rounded-full" src={doveWhite} alt="dove white color"/>
                          )}
                          {canopyColor === CanopyColor.CAPPUCCINO && (
                            <img className="rounded-full" src={cappuccino} alt="cappuccino color"/>
                          )}
                          {canopyColor === CanopyColor.STERLING_SILVER && (
                            <img className="rounded-full" src={sterlingSilver} alt="sterling silver color"/>
                          )}
                          {canopyColor === CanopyColor.DESERT_SAND && (
                            <img className="rounded-full" src={desertSand} alt="desert sand color"/>
                          )}
                        </div>
                      )
                  }
                </div>

                <div className="flex justify-between items-center pt-4">
                  <div className="text-text text-sm font-normal">External Dimensions:</div>
                  <div className="text-text text-sm font-bold">
                    {state.dimensions.height.feet}′{state.dimensions.height.inches !== 0 && `${state.dimensions.height.inches}′′`}{' '}H{' '}
                    <span>x</span> {state.dimensions.length.feet}′{state.dimensions.length.inches !== 0 && `${state.dimensions.length.inches}′′`}{' '}L{' '}
                    <span>x</span> {state.dimensions.width.feet}′{state.dimensions.width.inches !== 0 && `${state.dimensions.width.inches}′′`}{' '}W{' '}
                  </div>
                </div>

                <div className="flex justify-between items-center checkout_modal_summary_border py-4">
                  <div className="text-text text-sm font-normal">Internal Dimensions:</div>
                  <div className="text-text text-sm font-bold">
                    {internalDimensions.height.feet}′{internalDimensions.height.inches !== 0 && `${internalDimensions.height.inches}′′`}{' '}H{' '}
                    <span>x</span> {internalDimensions.length.feet}′{internalDimensions.length.inches !== 0 && `${internalDimensions.length.inches}′′`}{' '}L{' '}
                    <span>x</span> {internalDimensions.width.feet}′{internalDimensions.width.inches !== 0 && `${internalDimensions.width.inches}′′`}{' '}W{' '}
                  </div>
                </div>

                <div className="flex items-center pt-4">
                  <div className="text-text text-sm font-normal">Beam Quantity:</div>
                  <div className="text-text text-base font-bold ml-7">
                    {state.beams}
                  </div>
                </div>

                <div className="flex items-center checkout_modal_summary_border py-4">
                  <div className="text-text text-sm font-normal">Approx. Net Weight:</div>
                  <div className="text-text text-base font-bold ml-12">
                    {state.weight} lb
                  </div>
                </div>
              </div>

              <div className="cart_grid_floating_buttons_container">
                <div className="floating_buttons_container_cart_modal flex items-center">
                  <ShareModal/>

                  <div className="ml-4">
                    <ArModal/>
                  </div>

                  <a
                    href="tel:+18777824482"
                    className="floating_button_container ml-4"
                  >
                    <div className="floating_button_help_text text-green text-sm font-bold">
                      <img
                        className="floating_button_image"
                        src={phone}
                        alt="share link"
                      />
                    </div>

                    <div className="floating_button_text items-center text-text text-xs font-normal phone">
                      <div className="ml-2">877-782-4482</div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="cart_grid_shipping_container flex items-center pt-2 px-6">
                <ShippingBox/>
                <div className="text-text text-xs font-light text-green ml-2">Expected Shipping:</div>
                <div className="text-text text-sm font-medium text-green ml-2">
                  {
                    structureColor === StructureColor.HAMPTON_WHITE
                      ? '90 Days'
                      : '5 Weeks'
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed w-full bottom-0">
          <div className="bg-background px-12">
            <label
              htmlFor="terms"
              className="accessible_label h-14 flex items-center text-xs font-light ml-2 text-text cursor-pointer">
              <div className="custom_checkbox_container">
                <input
                  id="terms"
                  name="terms"
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      if (checkboxRef.current) {
                        checkboxRef.current.className = 'custom_checkmark';
                      }

                      setTermsChecked(true);
                    } else {
                      setTermsChecked(false);
                    }
                  }}
                />

                <span
                  tabIndex={1}
                  aria-label={'Accept terms and conditions'}
                  className="custom_checkmark"
                  ref={checkboxRef}
                />
              </div>

              <div>
                By clicking here, I approve the custom product dimensions as well as the&nbsp;
                <a
                  href="https://paragon-outdoor.com/pages/terms-and-conditions"
                  className="underline text-text"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms and conditions policy
                </a>.
              </div>
            </label>
          </div>

          <div className="bg-text h-24 cart_modal_sticky_bottom flex justify-between px-12 items-center">
            <div className="text-white">
              {
                discount > 0
                  ? (
                    <div className="flex items-center">
                      <div className="font-bold text-xl">
                        $ {
                        !dimensionsError && !beamsError
                          ? roundDecimalNumber(price - discount).toFixed(2)
                          : '-'
                      }
                      </div>

                      {
                        discount > 0 && (
                          <div className="font-normal text-lg ml-4 line-through">
                            $ {
                            !dimensionsError && !beamsError
                              ? roundDecimalNumber(price).toFixed(2)
                              : '-'
                          }
                          </div>
                        )
                      }
                    </div>
                  )
                  : (
                    <div className="font-bold text-xl">
                      $ {
                      !dimensionsError && !beamsError
                        ? roundDecimalNumber(price).toFixed(2)
                        : '-'
                    }
                    </div>
                  )
              }
              <div className="font-light text-xs">
                {pergolaStructure === PergolaDesign.FREE_STANDING ? 'FREE STANDING' : 'ATTACHED'} PERGOLA
              </div>
            </div>

            <div className="flex justify-center items-center">
              <button
                className={`cursor-pointer add_to_cart_modal_button text-green bg-white text-base font-medium py-2 px-16 mr-4 ${(!termsChecked || addToCartLoading || checkoutLoading) && 'cursor-not-allowed opacity-70'}`}
                onClick={async () => {
                  if (!termsChecked || addToCartLoading || checkoutLoading) {
                    if (checkboxRef.current) {
                      checkboxRef.current.className = `custom_checkmark on_error`;

                      setTimeout(() => {
                        if (checkboxRef.current) {
                          checkboxRef.current.className = `custom_checkmark button-interaction__checkout on_error`;
                        }
                      }, 1);
                    }

                    return;
                  }

                  await addToShopifyCart();
                }}
              >
                {addToCartLoading ? 'Loading...' : 'Add to Cart'}
              </button>
              <button
                className={`bg-green text-white checkout_modal_button py-2 px-8 cursor-pointer text-base font-medium ${(!termsChecked || checkoutLoading || addToCartLoading) && 'cursor-not-allowed opacity-70'}`}
                onClick={async () => {
                  if (!termsChecked || checkoutLoading || addToCartLoading) {
                    if (checkboxRef.current) {
                      checkboxRef.current.className = `custom_checkmark on_error`;

                      setTimeout(() => {
                        if (checkboxRef.current) {
                          checkboxRef.current.className = `custom_checkmark button-interaction__checkout on_error`;
                        }
                      }, 1);
                    }

                    return;
                  }

                  await createShopifyCheckout();
                }}
              >
                {checkoutLoading ? 'Loading...' : 'Proceed to Checkout'}
              </button>
            </div>
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};
