import React, { FC, useState } from 'react';
import { styled } from '@stitches/react';
import { blackA } from '@radix-ui/colors';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import { PergolaDesign, usePergola } from '../../data-stores/pergola';
import { useErrors } from '../../data-stores/errors';

import { StyleTabContent } from './StyleTabContent';
import { DimensionsTabContent } from './DimensionsTabContent';
import { CanopyTabContent } from './CanopyTabContent';
import { BeamsTabContent } from './BeamsTabContent';
import { CanopyPopup } from '../modals/CanopyModal';
import { BeamsPopup } from '../modals/BeamsModal';
import { DimensionsPopup } from '../modals/DimensionsModal';
import { CartPopup } from '../modals/CartModal';

import { ControlTabs } from '../../configurations/tabs';

import attention from '../../assets/attention.svg';
import questionMark from '../../assets/question-mark.svg';
import { roundDecimalNumber } from '../../price-calculations/round-decimal-number';

const Flex = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem',
  height: 84,
  backgroundColor: '#393B42',
  borderBottomRightRadius: 10,
  borderBottomLeftRadius: 10
});

const StyledTabs = styled(TabsPrimitive.Root, {
  justifySelf: 'end',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: `0 3px 10px 3px ${blackA.blackA4}`,
  height: '95vh',
  '@media screen and (max-height: 1079px)': {
    height: '93vh'
  },
  width: '504px',
  backgroundColor: '#fff',
  borderRadius: 10
});

const StyledList = styled(TabsPrimitive.List, {
  position: 'relative',
  display: 'flex',
  borderBottom: '0.1px solid #f5f5f5',
  alignItems: 'end',
  paddingTop: '0.5rem'
});

const StyledTrigger = styled(TabsPrimitive.Trigger, {
  position: 'relative',
  all: 'unset',
  fontFamily: 'inherit',
  backgroundColor: 'white',
  padding: '0 20px',
  height: 45,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 1,
  userSelect: 'none',
  '&:first-child': { borderTopLeftRadius: 6 },
  '&:last-child': { borderTopRightRadius: 6 },
  '&:focus': {
    opacity: 1
  }
});

const StyledContent = styled(TabsPrimitive.Content, {
  flexGrow: 1,
  padding: '0 20px 0 20px',
  backgroundColor: 'white',
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
  outline: 'none',
  '@media screen and (max-height: 700px)': {
    overflowY: 'auto'
  }
});

// Exports
export const Tabs = StyledTabs;
export const TabsList = StyledList;
export const TabsTrigger = StyledTrigger;
export const TabsContent = StyledContent;

export const TabsComponent: FC = (): JSX.Element => {
  const price = usePergola(state => state.price);
  const discount = usePergola(state => state.discount);
  const pergolaStructure = usePergola(state => state.design.pergola);
  const hasCanopy = usePergola(state => state.canopy.hasCanopy);
  const canopyColor = usePergola(state => state.canopy.color);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  // const [buttonAnimation, setButtonAnimation] = useState<boolean>(false);

  const dimensionsError = useErrors(state => state.dimensionsError);
  const beamsError = useErrors(state => state.beamsError);

  const [activeTab, setActiveTab] = useState<ControlTabs>(ControlTabs.STYLE);

  // useEffect(() => {
  //   if (buttonAnimation) {
  //     setTimeout(() => {
  //       setButtonAnimation(false);
  //     }, 2000);
  //   }
  // }, [buttonAnimation]);

  const nextTab = () => {
    if (activeTab === ControlTabs.STYLE) {
      setActiveTab(ControlTabs.DIMENSIONS);
    }

    if (activeTab === ControlTabs.DIMENSIONS) {
      setActiveTab(ControlTabs.CANOPY);
    }

    if (activeTab === ControlTabs.CANOPY) {
      setActiveTab(ControlTabs.BEAMS);
      setShowSummary(true);
    }

    if (activeTab === ControlTabs.BEAMS) {
      setShowSummary(true);
    }
  };

  return (
    <Tabs value={activeTab} onValueChange={(e) => setActiveTab(e as ControlTabs)}>
      <TabsList aria-label="Manage your pergola design">
        <TabsTrigger
          value={ControlTabs.STYLE}
          onClick={() => setActiveTab(ControlTabs.STYLE)}
          // onMouseOver={() => {
          //   if (dimensionsError || beamsError) {
          //     setButtonAnimation(true);
          //     return;
          //   }
          // }}
          disabled={dimensionsError || beamsError}
          css={{
            opacity: dimensionsError || beamsError ? 0.5 : 1,
            color: activeTab === ControlTabs.STYLE && !dimensionsError && !beamsError ? '#71903E' : '#393B42'
          }}
        >
          <span
            // className={`${!dimensionsError && !beamsError && 'tab_title'}`}
            className="tab_title"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            Style
          </span>
          <div
            style={{
              display: activeTab === ControlTabs.STYLE ? 'block' : 'none',
              backgroundColor: '#71903E',
              position: 'absolute',
              width: '99px',
              height: '4.85px',
              top: '98%',
              opacity: activeTab === ControlTabs.STYLE ? 1 : 0.5,
              borderRadius: '9px'
            }}
          />
        </TabsTrigger>

        <TabsTrigger
          // disabled={finalDesign.design.pergola === '' || finalDesign.design.color === '' || beamsError}
          value={ControlTabs.DIMENSIONS}
          onClick={() => {
            setActiveTab(ControlTabs.DIMENSIONS);
          }}
          // onMouseOver={() => {
          //   if (finalDesign.design.pergola === '' || finalDesign.design.color === '' || beamsError) {
          //     setButtonAnimation(true);
          //     return;
          //   }
          // }}
          css={{
            // opacity: (activeTab === ControlTabs.DIMENSIONS || (finalDesign.design.pergola !== '' && finalDesign.design.color !== '' && !beamsError)) ? 1 : 0.5,
            opacity: 1,
            color: activeTab === ControlTabs.DIMENSIONS ? '#71903E' : '#393B42'
          }}
        >
          <div
            style={{
              display: activeTab === ControlTabs.DIMENSIONS ? 'block' : 'none',
              backgroundColor: '#71903E',
              position: 'absolute',
              width: '99px',
              height: '4.85px',
              top: '98%',
              opacity: activeTab === ControlTabs.DIMENSIONS ? 1 : 0.5,
              borderRadius: '9px'
            }}
          />
          <span
            // className={
            //   `${
            //     !dimensionsError
            //     && !beamsError
            //     && finalDesign.design.pergola !== ''
            //     && finalDesign.design.color !== ''
            //     && 'tab_title'}`
            // }
            className="tab_title"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            Dimensions
          </span>
        </TabsTrigger>

        <TabsTrigger
          // disabled={
          //   finalDesign.dimensions.height.feet === 0
          //   || finalDesign.dimensions.length.feet === 0
          //   || finalDesign.dimensions.width.feet === 0
          //   || dimensionsError
          //   || beamsError
          // }
          value={ControlTabs.CANOPY}
          onClick={() => {
            setActiveTab(ControlTabs.CANOPY);
          }}
          // onMouseOver={() => {
          //   if (
          //     finalDesign.dimensions.height.feet === 0
          //     || finalDesign.dimensions.length.feet === 0
          //     || finalDesign.dimensions.width.feet === 0
          //     || dimensionsError
          //     || beamsError
          //   ) {
          //     setButtonAnimation(true);
          //     return;
          //   }
          // }}
          css={{
            // opacity: (
            //   activeTab === ControlTabs.CANOPY
            //   || (
            //     finalDesign.dimensions.height.feet !== 0
            //     && finalDesign.dimensions.length.feet !== 0
            //     && finalDesign.dimensions.width.feet !== 0
            //     && !dimensionsError
            //     && !beamsError
            //   ))
            //   ? 1 : 0.5,
            opacity: 1,
            color: activeTab === ControlTabs.CANOPY ? '#71903E' : '#393B42'
          }}
        >
          <div
            style={{
              display: activeTab === ControlTabs.CANOPY ? 'block' : 'none',
              backgroundColor: '#71903E',
              position: 'absolute',
              width: '99px',
              height: '4.85px',
              top: '98%',
              opacity: activeTab === ControlTabs.CANOPY ? 1 : 0.5,
              borderRadius: '9px'
            }}
          />
          <span
            // className={`${
            //   finalDesign.dimensions.height.feet !== 0
            //   && finalDesign.dimensions.length.feet !== 0
            //   && finalDesign.dimensions.width.feet !== 0
            //   && !dimensionsError
            //   && !beamsError
            //   && 'tab_title'
            // }`}
            className="tab_title"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            Canopy
          </span>
        </TabsTrigger>

        <TabsTrigger
          // disabled={finalDesign.canopy.hasCanopy === undefined || dimensionsError}
          value={ControlTabs.BEAMS}
          onClick={() => {
            setActiveTab(ControlTabs.BEAMS);
            setShowSummary(true);
          }}
          // onMouseOver={() => {
          //   if (finalDesign.canopy.hasCanopy === undefined || dimensionsError) {
          //     setButtonAnimation(true);
          //     return;
          //   }
          // }}
          css={{
            // opacity: activeTab === ControlTabs.BEAMS || (finalDesign.canopy.hasCanopy !== undefined && !dimensionsError) ? 1 : 0.5,
            opacity: 1,
            color: activeTab === ControlTabs.BEAMS ? '#71903E' : '#393B42'
          }}
        >
          <div
            style={{
              display: activeTab === ControlTabs.BEAMS ? 'block' : 'none',
              backgroundColor: '#71903E',
              position: 'absolute',
              width: '99px',
              height: '4.85px',
              top: '98%',
              opacity: activeTab === ControlTabs.BEAMS ? 1 : 0.5,
              borderRadius: '9px'
            }}
          />
          <span
            // className={`${
            //   finalDesign.canopy.hasCanopy !== undefined
            //   && !dimensionsError
            //   && 'tab_title'
            // }`}
            className="tab_title"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            Beams
          </span>
        </TabsTrigger>
      </TabsList>

      <TabsContent value="style">
        <StyleTabContent/>
      </TabsContent>

      <TabsContent value="dimensions">
        <DimensionsTabContent/>
      </TabsContent>

      <TabsContent value="canopy">
        <CanopyTabContent/>
      </TabsContent>

      <TabsContent value="beams">
        <BeamsTabContent/>
      </TabsContent>

      {
        activeTab === ControlTabs.CANOPY && (
          <div className="grid grid-cols-canopy-attention gap-5 items-center pl-6 pr-2 mb-8">
            <img src={attention} alt="warning icon"/>
            <div className="font-light text-sm text-text">
              The amount of canopies depends on your structure size, and cannot be customized.
            </div>
          </div>
        )
      }

      {
        activeTab === ControlTabs.DIMENSIONS
          ? (
            <div className="flex items-center px-7 desktop_popup_link_margin">
              <div className="custom_question_mark_container">
                <img src={questionMark} alt="question mark"/>
              </div>
              <DimensionsPopup/>
            </div>
          )
          : activeTab === ControlTabs.CANOPY
            ? (
              <div className="flex items-center px-7 desktop_popup_link_margin">
                <div className="custom_question_mark_container">
                  <img src={questionMark} alt="question mark"/>
                </div>
                <CanopyPopup/>
              </div>
            )
            : activeTab === ControlTabs.BEAMS
              ? (
                (
                  <div className="flex items-center px-7 desktop_popup_link_margin">
                    <div className="custom_question_mark_container">
                      <img src={questionMark} alt="question mark"/>
                    </div>
                    <BeamsPopup/>
                  </div>
                )
              )
              : null
      }

      <Flex>
        <div className="text-white">
          {
            discount > 0
              ? (
                <div className="flex items-center">
                  <div className="font-bold text-2xl">
                    $ {
                    !dimensionsError && !beamsError
                      ? roundDecimalNumber(price - discount).toFixed(2)
                      : '-'
                  }
                  </div>

                  {
                    discount > 0 && (
                      <div className="font-normal text-xl ml-2 line-through">
                        $ {
                        !dimensionsError && !beamsError
                          ? roundDecimalNumber(price).toFixed(2)
                          : '-'
                      }
                      </div>
                    )
                  }
                </div>
              )
              : (
                <div className="font-bold text-2xl">
                  $ {
                  !dimensionsError && !beamsError
                    ? roundDecimalNumber(price).toFixed(2)
                    : '-'
                }
                </div>
              )
          }
          <div
            className="font-light text-base">{pergolaStructure === PergolaDesign.FREE_STANDING ? 'Free Standing' : 'Attached'} pergola
          </div>
        </div>

        {
          showSummary
            ? (
              <CartPopup activeTab={activeTab}/>
            )
            : (
              <button
                // className={`cart_modal_trigger_button font-medium text-lg ${buttonAnimation ? 'button-interaction' : ''} ${(!dimensionsError && !beamsError) ? 'hover_brightness cursor-pointer' : 'cursor-not-allowed'}`}
                className={`cart_modal_trigger_button font-medium text-lg ${(!dimensionsError && !beamsError) ? 'hover_brightness cursor-pointer' : 'cursor-not-allowed'}`}
                style={{
                  backgroundColor: (activeTab === ControlTabs.CANOPY && (hasCanopy && canopyColor === ''))
                  || dimensionsError
                  || beamsError
                    ? '#B8C79F'
                    : '#71903E'
                }}
                onClick={() => {
                  // setButtonAnimation(false);
                  nextTab();
                }}
                disabled={
                  (activeTab === ControlTabs.CANOPY && (hasCanopy && canopyColor === ''))
                  || dimensionsError
                  || beamsError
                }
              >
                Next
              </button>
            )
        }
      </Flex>
    </Tabs>
  );
};
