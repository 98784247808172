import { PergolaDesign } from '../data-stores/pergola';

import { calculatePosts } from './calculate-posts';
import { calculateHeightSizePrice } from './calculate-height-size-price';
import { calculateRailings } from './calculate-railings';
import { calculateLengthSizePrice } from './calculate-length-size-price';
import { calculateBeamsSizePrice } from './calculate-beams-size-price';
import { calculateUConnectorsPrice } from './calculate-u-connectors';
import { calculateCanopyPrice } from './calculate-canopy-price';
import { calculateWoodenColorPrice } from './calculate-wooden-color-price';
import { calculateStructureTypePrice } from './calculate-structure-type-price';

import { CanopyColor, StructureColor } from '../configurations/colors';
import { PricingConfigurationData } from '../firebase/firestore/types/pricing-configuration';

interface CalculateTotalPriceProps {
  structureDesign: PergolaDesign;
  structureColor: StructureColor;
  height: {
    feet: number;
    inches: number;
  };
  length: {
    feet: number;
    inches: number;
  };
  width: {
    feet: number;
    inches: number;
  };
  hasCanopy: boolean;
  canopyColor: CanopyColor | '';
  beams: number;
  pricingConfiguration: PricingConfigurationData;
}

export const calculateTotalPrice = (props: CalculateTotalPriceProps): number => {
  const heightInches: number = props.height.feet * 12 + props.height.inches;
  const lengthInches: number = props.length.feet * 12 + props.length.inches;
  const widthInches: number = props.width.feet * 12 + props.width.inches;
  const beams: number = props.beams;

  const posts = calculatePosts(lengthInches, widthInches);

  const heightBasePrice: number = props.pricingConfiguration.heightBasePrice * posts;
  const heightSizePrice: number = calculateHeightSizePrice(
    heightInches,
    posts,
    props.pricingConfiguration.heightSizePriceBelow94,
    props.pricingConfiguration.heightSizePriceAbove94
  );
  const heightTotal: number = heightBasePrice + heightSizePrice;

  const railings = calculateRailings(posts);

  const lengthBasePrice: number = railings * props.pricingConfiguration.lengthBasePrice;
  const lengthSizePrice: number = calculateLengthSizePrice(
    lengthInches,
    railings,
    props.pricingConfiguration.lengthSizePriceBelow120,
    props.pricingConfiguration.lengthSizePriceBelow240,
    props.pricingConfiguration.lengthSizePriceAbove240
  );
  const lengthTotal: number = lengthBasePrice + lengthSizePrice;

  const beamsBasePrice: number = beams * props.pricingConfiguration.beamsBasePrice;
  const beamsSizePrice: number = calculateBeamsSizePrice(
    widthInches,
    beams,
    props.pricingConfiguration.widthSizePriceBelow120,
    props.pricingConfiguration.widthSizePriceBelow240,
    props.pricingConfiguration.widthSizePriceAbove240
  );
  const beamsTotal: number = beamsBasePrice + beamsSizePrice;

  const connectorsPrice: number = calculateUConnectorsPrice(
    widthInches,
    beams,
    props.pricingConfiguration.uConnectorsMultiplier
  );

  const canopyPrice: number = calculateCanopyPrice(
    props.hasCanopy,
    lengthInches,
    widthInches,
    props.pricingConfiguration.canopyMultiplier
  );

  const structurePrice: number = calculateStructureTypePrice(
    props.structureDesign,
    heightTotal,
    props.pricingConfiguration.attachedHeightPriceMultiplier,
    props.pricingConfiguration.attachedMultiplier
  );

  const woodenColorPrice: number = calculateWoodenColorPrice(
    props.structureColor,
    structurePrice,
    heightTotal,
    lengthTotal,
    beamsTotal,
    props.pricingConfiguration.woodenColorPricePercentMultiplier
  );

  return heightTotal
    + lengthTotal
    + beamsTotal
    + connectorsPrice
    + canopyPrice
    + structurePrice
    + woodenColorPrice;
};
