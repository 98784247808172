import { Scene } from 'three';
import { GLTFExporter } from 'three-stdlib';

export const exportGltfUrl = async (scene: Scene): Promise<string> => {
  const exporter = new GLTFExporter();

  const arraybuffer = await (() => new Promise((resolve, reject) => {
    exporter.parse(
      scene,
      (result) => {
        if (result instanceof Error) {
          return reject(result);
        }

        resolve(result);
      },
      {}
    );
  }))();

  // @ts-ignore
  const blob = new Blob([arraybuffer], { type: 'application/octet-stream' }, 'scene.glb');

  return `intent://arvr.google.com/scene-viewer/1.1?file=${URL.createObjectURL(blob)}&mode=ar_preferred#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
}
