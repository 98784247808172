import * as React from 'react';
import { SVGProps } from 'react';

export const ShippingBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={27}
    height={27}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m25.73 8.815-7-2.744A2 2 0 0 0 16 7.933v8.165a2 2 0 0 0 1.233 1.847l7 2.906A2 2 0 0 0 27 19.004v-8.327a2 2 0 0 0-1.27-1.862Z"
      fill="#AABC8B"
      fillOpacity={0.5}
    />
    <g clipPath="url(#a)" fill="#71903E">
      <path
        d="M14.816 18.222a.547.547 0 0 0-.504-.504.547.547 0 0 0 0 1.091.547.547 0 0 0 .504-.587ZM16.15 17.754a.547.547 0 0 0-.503-.504.547.547 0 0 0 0 1.091.547.547 0 0 0 .503-.587Z"/>
      <path
        d="M20.155 9.434v-.025a.319.319 0 0 0-.025-.05l-.019-.026-.038-.041-.022-.02-.041-.018h-.016l-6.868-2.808a.316.316 0 0 0-.237 0l-6.852 2.76h-.016l-.04.02-.023.018-.038.041-.019.026a.318.318 0 0 0-.025.05v.026a.315.315 0 0 0 0 .079v2.53H2.249a.316.316 0 0 0 0 .632v.04h3.589v1.082h-1.23a.316.316 0 0 0 0 .633h1.23v1.08H2.923a.316.316 0 0 0 0 .633h2.915v1.322c0 .128.078.242.196.29l6.855 2.847c.035.013.07.02.108.022a.315.315 0 0 0 .107-.022l6.855-2.846a.316.316 0 0 0 .197-.291V9.513a.317.317 0 0 0 0-.079Zm-7.149-2.346 2.6 1.04-5.914 2.501L6.99 9.504l6.017-2.416Zm-.325 12.705-6.21-2.587v-1.113h1.312a.316.316 0 0 0 0-.632H6.47v-1.082h3.004a.316.316 0 1 0 0-.632H6.47v-1.078h.633a.316.316 0 0 0 0-.633H6.47V9.975l2.416.999 3.795 1.58v7.239Zm.328-7.772-2.498-1.047 5.92-2.51 2.59 1.043-3.773 1.58-2.239.934Zm.313 7.772v-7.238l1.78-.74 4.427-1.847.016 7.238-6.223 2.587Z"/>
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(1.933 4.387)"
          d="M0 0h18.225v18.225H0z"
        />
      </clipPath>
    </defs>
  </svg>
);
