import React, { FC, useRef } from 'react';
import { styled, keyframes } from '@stitches/react';
import { blackA } from '@radix-ui/colors';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import close from '../../assets/close.svg';
import notice from '../../assets/notice.svg';
import { CanopyModal } from '../../content/content';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' }
});

const StyledOverlay = styled(AlertDialogPrimitive.Overlay, {
  backgroundColor: blackA.blackA9,
  position: 'fixed',
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  }
});

const StyledContent = styled(AlertDialogPrimitive.Content, {
  backgroundColor: 'white',
  borderRadius: '10px',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  maxWidth: '769px',
  maxHeight: '85vh',
  overflow: 'auto',
  zIndex: 9999,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  },
  '&:focus': { outline: 'none' }
});

const AlertDialog = AlertDialogPrimitive.Root;
const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
const AlertDialogContent = StyledContent;
const AlertDialogCancel = AlertDialogPrimitive.Cancel;

export const CanopyPopup: FC = (): JSX.Element => {
  const closeCanopyRef = useRef<any>(null);

  const closeCanopyPopup = () => {
    if (closeCanopyRef.current) {
      closeCanopyRef.current.click();
    }
  };
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <button>
          <div className="hidden lg:block underline font-light text-sm cursor-pointer hover_primary">
            Why do I need it and how is it
            used? {`>`}
          </div>

          <div className="lg:hidden underline font-light text-sm cursor-pointer hover_primary">
            Canopy Details {`>`}
          </div>
        </button>
      </AlertDialogTrigger>
      <AlertDialogPrimitive.Portal>
        <StyledOverlay onClick={closeCanopyPopup}/>
        <AlertDialogContent className="alert-dialog_fullScreen">
          <div
            className="sticky top-0 bg-white grid grid-cols-3 items-center pt-4 pb-4 lg:px-4 custom_modal_title_border px-2">
            <div/>

            <div className="justify-self-center font-bold lg:font-semibold text-sm lg:text-base text-text">
              Canopy Guide
            </div>

            <AlertDialogCancel asChild ref={closeCanopyRef}>
              <img
                className="justify-self-end cursor-pointer"
                src={close}
                alt="close canopy modal"
              />
            </AlertDialogCancel>
          </div>

          <div className="px-4 lg:px-16 pt-8">
            <div className="mb-8">
              <div className="text-text text-sm font-semibold mb-4">
                {CanopyModal.WHAT_IS_TITLE}
              </div>
              <div className="text-extralight text-sm font-light">
                {CanopyModal.WHAT_IS_CONTENT}
              </div>
            </div>

            <div className="mb-8">
              <div className="text-text text-sm font-semibold mb-4">
                {CanopyModal.WHY_TITLE}
              </div>
              <div className="text-extralight text-sm font-light">
                {CanopyModal.WHY_CONTENT}
              </div>
            </div>

            <div className="mb-8">
              <div className="text-text text-sm font-semibold mb-4">
                {CanopyModal.AMOUNT_TITLE}
              </div>
              <div className="text-extralight text-sm font-light">
                {CanopyModal.AMOUNT_CONTENT}
              </div>

              <div className="hidden lg:grid notice_modal_container px-4 py-4 mt-4 mb-8">
                <img
                  src={notice}
                  alt="notice icon"
                  style={{
                    marginTop: '5px'
                  }}
                />

                <div>
                  <div className="text-sm font-semibold text-green">{CanopyModal.NOTICE_TITLE}</div>
                  <div className="text-sm font-light text-text">
                    {CanopyModal.NOTICE_CONTENT}
                  </div>
                </div>
              </div>

              <div className="lg:hidden notice_mobile_modal_container px-4 py-4 mt-4 mb-8">
                <div className="flex items-end mb-4">
                  <img
                    src={notice}
                    alt="notice icon"
                    style={{
                      marginTop: '5px',
                      marginRight: '5px'
                    }}
                  />
                  <div className="text-sm font-semibold text-green">{CanopyModal.NOTICE_TITLE}</div>
                </div>

                <div className="text-sm font-light text-text">
                  {CanopyModal.NOTICE_CONTENT}
                </div>
              </div>
            </div>

            <div className="mb-8">
              <div className="text-text text-sm font-semibold mb-4">
                {CanopyModal.HOW_TITLE}
              </div>
              <div className="text-extralight text-sm font-light">
                {CanopyModal.HOW_CONTENT}
              </div>
            </div>

            <div className="notice_modal_container px-4 py-4 mt-4 mb-8">
              <div className="flex items-end mb-2">
                <img
                  src={notice}
                  alt="notice icon"
                  style={{
                    marginTop: '5px',
                    marginRight: '5px'
                  }}
                />
                <div className="text-sm font-semibold text-green">{CanopyModal.NOTICE_TITLE_2}</div>
              </div>

              <div className="text-sm font-light text-text">
                {CanopyModal.NOTICE_CONTENT_2}
              </div>
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialogPrimitive.Portal>
    </AlertDialog>
  );
};
