import { FC, MutableRefObject } from 'react';
import { Text, Line } from '@react-three/drei';
import { textColor } from '../../../contants/3d-text-settings';
import { Group } from 'three';

interface MeasurementProps {
  hPositionM: number;
  wPositionMP: number;
  lPositionMP: number;
  lPositionMN?: number;
  rotation?: [number, number, number];
  linePosition?: [number, number, number];
  textPosition?: [number, number, number];
  textValue?: string;
  firstPoint: [number, number, number];
  secondPoint: [number, number, number];
  groupRef?: MutableRefObject<Group | null>;
  fontSize: number;
}


// TODO: scale the text and the lines properly
// TODO: make the lines come out from the outside of the pergola
// TODO: add one sided plane to the text and the lines

export const Measurement: FC<MeasurementProps> = ({
  hPositionM,
  wPositionMP,
  lPositionMP,
  rotation = [0, 0, 0],
  linePosition = [0, 0, 0],
  firstPoint,
  secondPoint,
  textPosition,
  textValue,
  groupRef,
  fontSize,
}) => {

  // const { width, height } = useThree(s => s.size);
  // // return Math.min(width, height) * 0.01;

  return (
    <group ref={groupRef} position={[0, hPositionM, wPositionMP]}>
      <Text
        color={'#111111'}
        fontSize={fontSize}
        position={textPosition}
        rotation={rotation}
        lineHeight={1}
        maxWidth={1000}
        letterSpacing={0.02}
        font="/Roboto-Bold.ttf"
        anchorX="center"
        anchorY="bottom"
      >
        {textValue}
      </Text>

      <group visible userData={{ hello: 'world' }}
             position={linePosition}
             rotation={[0, 0, 0]}>
        <Line
          lineWidth={1}
          points={[
            [0, 0.05, 0],
            [0, -0.05, 0]
          ]}
          color={textColor}
          scale={1}
          position={[firstPoint[0], 0, secondPoint[2]]}
        />

        <Line
          lineWidth={1}
          points={[
            firstPoint,
            secondPoint
          ]}
          color={textColor}
          scale={1}
          position={[0, 0, 0]}
        />

        <Line
          lineWidth={1}
          points={[
            [0, 0.05, 0],
            [0, -0.05, 0]
          ]}
          color={textColor}
          scale={1}
          position={[secondPoint[0], 0, firstPoint[2]]}
        />
      </group>

    </group>
  );
};
