import React, { FC } from 'react';

import { usePergola } from '../../data-stores/pergola';

import CanopyColorRadio from '../radio/CanopyColorRadio';
import { CanopySwitch } from '../switches/CanopySwitch';

export const CanopyTabContent: FC = (): JSX.Element => {
  const state = usePergola(state => state.canopy);

  return (
    <div className="flex flex-col lg:block canopy_tab_content_container">
      <div className="px-4">
        <CanopySwitch/>
      </div>

      <div className={`relative ${!state.hasCanopy && 'opacity-50'}`}>
        <div className="text-xs text-center text-text my-4 font-light lg:hidden">
          Choose
          <span className="font-semibold text-xs text-text"> canopy color </span>
          for your pergola
        </div>

        <div className="text-sm text-center text-text mt-12 mb-8 font-normal hidden lg:block">
          CHOOSE
          <span className="font-semibold text-text"> CANOPY COLOR </span>
          FOR YOUR PERGOLA:
        </div>

        <CanopyColorRadio/>

        <div className={`absolute w-full h-full z-40 top-1 opacity-50 ${state.hasCanopy && 'hidden'}`}/>
      </div>
    </div>
  );
};
