import create from 'zustand';
import { PricingConfigurationData } from '../firebase/firestore/types/pricing-configuration';

export interface PricingConfigurationStore extends PricingConfigurationData {
  setPricingConfiguration: (config: Omit<PricingConfigurationStore, 'setPricingConfiguration'>) => void;
}

export const usePricingConfigurations = create<PricingConfigurationStore>(set => ({
  heightBasePrice: 0,
  heightSizePriceBelow94: 0,
  heightSizePriceAbove94: 0,
  lengthBasePrice: 0,
  lengthSizePriceBelow120: 0,
  lengthSizePriceBelow240: 0,
  lengthSizePriceAbove240: 0,
  beamsBasePrice: 0,
  widthSizePriceBelow120: 0,
  widthSizePriceBelow240: 0,
  widthSizePriceAbove240: 0,
  uConnectorsMultiplier: 0,
  canopyMultiplier: 0,
  attachedHeightPriceMultiplier: 0,
  attachedMultiplier: 0,
  woodenColorPricePercentMultiplier: 0,
  structureCharcoalGrayDiscountPercentage: 0,
  structureWhiteSnowDiscountPercentage: 0,
  structureBrazilianIpeDiscountPercentage: 0,
  structureTuscanCedarDiscountPercentage: 0,
  canopyCappuccinoDiscountPercentage: 0,
  canopyDoveWhiteDiscountPercentage: 0,
  canopySterlingSilverDiscountPercentage: 0,
  canopyDesertSandDiscountPercentage: 0,
  announcementBarMessage: '',
  setPricingConfiguration: (config) => set(config)
}));
