import { partsWeight } from './config';

/**
 * Calculate canopy and wheels weight
 *
 * @param {number} lengthFeet The Length inches of the pergola
 * @param {number} lengthInches The Length inches of the pergola
 * @param {number} widthFeet The Width inches of the pergola
 * @param {number} widthInches The Width inches of the pergola
 * @param {boolean} hasCanopy Whether or not the pergola has a canopy
 *
 * @return {number} The total weight of all the beams of the Pergola
 */

export const getCanopyWeight = (
  lengthFeet: number,
  lengthInches: number,
  widthFeet: number,
  widthInches: number,
  hasCanopy: boolean
): number => {
  // Return 0 if there is no canopy added in the configuration
  if (!hasCanopy) {
    return 0;
  }

  // Convert pergola length and width to inches
  const pergolaLength: number = (lengthFeet * 12) + lengthInches;
  const pergolaWidth: number = (widthFeet * 12) + widthInches;

  // Post size
  const postSize: number = 6;

  // Remove external width
  const extraWidthSpace: number = 14 * 2;

  // CANOPY
  // Calculate the area of the pergola
  let mainCanopyArea: number;

  if (pergolaWidth > 240) {
    mainCanopyArea = pergolaLength * (pergolaWidth - extraWidthSpace - postSize);
  } else {
    mainCanopyArea = pergolaLength * (pergolaWidth - extraWidthSpace);
  }

  // Calculate the total weight of the canopy depending on the area of the structure
  const mainCanopyWeight: number = mainCanopyArea * partsWeight.canopy;

  // Calculate area of additional canopy on top
  const topCanopyArea: number = pergolaLength * 12;

  // Calculate the weight of a single top canopy
  let topCanopyWeight: number = topCanopyArea * partsWeight.canopy;

  // Check if there will be a second top canopy attached to the pergola
  if (pergolaWidth > 240) {
    topCanopyWeight *= 2;
  }

  // WHEELS
  // Calculate number of wheels on the edges of the pergola
  const numberOfWheelsOnBeam: number = Math.round((pergolaWidth - extraWidthSpace) / 15.6);

  // Calculate weight of edge wheels
  const edgeWheelsWeight: number = (numberOfWheelsOnBeam * 2) * partsWeight.wheels.normal;

  // Calculate weight of center wheels
  const centerWHeelsWeight: number = numberOfWheelsOnBeam * partsWeight.wheels.a;

  // Initial center canopy wheels
  let centerWheels: number = 2;

  // Calculate additional center canopy wheels
  if (pergolaLength > 240 && pergolaLength <= 480) {
    centerWheels += 2;
  }

  if (pergolaLength > 480) {
    centerWheels += 4;
  }

  if (pergolaWidth > 240) {
    centerWheels *= 2;
  }

  // Calculate center wheels total weight
  const centerWheelsWeight: number = centerWheels * partsWeight.wheels.b;

  // TUBES
  // Calculate tubes weight
  const firstTypeTubeWeight = (pergolaLength * partsWeight.tubes.a) * numberOfWheelsOnBeam;
  const secondTypeTubeWeight = (pergolaLength * partsWeight.tubes.b) * numberOfWheelsOnBeam;

  // Return total weight of all part regarding the canopy
  return mainCanopyWeight + topCanopyWeight + edgeWheelsWeight + centerWHeelsWeight + centerWheelsWeight + firstTypeTubeWeight + secondTypeTubeWeight;
};