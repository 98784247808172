import { useLoader } from '@react-three/fiber';
import React, { FC } from 'react';
import { DoubleSide, RepeatWrapping, Texture, TextureLoader } from 'three';

import { CanopyColor } from '../../configurations/colors';
import { usePergola } from '../../data-stores/pergola';

export const CanopyMaterial: FC = () => {
  const canopyColor = usePergola(s => s.canopy.color);

  const doveWhiteTexture = useLoader(TextureLoader, [
    '/3d-models/textures/canopy/canopy_white_diffuse.jpg',
    '/3d-models/textures/canopy/canopy_normal.jpg',
    '/3d-models/textures/canopy/canopy_roughness.jpg',
  ]);

  const cappuccinoTexture = useLoader(TextureLoader, [
    '/3d-models/textures/canopy/canopy_diffuse_2.jpg',
    '/3d-models/textures/canopy/canopy_normal.jpg',
    '/3d-models/textures/canopy/canopy_roughness.jpg',
  ]);

  const sterlingSilverTexture = useLoader(TextureLoader, [
    '/3d-models/textures/canopy/canopy_diffuse_3.jpg',
    '/3d-models/textures/canopy/canopy_normal.jpg',
    '/3d-models/textures/canopy/canopy_roughness.jpg',
  ]);

  const desertSandTexture = useLoader(TextureLoader, [
    '/3d-models/textures/canopy/canopy_diffuse_4.jpg',
    '/3d-models/textures/canopy/canopy_normal.jpg',
    '/3d-models/textures/canopy/canopy_roughness.jpg',
  ]);

  let textures: Texture[];

  switch (canopyColor) {
    case CanopyColor.CAPPUCCINO:
      textures = cappuccinoTexture;
      break;
    case CanopyColor.DOVE_WHITE:
      textures = doveWhiteTexture;
      break;
    case CanopyColor.STERLING_SILVER:
      textures = sterlingSilverTexture;
      break;
    case CanopyColor.DESERT_SAND:
      textures = desertSandTexture;
      break;
    default:
      textures = cappuccinoTexture;
  }

  for (const texture of textures) {
    if (texture) {
      texture.wrapS = RepeatWrapping;
      texture.wrapT = RepeatWrapping;
      texture.repeat.set(1, 4);
    }
  }

  const [colorMap, normalMap, roughnessMap] = textures;

  return (
    <meshStandardMaterial
      key={`texture-${canopyColor}`}
      name={`texture-${canopyColor}`}
      attach="material"
      map={colorMap}
      normalMap={normalMap}
      roughnessMap={roughnessMap}
      side={DoubleSide}
    />
  );
};
