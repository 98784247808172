import { partsWeight } from './config';
import { PergolaDesign } from '../data-stores/pergola';

/**
 * Calculate posts weight
 *
 * @param {number} lengthFeet The Length inches of the pergola
 * @param {number} lengthInches The Length inches of the pergola
 * @param {number} widthFeet The Width inches of the pergola
 * @param {number} widthInches The Width inches of the pergola
 * @param {number} totalBeams The total amount of beams added to the configuration
 * @param {PergolaDesign} structureType The Pergola structure type
 *
 * @return {number} The total weight of all the beams of the Pergola
 */

export const getBeamsWeight = (
  lengthFeet: number,
  lengthInches: number,
  widthFeet: number,
  widthInches: number,
  totalBeams: number,
  structureType: PergolaDesign
): number => {
  // Convert pergola length and width to inches
  const pergolaLength: number = (lengthFeet * 12) + lengthInches;
  const pergolaWidth: number = (widthFeet * 12) + widthInches;

  // Check if attached pergola -> additional beam for wall
  let wallBeamWeight: number = 0;

  if (structureType === PergolaDesign.ATTACHED) {
    wallBeamWeight = pergolaLength * partsWeight.beams.wall;
  }

  // BEAMS
  // The weight of a center/edge beam
  const centerBeamWeight: number = (pergolaWidth * partsWeight.beams.centerAndEdges.railProfile) + (partsWeight.beams.centerAndEdges.railProfileCap * 2) + partsWeight.beams.centerAndEdges.railProfileConnector;

  // The weight of a normal beam
  const normalBeamWeight: number = (pergolaWidth * partsWeight.beams.normal.railProfile) + (partsWeight.beams.normal.railProfileCap * 2) + partsWeight.beams.normal.railProfileConnector;

  // The weight of a lower beam
  const lowerBeamWeight: number = (pergolaLength * partsWeight.beams.lower.railProfile) + partsWeight.beams.lower.railProfileConnector;

  // Initial center beams
  let numberOfCenterBeams: number = 3;

  if (pergolaLength > 240 && pergolaLength <= 480) {
    numberOfCenterBeams += 2;
  }

  if (pergolaLength > 480) {
    numberOfCenterBeams += 4;
  }

  // if (pergolaLength > 576) {
  //   numberOfCenterBeams += 6;
  // }

  // Calculate the number of the remaining beams
  const numberOfNormalBeams: number = totalBeams - numberOfCenterBeams;

  // Number of lower beams
  const numberOfLowerBeams: number = pergolaWidth > 240 ? 3 : 2;

  // Initial lower beams
  // let numberOfLowerBeams: number = 2;
  //
  // if (pergolaWidth > 192 && pergolaWidth <= 384) {
  //   numberOfLowerBeams += 1;
  // }
  //
  // if (pergolaLength > 384) {
  //   numberOfCenterBeams += 2;
  // }

  // U-CONNECTORS
  // Calculate u-connectors per beam
  // Initial lower beams
  // let uConnectorsPerBeam: number = 2;
  //
  // if (pergolaWidth > 192 && pergolaWidth <= 384) {
  //   uConnectorsPerBeam += 1;
  // }
  //
  // if (pergolaLength > 384) {
  //   uConnectorsPerBeam += 2;
  // }

  const uConnectorsPerBeam: number = pergolaWidth > 240 ? 3 : 2;

  // Calculate the amount of u-connectors used
  let uConnectorsAmount = numberOfNormalBeams * uConnectorsPerBeam;

  if (pergolaLength <= 240) {
    uConnectorsAmount -= uConnectorsPerBeam;
  }

  // Calculate the total weight of all u-connectors
  const uConnectorsWeight = uConnectorsAmount * partsWeight.uConnector;

  // Return the weight of all beams
  return (numberOfCenterBeams * centerBeamWeight) + (numberOfNormalBeams * normalBeamWeight) + (lowerBeamWeight * numberOfLowerBeams) + uConnectorsWeight + wallBeamWeight;
};