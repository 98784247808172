import {
  defaultLengthInches,
  defaultLengthCanopyInches,
  defaultWidthInches,
  defaultLegHeight
} from './constants';

export const heightScale = (totalHeightInches: number) =>
  (x: number, totalInches: number = totalHeightInches): number =>
    x / defaultLegHeight * totalInches;

export const widthBeamScale = (totalWidthInches: number) =>
  (x: number, totalInches: number = totalWidthInches): number =>
    x / defaultWidthInches * totalInches;

export const widthScale = (totalWidthInches: number) =>
  (x: number, totalInches: number = totalWidthInches): number =>
    x / defaultWidthInches * totalInches;

export const lengthScale = (totalLengthInches: number) =>
  (x: number, totalInches: number = totalLengthInches): number =>
    x / defaultLengthInches * totalInches;

export const lengthScaleCanopy = (totalWidthInches: number) =>
  (x: number, totalInches: number = totalWidthInches): number =>
    x / defaultLengthCanopyInches * (totalInches - 5.1);
