import create from 'zustand';

export interface ArStore {
  isAr: boolean;
  setIsAr: (value: boolean) => void;
}

export const useAr = create<ArStore>(set => ({
  isAr: false,
  setIsAr: (value: boolean) => set((state: ArStore) => ({
    ...state,
    isAr: value
  }))
}));
