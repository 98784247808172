import React, { FC } from 'react';

import { useAr } from '../../data-stores/ar';

import { ArModal } from '../modals/ArModal';
import { ShareModal } from '../modals/ShareModal';

import { ControlTabs } from '../../configurations/tabs';

import ar from '../../assets/ar.svg';
import phone from '../../assets/phone.svg';
import mobilePhone from '../../assets/mobile-phone.svg';
import linkChain from '../../assets/link-chain.svg';

interface FloatingButtonsProps {
  activeTab?: ControlTabs;
}

const FloatingButtons: FC<FloatingButtonsProps> = ({ activeTab }): JSX.Element => {
  const setIsAr = useAr(state => state.setIsAr);

  return (
    <>
      <div className="hidden lg:flex floating_buttons_container">
        <div className="mt-10">
          <div className="mb-4">
            <ShareModal/>
          </div>

          <ArModal/>
        </div>

        <a
          href="tel:+18777824482"
          className="floating_button_container help mb-4"
        >
          <div className="floating_button_help_text text-green text-sm font-bold">Need help?</div>

          <div className="floating_button_text help items-center text-text text-xs font-normal">
            <img
              className="mr-2"
              src={phone}
              alt=""
            />
            <div>877-782-4482</div>
          </div>
        </a>
      </div>

      <div
        className="flex lg:hidden floating_buttons_container"
      >
        <div>
          <div className="floating_button_container mb-2">
            <img
              className="floating_button_image relative"
              src={linkChain}
              width={19}
              height={19}
              alt="share link"
            />
            <ShareModal/>
          </div>

          <div className="floating_button_container ar">
            <a
              id="ar-link"
              rel="ar"
              className="hidden"
              download="pergola.usdz"
            >
              <img
                className="floating_button_image"
                src={ar}
                width={19}
                height={19}
                alt="view in ar"
              />
            </a>
            <div onClick={() => setIsAr(true)}>
              <img
                onClick={() => setIsAr(true)}
                className="floating_button_image"
                src={ar}
                width={19}
                height={19}
                alt="view in ar"
              />
            </div>
          </div>
        </div>

        <a
          href="tel:+18777824482"
          className="floating_button_container"
        >
          <img
            className="floating_button_image"
            width={19}
            height={19}
            src={mobilePhone}
            alt="share link"
          />
        </a>
      </div>
    </>
  );
};

export default FloatingButtons;
