import { PergolaDesign } from '../../data-stores/pergola';

import { roundDecimalNumber } from '../../price-calculations/round-decimal-number';

enum ReportEvents {
  VIEW = 'eci_custom_view_item',
  ADD_TO_CART = 'eci_custom_add_to_cart',
  CHECKOUT = 'eci_custom_begin_checkout'
}

interface ReportEventProps {
  event: keyof typeof ReportEvents;
  design: PergolaDesign;
  price: number;
  discount: number;
}

export const reportEvent = ({
  event,
  design,
  price,
  discount
}: ReportEventProps) => {
  // @ts-ignore
  window.dataLayer.push({
    event: ReportEvents[event],
    ecommerce: {
      items: [
        {
          item_id: `custom-pergola-${design === PergolaDesign.ATTACHED ? 'attached' : 'free-standing'}`,
          item_name: `${design === PergolaDesign.ATTACHED ? 'Attached' : 'Free Standing'} Custom Pergola`,
          affiliation: 'Paragon Outdoor',
          currency: 'USD',
          price: roundDecimalNumber(price - discount).toFixed(2),
          quantity: 1
        }
      ]
    }
  });
};
