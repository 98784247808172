import React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { usePergola } from '../../data-stores/pergola';

import { CanopyColor } from '../../configurations/colors';

import cappuccino from '../../assets/cappuccino.png';
import doveWhite from '../../assets/dove-white.png';
import sterlingSilver from '../../assets/sterling-silver.png';
import desertSand from '../../assets/desert-sand.png';

export const CanopyColorRadio = () => {
  const hasCanopy = usePergola(state => state.canopy.hasCanopy);
  const canopyColor = usePergola(state => state.canopy.color);
  const changeCanopyColor = usePergola(state => state.changeCanopyColor);

  return (
    <RadioGroupPrimitive.Root
      className="grid grid-cols-4 justify-around canopy_colors_radio_container my-8"
      defaultValue={!hasCanopy ? '' : CanopyColor.CAPPUCCINO}
      aria-label="View density"
      aria-disabled={!hasCanopy}
      tabIndex={!hasCanopy ? -1 : 0}
    >
      <div className="flex flex-col items-center justify-center">
        <RadioGroupPrimitive.Item
          id="c1"
          className="canopy_colors_radio_group_item"
          checked={canopyColor === CanopyColor.CAPPUCCINO}
          value={CanopyColor.CAPPUCCINO}
          onClick={() => changeCanopyColor(CanopyColor.CAPPUCCINO)}
        >
          <div
            className="canopy_color_circle"
            // style={{ backgroundColor: CanopyColorHexNumbers.CAPPUCCINO }}
          >
            <img className="rounded-full" src={cappuccino} alt="cappuccino color"/>
          </div>
          <RadioGroupPrimitive.Indicator className="canopy_color_indicator"/>
        </RadioGroupPrimitive.Item>
        <label
          htmlFor="c1"
          className={`text-xs lg:text-sm ${canopyColor === CanopyColor.CAPPUCCINO ? 'canopy_design_color_selected_label' : 'canopy_design_color_label'}`}
        >
          Cappuccino
        </label>
      </div>

      <div className="flex flex-col items-center justify-center">
        <RadioGroupPrimitive.Item
          id="c2"
          className="canopy_colors_radio_group_item"
          checked={canopyColor === CanopyColor.DOVE_WHITE}
          value={CanopyColor.DOVE_WHITE}
          onClick={() => changeCanopyColor(CanopyColor.DOVE_WHITE)}
        >
          <div
            className="canopy_color_circle"
            // style={{ backgroundColor: CanopyColorHexNumbers.DOVE_WHITE }}
          >
            <img className="rounded-full" src={doveWhite} alt="dove white color"/>
          </div>
          <RadioGroupPrimitive.Indicator className="canopy_color_indicator"/>
        </RadioGroupPrimitive.Item>
        <label
          htmlFor="c2"
          className={`text-xs lg:text-sm ${canopyColor === CanopyColor.DOVE_WHITE ? 'canopy_design_color_selected_label' : 'canopy_design_color_label'}`}
        >
          Dove White
        </label>
      </div>


      <div className="flex flex-col items-center justify-center">
        <RadioGroupPrimitive.Item
          id="c3"
          className="canopy_colors_radio_group_item"
          checked={canopyColor === CanopyColor.STERLING_SILVER}
          value={CanopyColor.STERLING_SILVER}
          onClick={() => changeCanopyColor(CanopyColor.STERLING_SILVER)}
        >
          <div
            className="canopy_color_circle"
            // style={{ backgroundColor: CanopyColorHexNumbers.STERLING_SILVER }}
          >
            <img className="rounded-full" src={sterlingSilver} alt="sterling silver color"/>
          </div>
          <RadioGroupPrimitive.Indicator className="canopy_color_indicator"/>
        </RadioGroupPrimitive.Item>
        <label
          htmlFor="c3"
          className={`text-xs lg:text-sm ${canopyColor === CanopyColor.STERLING_SILVER ? 'canopy_design_color_selected_label' : 'canopy_design_color_label'}`}
        >
          Sterling Silver
        </label>
      </div>

      <div className="flex flex-col items-center justify-center">
        <RadioGroupPrimitive.Item
          id="c4"
          className="canopy_colors_radio_group_item"
          checked={canopyColor === CanopyColor.DESERT_SAND}
          value={CanopyColor.DESERT_SAND}
          onClick={() => changeCanopyColor(CanopyColor.DESERT_SAND)}
        >
          <div
            className="canopy_color_circle"
            // style={{ backgroundColor: CanopyColorHexNumbers.DESERT_SAND }}
          >
            <img className="rounded-full" src={desertSand} alt="desert sand color"/>
          </div>
          <RadioGroupPrimitive.Indicator className="canopy_color_indicator"/>
        </RadioGroupPrimitive.Item>
        <label
          htmlFor="c4"
          className={`text-xs lg:text-sm ${canopyColor === CanopyColor.DESERT_SAND ? 'canopy_design_color_selected_label' : 'canopy_design_color_label'}`}
        >
          Desert Sand
        </label>
      </div>
    </RadioGroupPrimitive.Root>
  );
};

export default CanopyColorRadio;
