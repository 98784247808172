import React, { FC, useEffect } from 'react';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import client from './apollo-client';
import { ApolloProvider } from '@apollo/client';
import { signInAnonymously } from 'firebase/auth';

import { usePergola } from './data-stores/pergola';
import { auth } from './firebase';
import { useUser } from './data-stores/user';

import App from './App';
import { MediaQueryContextProvider } from './contexts/MediaQueryContext';
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import { createConfigurationWithBuildID } from './configurations/create-configuration';
import { Loader } from './components/shared/Loader';
import './index.scss';
import { BrowserTracing } from '@sentry/tracing';

const tagManagerArgs = {
  gtmId: 'GTM-MHJ4LWD'
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: 'https://b2ab8b8bd4b34807857599eb7801c7ab@o479719.ingest.sentry.io/6260795',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

const ConfigurationLoader: FC = () => {
  const { user, setUser } = useUser(state => state);
  const updateConfigurationID = usePergola(state => state.updateConfigurationID);
  const navigate = useNavigate();
  const updateBuildNumber = usePergola(state => state.updatePergolaBuildNumber);

  useEffect(() => {
    if (user) {
      return;
    }

    (async () => {
      try {
        const user = await signInAnonymously(auth);
        setUser(user);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [setUser, user]);

  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      const { buildNumber } = await createConfigurationWithBuildID(user.user.uid);
      const newConfigurationID = buildNumber;

      updateConfigurationID(newConfigurationID);
      updateBuildNumber(buildNumber);

      navigate(`/${newConfigurationID}`);
    })();

  }, [navigate, updateBuildNumber, updateConfigurationID, user]);

  return (
    <Loader/>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <ConfigurationLoader/>
  },
  {
    path: '/:buildNumber',
    element: <App/>
  }
]);


ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <MediaQueryContextProvider>
        <RouterProvider router={router}/>
      </MediaQueryContextProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.
// ly/CRA-vitals
reportWebVitals();

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
