import { CanopyColor, StructureColor } from '../configurations/colors';

/**
 * Calculate colors discount price
 *
 * @param {hasCanopy} hasCanopy If the pergola has a canopy
 * @param {StructureColor} structureColor The color of the pergola
 * @param {CanopyColor | ''} canopyColor The color of the canopy
 * @param {number} totalPrice The total price of the pergola
 * @param {number} canopyPrice The canopy price of the pergola
 * @param {number} structureCharcoalGrayDiscountPercentage The color percent multiplier
 * @param {number} structureWhiteSnowDiscountPercentage The color percent multiplier
 * @param {number} structureBrazilianIpeDiscountPercentage The color percent multiplier
 * @param {number} structureTuscanCedarDiscountPercentage The color percent multiplier
 * @param {number} canopyCappuccinoDiscountPercentage The color percent multiplier
 * @param {number} canopyDoveWhiteDiscountPercentage The color percent multiplier
 * @param {number} canopySterlingSilverDiscountPercentage The color percent multiplier
 * @param {number} canopyDesertSandDiscountPercentage The color percent multiplier
 * @return {number}
 */

export const calculateColorsPriceDiscount = (
  hasCanopy: boolean,
  structureColor: StructureColor,
  canopyColor: CanopyColor | '',
  totalPrice: number,
  canopyPrice: number,
  structureCharcoalGrayDiscountPercentage: number,
  structureWhiteSnowDiscountPercentage: number,
  structureBrazilianIpeDiscountPercentage: number,
  structureTuscanCedarDiscountPercentage: number,
  canopyCappuccinoDiscountPercentage: number,
  canopyDoveWhiteDiscountPercentage: number,
  canopySterlingSilverDiscountPercentage: number,
  canopyDesertSandDiscountPercentage: number
): number => {
  const newTotalPrice = hasCanopy ? totalPrice - canopyPrice : totalPrice;
  let discount: number = 0;

  // Structure color discount
  if (structureColor === StructureColor.CHARCOAL_GRAY) {
    discount = newTotalPrice * structureCharcoalGrayDiscountPercentage;
  }

  if (structureColor === StructureColor.HAMPTON_WHITE) {
    discount = newTotalPrice * structureWhiteSnowDiscountPercentage;
  }

  if (structureColor === StructureColor.BRAZILIAN_IPE) {
    discount = newTotalPrice * structureBrazilianIpeDiscountPercentage;
  }

  if (structureColor === StructureColor.TUSCAN_CEDAR) {
    discount = newTotalPrice * structureTuscanCedarDiscountPercentage;
  }

  // Canopy color discount
  if (hasCanopy) {
    if (canopyColor === CanopyColor.CAPPUCCINO) {
      discount += canopyPrice * canopyCappuccinoDiscountPercentage;
    }

    if (canopyColor === CanopyColor.DOVE_WHITE) {
      discount += canopyPrice * canopyDoveWhiteDiscountPercentage;
    }

    if (canopyColor === CanopyColor.STERLING_SILVER) {
      discount += canopyPrice * canopySterlingSilverDiscountPercentage;
    }

    if (canopyColor === CanopyColor.DESERT_SAND) {
      discount += canopyPrice * canopyDesertSandDiscountPercentage;
    }
  }

  return discount;
};
