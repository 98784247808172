import { isSafari } from 'react-device-detect';
import { Scene } from 'three';
import { exportGltfUrl } from './gltf';
import { exportUSDZUrl } from './usdz';

export const exportARUrl = async (scene: Scene): Promise<string | undefined> => {
  if (isSafari) {
    return exportUSDZUrl(scene);
  }

  return exportGltfUrl(scene);
};
