import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  exitAnimation,
  initialSmarchAnimation,
  initialSmarchAnimationBasic,
  SmarchAnimate,
  SmarchAnimateBasic,
  SmarchTransition
} from '../animation-constants';

import { PergolaDesign, usePergola } from '../../data-stores/pergola';
import { useErrors } from '../../data-stores/errors';
import { usePricingConfigurations } from '../../data-stores/pricing-configuration';

import { CanvasContainer } from '../3d/CanvasContainer';

import { createCustomPergola } from '../../firebase/functions/shopify-request';

import { roundDecimalNumber } from '../../price-calculations/round-decimal-number';

import { StyleTabContent } from '../tabs/StyleTabContent';
import { CanopyTabContent } from '../tabs/CanopyTabContent';
import { BeamsTabContent } from '../tabs/BeamsTabContent';
import { HomePopup } from '../modals/HomeModal';
import { DimensionsTabContent } from '../tabs/DimensionsTabContent';
import Summary from '../tabs/Summary';
import FloatingButtons from '../shared/FloatingButtons';
import { DimensionsPopup } from '../modals/DimensionsModal';
import { BeamsPopup } from '../modals/BeamsModal';
import { CanopyPopup } from '../modals/CanopyModal';

import { StructureColor } from '../../configurations/colors';
import { ControlTabs } from '../../configurations/tabs';

import backArrow from '../../assets/backArrow.svg';
import questionMark from '../../assets/question-mark.svg';
import { reportEvent } from '../../utilities/gtag/report-event';

export const MobileLayout: FC = (): JSX.Element => {
  const structureDesign = usePergola(state => state.design.pergola);
  const structureColor = usePergola(state => state.design.color);
  const hasCanopy = usePergola(state => state.canopy.hasCanopy);
  const canopyColor = usePergola(state => state.canopy.color);
  const price = usePergola(state => state.price);
  const discount = usePergola(state => state.discount);
  const announcementBarMessage = usePricingConfigurations(state => state.announcementBarMessage);

  const dimensionsError = useErrors(state => state.dimensionsError);
  const beamsError = useErrors(state => state.beamsError);
  const state = usePergola(state => state);

  const mobileCheckboxRef = useRef<HTMLInputElement>(null);

  const [activeTab, setActiveTab] = useState<ControlTabs>(ControlTabs.STYLE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buttonAnimation, setButtonAnimation] = useState<boolean>(false);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [termsChecked, setTermsChecked] = useState<boolean>(false);

  const renderCanvas = useMemo(() => <CanvasContainer/>, []);

  useEffect(() => {
    if (buttonAnimation) {
      setTimeout(() => {
        setButtonAnimation(false);
      }, 2000);
    }
  }, [buttonAnimation]);

  const finalObject = {
    design: {
      pergola: state.design.pergola,
      color: state.design.color
    },
    dimensions: state.dimensions,
    canopy: {
      color: state.canopy.hasCanopy ? state.canopy.color : '-',
      hasCanopy: state.canopy.hasCanopy
    },
    beams: state.beams,
    price: state.price - state.discount,
    buildNumber: state.buildNumber,
    isPingCall: false
  };

  const pingCloudFunction = async () => {
    try {
      await createCustomPergola({ ...finalObject, isPingCall: true });
    } catch (e: any) {
      throw new Error(`Create Cart Error: ${e}`);
    }
  };

  const nextTab = () => {
    if (activeTab === ControlTabs.STYLE) {
      setActiveTab(ControlTabs.DIMENSIONS);
    }

    if (activeTab === ControlTabs.DIMENSIONS) {
      setActiveTab(ControlTabs.CANOPY);
    }

    if (activeTab === ControlTabs.CANOPY) {
      setShowSummary(true);
      setActiveTab(ControlTabs.BEAMS);
    }

    if (showSummary) {
      setActiveTab(ControlTabs.SUMMARY);
    }
  };

  const addToShopifyCart = async () => {
    setIsLoading(true);

    reportEvent({
      event: 'ADD_TO_CART',
      design: state.design.pergola,
      price: state.price,
      discount: state.discount
    });

    try {
      const createProductResponse = await createCustomPergola(finalObject);
      setIsLoading(false);
      const variantId = createProductResponse.data.product.variants[0].id;
      console.log(variantId);
      window.location.href = `https://paragon-outdoor.com/cart/add?id=${variantId}&quantity=1`;
    } catch (e: any) {
      setIsLoading(false);
      throw new Error(`Create Cart Error: ${e}`);
    }
  };

  return (
    <div
      className={`transition-colors duration-1000 ${structureColor === StructureColor.HAMPTON_WHITE ? 'bg-gray' : ''} mobile_layout_container w-full grid lg:hidden`}>
      {/* Header */}
      <div
        className="fixed sticky top-0 bg-white flex flex-col justify-between items-center mobile_top_bar">

        <div className="absolute w-full flex justify-center items-center bg-text top-0" style={{ height: '34px' }}>
          <div className="text-xs text-white font-normal">
            {announcementBarMessage}
          </div>
        </div>
        <div
          className={`grid grid-cols-header w-full pt-9 px-2 items-center ${activeTab === ControlTabs.SUMMARY && 'h-full pt-8'}`}>

          <div className={`pl-4 ${activeTab === ControlTabs.SUMMARY && 'items-center'}`}>
            {/*<AnimatePresence>*/}

            {activeTab === ControlTabs.SUMMARY && (
              <motion.div
                transition={{ duration: 0.36, delay: 0.4 }}
                initial={initialSmarchAnimation}
                animate={SmarchAnimate}
                exit={exitAnimation}
                // exit={{ opacity: 0 }}
                className="flex items-center "
                onClick={() => setActiveTab(ControlTabs.STYLE)}
              >
                <img src={backArrow} alt="back arrow"/>
                <div className="tex-text text-sm font-light ml-2">Edit</div>
              </motion.div>
            )}
            {/*</AnimatePresence>*/}

            {activeTab !== ControlTabs.SUMMARY && (
              <div className="justify-start flex">
                <HomePopup/>
              </div>
            )}

          </div>

          {activeTab === ControlTabs.SUMMARY && (
            <motion.div
              transition={{ duration: 0.8, delay: 0.4 }}
              initial={initialSmarchAnimationBasic}
              animate={SmarchAnimateBasic}
              // exit={{ opacity: 0 }}
              className="justify-self-center text-text font-bold text-base">
              Summary
            </motion.div>
          )}

          {activeTab !== ControlTabs.SUMMARY && (
            <motion.div
              transition={SmarchTransition}
              initial={initialSmarchAnimationBasic}
              animate={SmarchAnimateBasic}
              className="justify-self-center text-text font-medium text-sm">
              Build your own pergola
            </motion.div>
          )}

          <div/>
        </div>
        <AnimatePresence>
          {activeTab !== ControlTabs.SUMMARY && (
            <motion.div
              transition={{ duration: 0.38 }}
              initial={initialSmarchAnimationBasic}
              animate={SmarchAnimateBasic}
              exit={exitAnimation}
              className={`grid-cols-4 w-full items-center pb-1 grid `}>
              <div
                className={`relative w-full text-center ${activeTab === ControlTabs.STYLE ? 'mobile_tab_active_button' : 'mobile_tab_button'}`}
                onClick={() => {
                  if (dimensionsError || beamsError) {
                    setButtonAnimation(true);
                    return;
                  }

                  setActiveTab(ControlTabs.STYLE);
                }}
                style={{
                  opacity: dimensionsError || beamsError ? 0.5 : 1,
                  color: activeTab === ControlTabs.STYLE && !dimensionsError && !beamsError ? '#71903E' : '#393B42'
                }}
              >
                Style

                <div
                  className={`mobile_header_active_tab_border ${activeTab === ControlTabs.STYLE ? 'block' : 'hidden'}`}/>
              </div>

              <div
                className={`relative w-full text-center justify-self-center ${activeTab === ControlTabs.DIMENSIONS ? 'mobile_tab_active_button' : 'mobile_tab_button'}`}
                onClick={() => {
                  // if (finalPergolaDesign.design.pergola === '' || finalPergolaDesign.design.color === '' || beamsError) {
                  //   setButtonAnimation(true);
                  //   window.location.href = '#cta'
                  //   return;
                  // }

                  setActiveTab(ControlTabs.DIMENSIONS);
                }}
                style={{
                  opacity: dimensionsError || beamsError ? 0.5 : 1,
                  color: activeTab === ControlTabs.DIMENSIONS && !beamsError ? '#71903E' : '#393B42'
                }}
              >
                Dimensions

                <div
                  className={`mobile_header_active_tab_border ${activeTab === ControlTabs.DIMENSIONS ? 'block' : 'hidden'}`}/>
              </div>

              <div
                onClick={() => {
                  // if (
                  //   finalPergolaDesign.dimensions.height.feet === 0
                  //   || finalPergolaDesign.dimensions.length.feet === 0
                  //   || finalPergolaDesign.dimensions.width.feet === 0
                  //   || dimensionsError
                  //   || beamsError
                  // ) {
                  //   setButtonAnimation(true);
                  //   window.location.href = '#cta'
                  //   return;
                  // }

                  setActiveTab(ControlTabs.CANOPY);
                }}
                className={`relative w-full text-center justify-self-center ${activeTab === ControlTabs.CANOPY ? 'mobile_tab_active_button' : 'mobile_tab_button'}`}
                style={{
                  opacity: dimensionsError || beamsError ? 0.5 : 1,
                  color: activeTab === ControlTabs.CANOPY && !dimensionsError && !beamsError ? '#71903E' : '#393B42'
                }}
              >
                Canopy

                <div
                  className={`mobile_header_active_tab_border ${activeTab === ControlTabs.CANOPY ? 'block' : 'hidden'}`}/>
              </div>

              <div
                onClick={() => {
                  // if (finalPergolaDesign.canopy.hasCanopy === undefined || dimensionsError) {
                  //   setButtonAnimation(true);
                  //   window.location.href = '#cta'
                  //   return;
                  // }

                  setShowSummary(true);
                  setActiveTab(ControlTabs.BEAMS);
                }}
                className={`relative w-full text-center justify-self-center ${activeTab === ControlTabs.BEAMS ? 'mobile_tab_active_button' : 'mobile_tab_button'}`}
                style={{
                  opacity: dimensionsError || beamsError ? 0.5 : 1,
                  color: activeTab === ControlTabs.BEAMS && !dimensionsError ? '#71903E' : '#393B42'
                }}
              >
                Beams

                <div
                  className={`mobile_header_active_tab_border ${activeTab === ControlTabs.BEAMS ? 'block' : 'hidden'}`}/>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Canvas */}
      <div className="mobile_canvas_container">
        {renderCanvas}

        <FloatingButtons activeTab={activeTab}/>
      </div>

      {/* Pergola Settings Container */}

      {activeTab !== ControlTabs.SUMMARY && (
        <div className="mobile_pergola_settings_container">
          {/*Style Tab Content */}
          <div className={`h-full flex-col justify-around ${activeTab === ControlTabs.STYLE ? 'flex' : 'hidden'}`}>
            <StyleTabContent/>
          </div>
          {/*// Dimensions Tab Content*/}
          <div
            className={`h-full flex-col justify-between pt-4 ${activeTab === ControlTabs.DIMENSIONS ? 'flex' : 'hidden'}`}>
            <DimensionsTabContent/>

            <div className="flex items-center px-4 mb-4">
              <div className="custom_question_mark_container">
                <img src={questionMark} alt="question mark"/>
              </div>
              <DimensionsPopup/>
            </div>
          </div>

          {/*  Canopy Tab Content */}
          <div
            className={`h-full flex-col justify-between pt-4 ${activeTab === ControlTabs.CANOPY ? 'flex' : 'hidden'}`}>
            <CanopyTabContent/>

            <div className="flex items-center px-4 mb-4">
              <div className="custom_question_mark_container">
                <img src={questionMark} alt="question mark"/>
              </div>
              <CanopyPopup/>
            </div>
          </div>

          {/*  Beams Tab Content */}
          <div
            className={`h-full flex-col justify-between pt-4 ${activeTab === ControlTabs.BEAMS ? 'flex' : 'hidden'}`}>
            <BeamsTabContent/>

            <div className="flex items-center px-4 mb-4">
              <div className="custom_question_mark_container">
                <img src={questionMark} alt="question mark"/>
              </div>
              <BeamsPopup/>
            </div>
          </div>
        </div>
      )}
      <AnimatePresence>
        {activeTab === ControlTabs.SUMMARY && (
          <div className="mobile_pergola_settings_container z-40">
            <motion.div
              transition={{ duration: 0.38 }}
              initial={initialSmarchAnimationBasic}
              animate={SmarchAnimateBasic}
              exit={exitAnimation}
              className={`h-full flex-col pt-4 flex`}>
              <Summary/>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      {/* Price and Navigation Button */}
      {
        activeTab === ControlTabs.SUMMARY
          ? (
            <div className="mobile_cart_bottom_bar_container summary_bar">
              <div className="bg-background px-4">
                <label
                  htmlFor="mobile-terms"
                  className="accessible_label h-14 flex items-center text-xs font-light ml-2 text-text cursor-pointer">
                  <div className="custom_checkbox_container">
                    <input
                      id="mobile-terms"
                      name="mobile-terms"
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (mobileCheckboxRef.current) {
                            mobileCheckboxRef.current.className = 'custom_checkmark';
                          }

                          setTermsChecked(true);
                        } else {
                          setTermsChecked(false);
                        }
                      }}
                    />

                    <span
                      tabIndex={1}
                      aria-label={'Accept terms and conditions'}
                      className="custom_checkmark"
                      ref={mobileCheckboxRef}
                    />
                  </div>

                  <div>
                    By clicking here, I approve the custom product dimensions as well as the&nbsp;
                    <a
                      href="https://paragon-outdoor.com/pages/terms-and-conditions"
                      className="underline text-text"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms and conditions policy
                    </a>.
                  </div>
                </label>
              </div>

              <div className="mobile_cart_price_button_container h-16">
                <div className="text-white">
                  {
                    discount > 0
                      ? (
                        <div className="flex items-center">
                          <div className="font-bold text-base">
                            $ {
                            !dimensionsError && !beamsError
                              ? roundDecimalNumber(price - discount).toFixed(2)
                              : '-'
                          }
                          </div>

                          {
                            discount > 0 && (
                              <div className="font-normal text-sm ml-2 line-through">
                                $ {
                                !dimensionsError && !beamsError
                                  ? roundDecimalNumber(price).toFixed(2)
                                  : '-'
                              }
                              </div>
                            )
                          }
                        </div>
                      )
                      : (
                        <div className="font-bold text-base">
                          $ {
                          !dimensionsError && !beamsError
                            ? roundDecimalNumber(price).toFixed(2)
                            : '-'
                        }
                        </div>
                      )
                  }
                  <div
                    className="font-light text-10">{structureDesign === PergolaDesign.FREE_STANDING ? 'FREE STANDING' : 'ATTACHED'} PERGOLA
                  </div>
                </div>

                <button
                  className={`mobile_next_cart_button ${(!termsChecked || isLoading) && 'cursor-not-allowed opacity-70'}`}
                  style={{ backgroundColor: '#71903E' }}
                  onClick={async () => {
                    if (isLoading || !termsChecked) {
                      if (mobileCheckboxRef.current) {
                        mobileCheckboxRef.current.className = `custom_checkmark on_error`;

                        setTimeout(() => {
                          if (mobileCheckboxRef.current) {
                            mobileCheckboxRef.current.className = `custom_checkmark button-interaction__checkout on_error`;
                          }
                        }, 1);
                      }

                      return;
                    }

                    await addToShopifyCart();
                  }}
                >
                  {
                    isLoading
                      ? 'Loading...'
                      : 'Add to Cart'
                  }
                </button>
              </div>
            </div>
          )
          : (
            <div className="mobile_cart_bottom_bar_container">
              <div className="mobile_cart_price_button_container">
                <div className="text-white">
                  {
                    discount > 0
                      ? (
                        <div className="flex items-center">
                          <div className="font-bold text-base">
                            $ {
                            !dimensionsError && !beamsError
                              ? roundDecimalNumber(price - discount).toFixed(2)
                              : '-'
                          }
                          </div>

                          {
                            discount > 0 && (
                              <div className="font-normal text-sm ml-2 line-through">
                                $ {
                                !dimensionsError && !beamsError
                                  ? roundDecimalNumber(price).toFixed(2)
                                  : '-'
                              }
                              </div>
                            )
                          }
                        </div>
                      )
                      : (
                        <div className="font-bold text-base">
                          $ {
                          !dimensionsError && !beamsError
                            ? roundDecimalNumber(price).toFixed(2)
                            : '-'
                        }
                        </div>
                      )
                  }
                  <div
                    className="font-light text-10">{structureDesign === PergolaDesign.FREE_STANDING ? 'FREE STANDING' : 'ATTACHED'} PERGOLA
                  </div>
                </div>

                <button
                  id="cta"
                  className={`mobile_next_cart_button ${buttonAnimation ? 'button-interaction' : ''}`}
                  style={{
                    backgroundColor: (activeTab === ControlTabs.CANOPY && (hasCanopy && canopyColor === ''))
                    || dimensionsError
                    || beamsError
                      ? '#B8C79F'
                      : '#71903E'
                  }}
                  onClick={() => {
                    setButtonAnimation(false);

                    if (showSummary) {
                      pingCloudFunction();
                    }

                    nextTab();
                  }}
                  disabled={
                    (activeTab === ControlTabs.CANOPY && (hasCanopy && canopyColor === ''))
                    || dimensionsError
                    || beamsError
                  }
                >
                  {
                    showSummary
                      ? 'View Summary'
                      : 'Next'
                  }
                </button>
              </div>
            </div>
          )
      }
    </div>
  );
};
