import { FirebaseApp, getApp, getApps, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getPerformance } from 'firebase/performance';

import { firebaseConfig } from './config';

export let firebaseApp: FirebaseApp;

if (getApps().length) {
  firebaseApp = getApp();
} else {
  firebaseApp = initializeApp(firebaseConfig);
}

export const auth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const performance = getPerformance(firebaseApp);


