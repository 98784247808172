import React, { FC, useRef } from 'react';
import { Group } from 'three';
import { useFrame, useThree } from '@react-three/fiber';

import { Measurement } from './Measurement';
import { usePergola } from '../../../data-stores/pergola';

interface MeasurementProps {
  position?: [number, number, number];
  rotation?: [number, number, number];
  hPositionM: (x: number) => number;
  wPositionMP: (x: number) => number;
  lPositionMP: (x: number) => number;
}

export const MeasurementsGroup: FC<MeasurementProps> = ({ hPositionM, wPositionMP, lPositionMP }) => {
  const { width, length } = usePergola(s => s.dimensions);


  const { camera } = useThree();
  const groupRef1 = useRef<Group | null>(null);
  const groupRef2 = useRef<Group | null>(null);
  const groupRef3 = useRef<Group | null>(null);
  const groupRef4 = useRef<Group | null>(null);

  let fontSize = 0.1;

  const lengthOffset = 0.1;
  const widthOffset = 0.3;

  useFrame(() => {
    // Get the camera's position
    const { z, y, x } = camera.position;

    if (groupRef1.current) {
      groupRef1.current.visible = z < -1 && x < 7 && y < 4;
    }

    if (groupRef2.current) {
      groupRef2.current.visible = z > 2 && x < 7 && y < 4;
    }

    if (groupRef3.current) {
      groupRef3.current.visible = z < 7 && x < -2 && y < 4;
    }

    if (groupRef4.current) {
      groupRef4.current.visible = z < 7 && x > 2 && y < 4;
    }
  });

  return (
    <group position={[0, 0, 0]}>
      <Measurement
        fontSize={fontSize}
        groupRef={groupRef1}
        lPositionMN={lPositionMP(0)}
        hPositionM={hPositionM(2.4)}
        wPositionMP={wPositionMP(4)}
        lPositionMP={lPositionMP(1.73)}
        rotation={[0, 0, 0]}
        firstPoint={[lPositionMP(1.73) + 1.73 + lengthOffset, 0, 0]}
        secondPoint={[-lPositionMP(1.73) + 1.73 + -lengthOffset, 0, 0]}
        linePosition={[-1.73, 0.4, 0.3]}
        textPosition={[0, 0.5, 0.3]}
        textValue={`Length (${length.feet}' ${length.inches}'')`}
      />

      <Measurement
        fontSize={fontSize}
        groupRef={groupRef2}
        lPositionMN={lPositionMP(0)}
        hPositionM={hPositionM(2.4)}
        wPositionMP={-wPositionMP(4)}
        lPositionMP={lPositionMP(-1.73)}
        rotation={[0, Math.PI, 0]}
        firstPoint={[lPositionMP(1.73) + 1.73 + lengthOffset, 0, 0]}
        secondPoint={[-lPositionMP(1.73) + 1.73 + -lengthOffset, 0, 0]}
        linePosition={[-1.73, 0.4, -0.3]}
        textPosition={[0, 0.5, -0.3]}
        textValue={`Length (${length.feet}' ${length.inches}'')`}
      />

      <Measurement
        fontSize={fontSize}
        groupRef={groupRef3}
        lPositionMN={lPositionMP(0)}
        hPositionM={hPositionM(2.4)}
        wPositionMP={wPositionMP(4)}
        firstPoint={[0, 0, wPositionMP(4) + 4 + widthOffset]}
        secondPoint={[0, 0, -wPositionMP(-4) + 4 + -widthOffset]}
        lPositionMP={lPositionMP(-1.73)}
        rotation={[Math.PI / 2, Math.PI / 2, -Math.PI / 2]}
        linePosition={[lPositionMP(-1.73) + 0.1, 0.4, -wPositionMP(4) - 4]}
        textPosition={[lPositionMP(-1.73) + 0.1, 0.5, -wPositionMP(4)]}
        textValue={`Width (${width.feet}' ${width.inches}'')`}
      />

      <Measurement
        fontSize={fontSize}
        groupRef={groupRef4}
        lPositionMN={lPositionMP(0)}
        hPositionM={hPositionM(2.4)}
        wPositionMP={wPositionMP(-4)}
        firstPoint={[0, 0, wPositionMP(4) + 4 + widthOffset]}
        secondPoint={[0, 0, -wPositionMP(-4) + 4 + -widthOffset]}
        lPositionMP={lPositionMP(-1.73)}
        rotation={[Math.PI / 2, -Math.PI / 2, Math.PI / 2]}
        linePosition={[-lPositionMP(-1.73) - 0.1, 0.4, -wPositionMP(4) - 4]}
        textPosition={[-lPositionMP(-1.73) - 0.1, 0.5, -wPositionMP(4)]}
        textValue={`Width (${width.feet}' ${width.inches}'')`}
      />
    </group>
  );
};
