/**
 * Calculate Posts
 *
 * @param {number} length The length in inches of the pergola
 * @param {number} width The width in inches of the pergola
 * @return {number}
 */
export const calculatePosts = (length: number, width: number): number => {
  //D4 = Length
  //D6 = Width

  // if(and(D4<=240,D6<=240),4,
  if (length < 240 && width < 240) {
    return 4;
  }

  // if(and(and(D4>240,D4<=480),D6<=240),6,
  if (length >= 240 && width < 480) {
    return 6;
  }

  // if(and(and(D4>480,D4<=720),D6<=240),8,
  if (length >= 480 && width < 240) {
    return 8;
  }

  // if(and(and(D4>240,D4<=480),and(D6>240,D6<=480)),9,
  if (length >= 240 && length < 480 && width >= 240 && width < 480) {
    return 9;
  }

  // if(and(and(D4>480,D4<=720),and(D6>240,D6<=480)),12,"ERROR")))))
  // if (lengthInches > 480 && lengthInches <= 720 && widthInches > 240 && widthInches <= 480) {
  return 12;
  // }
};

//=if(and(D4<=240,D6<=240),4,
// if(and(and(D4>240,D4<=480),D6<=240),6,
// if(and(and(D4>480,D4<=720),D6<=240),8,
// if(and(and(D4>240,D4<=480),and(D6>240,D6<=480)),9,
// if(and(and(D4>480,D4<=720),and(D6>240,D6<=480)),12,"ERROR")))))
