import React, { FC, useEffect } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { PergolaDesign, usePergola } from '../../data-stores/pergola';

import freeStandingPergola from '../../assets/freeStandingPergolа.png';
import attachedPergola from '../../assets/atachedPergola.png';

export const PergolaRadioButtons: FC = (): JSX.Element => {
  const structureDesign = usePergola(state => state.design.pergola);
  const changePergolaDesign = usePergola(state => state.changePergolaDesign);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const design = urlSearchParams.get('design');

    if (design === PergolaDesign.FREE_STANDING) {
      changePergolaDesign(PergolaDesign.FREE_STANDING)
      urlSearchParams.delete('design');
    }

    if (design === PergolaDesign.ATTACHED) {
      changePergolaDesign(PergolaDesign.ATTACHED)
      urlSearchParams.delete('design');
    }
  }, [changePergolaDesign]);

  return (
    <div className="px-4">
      <RadioGroupPrimitive.Root
        defaultValue={structureDesign}
        className="pergola_design_container"
        aria-label="Pergola Design"
      >
        <div className="flex flex-col items-center justify-center">
          <RadioGroupPrimitive.Item
            className="pergola_design_item"
            style={{ backgroundColor: structureDesign === PergolaDesign.FREE_STANDING ? '#DBE3CF' : '#fff' }}
            checked={structureDesign === PergolaDesign.FREE_STANDING}
            value={PergolaDesign.FREE_STANDING}
            onClick={() => changePergolaDesign(PergolaDesign.FREE_STANDING)}
            id={PergolaDesign.FREE_STANDING}
          >
            <RadioGroupPrimitive.Indicator className="pergola_design_item_indicator"/>
            <div className="imageBox">
              <img
                className="inline pergola_design_img"
                src={freeStandingPergola}
                alt="Free standing pergola"
              />
            </div>
          </RadioGroupPrimitive.Item>

          <div
            onClick={() => changePergolaDesign(PergolaDesign.FREE_STANDING)}
            className={
              `cursor-pointer text-sm mt-4 
              ${structureDesign === PergolaDesign.FREE_STANDING ? 'pergola_design_selected_label' : 'pergola_design_label'}
              ${structureDesign !== PergolaDesign.FREE_STANDING && 'hover_color'}
            `}
          >
            Free Standing
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <RadioGroupPrimitive.Item
            className="pergola_design_item"
            style={{ backgroundColor: structureDesign === PergolaDesign.ATTACHED ? '#DBE3CF' : '#fff' }}
            checked={structureDesign === PergolaDesign.ATTACHED}
            value={PergolaDesign.ATTACHED}
            onClick={() => changePergolaDesign(PergolaDesign.ATTACHED)}
            id={PergolaDesign.ATTACHED}
          >
            <RadioGroupPrimitive.Indicator className="pergola_design_item_indicator"/>
            <div className='imageBox'>
              <img
                className="inline pergola_design_img"
                src={attachedPergola}
                alt="Attached pergola"
              />
            </div>
          </RadioGroupPrimitive.Item>

          <div
            onClick={() => changePergolaDesign(PergolaDesign.ATTACHED)}
            className={
              `cursor-pointer text-sm mt-4 
              ${structureDesign === PergolaDesign.ATTACHED ? 'pergola_design_selected_label' : 'pergola_design_label'}
              ${structureDesign !== PergolaDesign.ATTACHED && 'hover_color'}
            `}
          >
            Attached
          </div>
        </div>
      </RadioGroupPrimitive.Root>
    </div>
  );
};

export default PergolaRadioButtons;
